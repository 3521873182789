import React from 'react';
import axios from 'axios';

function SendSMSNotification({
  setSendRemark,
  sendRemark,
  sendDetails,
  response,
  from
}) {
  const { messageTo, referenceNumber, type } = sendDetails;

  let SelectOptions;

  if (type === 'Requisition') {
    SelectOptions = (
      <>
        <option>
          Provide more details in regards to this Purchase Request.
        </option>
        <option>
          Please report to Admin Office for discussion regarding this Request.
        </option>
      </>
    );
  } else if (type === 'PO') {
    SelectOptions = (
      <>
        <option>Provide more details in regards to this Purchase Order.</option>
        <option>
          Please report to Admin Office for discussion regarding this Order.
        </option>
      </>
    );
  }

  return (
    <>
      <h6 className="text-black">Send remarks</h6>

      <select
        className="shadow-sm small mb-3 form-control form-control-sm"
        onChange={e => {
          setSendRemark({
            ...sendRemark,
            trigger: !!e.target.value,
            custom: e.target.value === 'custom',
            remark: e.target.value !== 'custom' ? e.target.value : ''
          });
        }}
      >
        <option defaultValue value="">
          Select one to send...
        </option>
        {SelectOptions}
        <option
          value="custom"
          onClick={() => setSendRemark({
            ...sendRemark,
            custom: true
          })}
        >
          Custom...
        </option>
      </select>

      {sendRemark.custom && (
        <input
          placeholder="Enter custom message"
          className="form-control form-control-sm m-1 send-notification"
          value={sendRemark.remark}
          onChange={e => setSendRemark({
            ...sendRemark,
            remark: e.target.value
          })}
        />
      )}

      {sendRemark.trigger && (
        <button
          className="btn btn-success btn-sm shadow-sm ml-2"
          disabled={!!(sendRemark.custom && !sendRemark.remark)}
          onClick={async () => {
            await axios
              .post(
                '/api/config/notification',
                {
                  messageTo,
                  referenceNumber,
                  messageHeading: `Message Received for your ${type}: ${referenceNumber} from ${from}`,
                  messageBody: sendRemark.remark,
                  type
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
                  }
                }
              )
              .then(() => {
                setSendRemark({ remark: '' });
                response();
              })
              .catch(err => console.error(err));
          }}
        >
          Send
          {' '}
          <i className="fas fa-paper-plane" />
        </button>
      )}
    </>
  );
}

export default SendSMSNotification;
