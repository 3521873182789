import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DataTables from 'react-data-table-component';
import {
  getTools,
  setCategory,
  searchTools,
  getAllocationTree,
  deleteInventoryItem
} from '../../../actions/inventoryActions';
import Topbar from '../../common/Topbar';
import Footer from '../../common/Footer';
import Sidebar from '../../common/Sidebar';
import SearchSort from '../../common/SearchSort';
import LogoutModal from '../../common/LogoutModal';
import SuccessModal from '../../utils/SuccessModal';
import CategoryOptions from '../../utils/CategoryOptions';
import ModifyAllocationModal from './ModifyAllocationModal';
import customStyles from '../../utils/dataTableCustomStyle';
import ConfirmationModal from '../../utils/ConfirmationModal';

function MainInventory({
  tools,
  count,
  category,
  setCategory,
  searchTools,
  getAllocationTree,
  deleteInventoryItem,
  loading,
  getTools
}) {
  const [filter, setFilter] = useState({
    phrase: '',
    minStock: false
  });
  const [selected, setSelected] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCategory(category || 'tools');
    getTools(category || 'tools', 1, window.location.search && true);
    window.location.search && setFilter({ minStock: true });
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'Item',
      width: '45%',
      selector: 'item',
      sortable: true,
      format: (row) => row.item
    },
    {
      grow: 0,
      width: '15%',
      center: true,
      cell: (row) => {
        let requestedQuantity = 0;
        let orderedQuantity = 0;

        if (row.isInRequisition[0] || row.isInPo[0]) {
          return (
            <span style={{ fontSize: '12px' }} className="badge badge-dark">
              {row.isInRequisition.map((req) => {
                requestedQuantity +=
                  parseFloat(req.quantity) || parseFloat(req.requestedQuantity);
                return true;
              })}
              {row.isInPo.map((po) => {
                orderedQuantity +=
                  parseFloat(po.quantity) - parseFloat(po.received);

                return true;
              })}
              Requested: {requestedQuantity} / Ordered: {orderedQuantity}
            </span>
          );
        }

        return null;
      }
    },
    {
      name: 'In Stock',
      width: '10%',
      selector: 'inStock',
      sortable: true,
      right: true,
      cell: (row) => {
        if (parseFloat(row.inStock) < parseFloat(row.minStock)) {
          return (
            <>
              <i className="fas fa-exclamation-triangle mr-4 text-warning text-lg" />
              {`${row.inStock} ${row.unit}`}
            </>
          );
        }
        return `${row.inStock} ${row.unit}`;
      }
    },
    {
      name: 'Min Stock',
      width: '10%',
      selector: 'minStock',
      right: true,
      cell: (row) => `${row.inStock} ${row.unit}`
    },
    {
      name: 'Usage to Date',
      width: '10%',
      selector: 'onFloor',
      sortable: true,
      right: true,
      format: (row) => `${row.onFloor} ${row.unit}`
    },
    {
      name: 'Updated At',
      width: '10%',
      selector: 'updatedAt',
      sortable: true,
      right: true,
      format: (row) => moment(row.updatedAt).format('DD-MM-YYYY')
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Inventory" loading={loading} />

            <div className="container-fluid">
              <div className="card shadow">
                <div className="card-header">
                  <CategoryOptions
                    current={category}
                    setCategory={setCategory}
                    getTools={getTools}
                  />
                </div>
                <div className="card-body">
                  <SearchSort
                    search={(phrase) => {
                      searchTools(
                        phrase,
                        category || 'tools',
                        1,
                        filter.minStock
                      );
                      setFilter({ ...filter, phrase });
                    }}
                    filter={filter}
                    setFilter={(res) => {
                      searchTools(
                        filter.phrase,
                        category || 'tools',
                        1,
                        res.minStock
                      );
                      setFilter({ ...filter, ...res });
                    }}
                    customFilterButton
                  />

                  <DataTables
                    noHeader
                    columns={columns}
                    customStyles={customStyles}
                    highlightOnHover
                    pointerOnHover
                    data={tools}
                    pagination
                    paginationTotalRows={count}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    onRowClicked={(row) => {
                      window.$('#modifyAllocation').modal('show');
                      setSelected(row.serialNumber);
                      getAllocationTree(row.serialNumber);
                    }}
                    paginationServer
                    paginationServerOptions={{
                      persistSelectedOnPageChange: true
                    }}
                    onChangePage={async (pageNumber) => {
                      setCurrentPage(pageNumber);
                      await searchTools(
                        filter.phrase,
                        category || 'tools',
                        pageNumber,
                        filter.minStock
                      );
                    }}
                    paginationPerPage={15}
                  />
                </div>
              </div>
              <ModifyAllocationModal
                selected={selected}
                filter={filter.phrase}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />

      <SuccessModal title="Item Deleted" />

      <ConfirmationModal
        bodyText="Are you sure, you want to delete this item. This action cannot be reversed once you click Confirm button"
        modalId="confirmItemDelete"
        title="Confirm Delete Item"
        buttonTitle="Delete"
        buttonStyle="danger"
        dismissOnClick
        response={async () => {
          await deleteInventoryItem(selected, category, currentPage);
          window.$('#successModal').modal('show');
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  tools: state.inventory.tools,
  count: state.inventory.count,
  category: state.inventory.category,
  loading: state.inventory.loading
});

export default connect(mapStateToProps, {
  getTools,
  setCategory,
  searchTools,
  getAllocationTree,
  deleteInventoryItem
})(MainInventory);
