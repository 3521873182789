import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';

axios.defaults.baseURL =
  process.env.REACT_APP_API_HOST || 'http://127.0.0.1:5000';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
