import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import Topbar from '../common/Topbar';
import LogoutModal from '../common/LogoutModal';

function NotFoundPage({ role }) {
  return (
    <div className="page-top">
      <div id="wrapper">
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="text-center">
                <div className="error mx-auto" data-text="404">
                  404
                </div>
                <p className="lead text-gray-800 mb-5">Page Not Found</p>
                <p className="text-gray-500 mb-0">
                  The page you are looking for does not exists...
                </p>
                <Link to={`/${role}`}>&larr; Back to Dashboard</Link>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

export default NotFoundPage;
