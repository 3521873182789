import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Spinner from '../common/Spinner';
import { downloadCSV } from './functions';

function GRItemsForApproval({
  filter,
  loading,
  pending,
  username,
  setFilter,
  fullScreen,
  insertItemIntoCart
}) {
  const style = { fontSize: fullScreen ? '1rem' : '9px' };
  const columns = [
    {
      name: 'PO',
      selector: 'poNumber',
      sortable: true,
      center: true,
      width: window.innerWidth > 600 && '20%',
      cell: (row) => (
        <button
          onClick={() => insertItemIntoCart && insertItemIntoCart(row)}
          className={`btn ${
            username === row.requisition.createdBy ? 'btn-info' : 'btn-danger'
          } btn-sm font-weight-bold`}
        >
          <span style={style}>{row.poNumber}</span>
        </button>
      )
    },
    {
      name: 'Item',
      selector: 'item',
      sortable: true,
      width: window.innerWidth > 600 && '65%',
      cell: (row) => (
        <div
          style={{ width: '100%' }}
          className="text-xs"
          onClick={() => insertItemIntoCart && insertItemIntoCart(row)}
        >
          <p className="text-black font-weight-bold mt-3 mb-1" style={style}>
            {row.item}
          </p>

          <hr className="mt-0 mb-1" />

          <div>
            <p className="text-black mb-1 mt-0" data-tag="allowRowEvents">
              Received Quantity:
              <span className="font-weight-bold ml-1">
                {`${row.receivedQuantity} ${row.unit}`}
              </span>
              <span className="text-xs text-info float-right font-weight-bold">
                [ {row.requisition.createdBy} ]
              </span>
            </p>
          </div>

          <div>
            <p className="text-black mb-3 mt-1" data-tag="allowRowEvents">
              Received At:
              <span className="font-weight-bold ml-1">
                {moment(row.createdAt).format('DD MMMM YYYY - hh:mm A')}
              </span>
            </p>
          </div>
        </div>
      )
    },
    {
      name: 'PR',
      selector: 'requisitionNumber',
      sortable: true,
      center: true,
      cell: (row) => (
        <p className="font-xs text-black" style={style}>
          {row.requisitionNumber}
        </p>
      )
    }
  ];

  let condStyle = {};

  if (window.innerWidth < 600) {
    condStyle = {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '1rem'
    };
  }

  return (
    <div className="card small shadow mb-2">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-6">
            <h6 className="text">
              APPROVE RECEIVED GOODS
              {loading && <Spinner height="15px" width="15px" />}
            </h6>
          </div>
          <div className="col-sm-6 text-right">
            <button
              className="btn btn-info btn-sm shadow-sm float-right ml-2"
              style={{ marginTop: '-5px', scale: '0.8' }}
              onClick={() => {
                const filtered = [];
                const data = filter.length > 0 ? [...filter] : [...pending];
                data.map((item) =>
                  filtered.push({
                    'PO Number': item.poNumber,
                    'Requisition Number': item.requisitionNumber,
                    'Item Name': item.item,
                    Quantity: `${item.receivedQuantity} ${item.unit}`,
                    'Requisition By': item.requisition.createdBy,
                    'Received At': moment(item.createdAt).format(
                      'DD MMMM YYYY - hh:mm A'
                    )
                  })
                );
                downloadCSV(filtered);
              }}
            >
              <i className="fas fa-save" /> Export
            </button>
            <label className="fas fa-search mr-2" />
            <input
              placeholder="Search..."
              style={{ width: '50%', marginTop: '-6px' }}
              onChange={(e) => {
                const searchFilter = pending.filter((item) =>
                  item.item.toLowerCase().includes(e.target.value.toLowerCase())
                );

                setFilter(searchFilter);
              }}
              className="form-control form-control-sm float-right"
            />
          </div>
        </div>

        <div className="table-responsive">
          <DataTable
            noHeader
            customStyles={{
              headCells: {
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold'
                }
              },
              headRow: {
                style: {
                  border: 'none'
                }
              },
              rows: {
                style: {
                  '&:nth-of-type(odd)': {
                    backgroundColor: '#F2F2F2',
                    border: 'none'
                  },
                  '&:nth-of-type(even)': {
                    border: 'none'
                  },
                  color: '#858796',
                  ...condStyle
                }
              }
            }}
            columns={columns}
            highlightOnHover
            pointerOnHover
            data={filter.length > 0 ? filter : pending}
            pagination
            paginationTotalRows={pending.length || 0}
            onRowClicked={(row) =>
              insertItemIntoCart && insertItemIntoCart(row)
            }
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationPerPage={10}
          />
        </div>

        {Object.keys(pending).length === 0 && (
          <div className="text-center">
            <img
              className="mb-3"
              src="/norecord.png"
              alt="Requisition Cart"
              draggable="false"
              style={{ opacity: 0.8, height: 200, width: 200 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  username: state.user.username,
  loading: state.goodsreceived.loading,
  error: state.goodsreceived.error
});

export default connect(mapStateToProps, {})(GRItemsForApproval);
