export const SET_USER = 'SET_USER';
export const SET_ERROR = 'SET_ERROR';
export const SET_STATUS = 'SET_STATUS';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const SET_LOADING = 'SET_LOADING';
export const SET_GR_LOADING = 'SET_GR_LOADING';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const REFRESH_STATS = 'REFRESH_STATS';
export const SET_CLIENT_CONFIGS = 'SET_CLIENT_CONFIGS';

export const SET_TOOLS = 'SET_TOOLS';
export const SET_TOOL_CATEGORY = 'SET_TOOL_CATEGORY';
export const SET_INVENTORY_CATEGORIES = 'SET_INVENTORY_CATEGORIES';

export const GET_REQUISITIONS = 'GET_REQUISITIONS';
export const GET_WFA_REQUISITIONS = 'GET_WFA_REQUISITIONS';
export const GET_APPROVED_REQUISITIONS = 'GET_APPROVED_REQUISITIONS';
export const GET_MY_REQUISITION_ITEMS = 'GET_MY_REQUISITION_ITEMS';

export const SET_PLANTS = 'SET_PLANTS';
export const SET_MACHINES = 'SET_MACHINES';
export const SET_ALLOCATION_TREE = 'SET_ALLOCATION_TREE';

export const ADMIN_GET_ALL_USERS = 'ADMIN_GET_ALL_USERS';

export const SET_ALL_PO = 'SET_ALL_PO';
export const SET_WFA_PO = 'SET_WFA_PO';
export const SET_APPROVED_PO = 'SET_APPROVED_PO';
export const SET_PENDING_ITEMS = 'SET_PENDING_ITEMS';
export const SET_VENDORS = 'SET_VENDORS';
export const ADD_PO_ITEM_INTO_CART = 'ADD_PO_ITEM_INTO_CART';
export const SET_PO_PENDING_ITEMS = 'SET_PO_PENDING_ITEMS';
export const SET_PO_MONTHLY_SPENDINGS = 'SET_PO_MONTHLY_SPENDINGS';

export const SET_PO_WAITING_TOBE_RECEIVED = 'SET_PO_WAITING_TOBE_RECEIVED';
export const SET_PO_WAITING_APPROVAL = 'SET_PO_WAITING_APPROVAL';

export const SET_ALL_RECEIVED_GOODS = 'SET_ALL_RECEIVED_GOODS';
export const SET_GOODS_REGISTER_ITEMS = 'SET_GOODS_REGISTER_ITEMS';

export const GET_ALL_GR_REVERSAL = 'GET_ALL_GR_REVERSAL';

export const GET_REPORTS = 'GET_REPORTS';
