import React from 'react';
import { connect } from 'react-redux';
import {
  approvePurchaseOrder,
  getPOMonthlySpendings
} from '../../../actions/poActions';

function ApprovePurchaseOrder({
  purchaseOrderId,
  poNumber,
  disabled,
  multiple,
  approvePurchaseOrder,
  getPOMonthlySpendings
}) {
  return (
    <>
      {disabled && (
        <div className="text-center">
          <label className="text-sm">
            Select the quotation by clicking on the tab above
          </label>
        </div>
      )}

      <div className="btn-group mt-2 m-auto btn-block" data-dismiss="modal">
        <button
          className="btn btn-danger"
          style={{ cursor: `${disabled ? 'not-allowed' : 'pointer'}` }}
          disabled={disabled}
          onClick={() => approvePurchaseOrder(
            purchaseOrderId,
            poNumber,
            multiple || null,
            'reject'
          )}
        >
          Reject Purchase Order
        </button>
        <button
          style={{ cursor: `${disabled ? 'not-allowed' : 'pointer'}` }}
          className="btn btn-success"
          disabled={disabled}
          onClick={() => {
            approvePurchaseOrder(
              purchaseOrderId,
              poNumber,
              multiple || null
            );

            getPOMonthlySpendings();
          }}
        >
          Approve Purchase Order
        </button>
      </div>
    </>
  );
}

export default connect(null, { approvePurchaseOrder, getPOMonthlySpendings })(
  ApprovePurchaseOrder
);
