import { GET_REPORTS, SET_LOADING } from '../actions/types';

const initialState = {
  loading: false,
  reports: [],
  count: 0,
  selected: {}
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        loading: false,
        reports: [...action.payload.data],
        count: action.payload.count
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};

export default reportsReducer;
