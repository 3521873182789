import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Spinner from '../common/Spinner';
import { downloadCSV } from './functions';

function ItemsWaitingForPurchase({
  cart,
  filter,
  loading,
  pending,
  setFilter,
  fullScreen,
  deleteItem,
  setPending,
  setDeleteItem,
  addItemIntoCart
}) {
  const [users, setUsers] = useState({ data: [], filter: '' });

  function getUsers() {
    const data = filter.length > 0 ? filter : pending;
    const temp = [];

    data.map(
      (item) => !temp.includes(item.createdBy) && temp.push(item.createdBy)
    );

    setUsers({ data: [...temp], filter: '' });
  }

  useEffect(() => {
    if (users.data.length === 0) getUsers();

    return () => {};
    // eslint-disable-next-line
  }, [filter, pending]);

  const insertItemIntoCart = (row) => {
    const index = pending.findIndex(
      (obj) => obj.serialNumber === row.serialNumber
    );

    setPending(pending.slice(0, index).concat(pending.slice(index + 1)));

    addItemIntoCart([...cart, row]);

    if (filter.length > 0) {
      const filterIndex = filter.findIndex(
        (obj) => obj.serialNumber === row.serialNumber
      );

      setFilter(
        filter.slice(0, filterIndex).concat(filter.slice(filterIndex + 1))
      );
    }
  };

  const columns = [
    {
      name: 'PR',
      selector: 'requisitionNumber',
      sortable: true,
      center: true,
      width: '20%',
      cell: (row) => (
        <button
          onClick={() => !fullScreen && insertItemIntoCart(row)}
          className="btn btn-info btn-sm font-weight-bold"
        >
          {row.requisitionNumber}
        </button>
      )
    },
    {
      name: 'Item',
      sortable: true,
      width: '52%',
      cell: (row) => (
        <div onClick={() => !fullScreen && insertItemIntoCart(row)}>
          <p className="text font-weight-bold mt-2 mb-1">{row.item}</p>
          <div>
            <p className="text-dark mb-1" data-tag="allowRowEvents">
              Quantity:{' '}
              <span className="text-dark font-weight-bold">
                {`${row.quantity} ${row.unit}`}
              </span>
            </p>
          </div>

          <div>
            <p className="text-dark mb-1 pt-0" data-tag="allowRowEvents">
              Created By:{' '}
              <span className="text-dark font-weight-bold">
                {row.createdBy}
              </span>
            </p>
          </div>

          {row.plant && (
            <div className="pb-2 pt-0">
              <span className="text-xs text-info font-weight-bold">
                {row.plant} | Machine:
                {row.machine} | Remark:
                {row.remark}
              </span>
            </div>
          )}
        </div>
      )
    },
    {
      sortable: true,
      center: true,
      width: '20%',
      cell: (row) => (
        <div
          className="text-black font-weight-bold"
          style={{ fontSize: '11px' }}
        >
          <p className="mb-1">
            Created: {moment(row.createdAt).format('DD/MM/YY')}
          </p>

          <p>
            Required:
            {moment(row.requiredBy).format('DD/MM/YY')}
          </p>
        </div>
      )
    }
  ];

  return (
    <div className="card text-black shadow-sm">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-5">
            <h6 className="text">
              <select
                className="ml-2 float-right btn-none"
                style={{ borderRadius: '5px', width: '35%' }}
                onChange={(e) => {
                  setUsers({
                    ...users,
                    filter: e.target.value === 'All' ? '' : e.target.value
                  });

                  if (e.target.value !== 'All') {
                    const searchFilter = pending.filter(
                      (item) => item.createdBy === e.target.value
                    );

                    setFilter(searchFilter);
                  }
                }}
              >
                <option value="All">All Users</option>
                {users.data.map((user) => (
                  <option key={user} value={user}>
                    {user}
                  </option>
                ))}
              </select>
              PENDING ORDER ITEMS
              {loading && <Spinner height="15px" width="15px" />}
            </h6>
          </div>
          <div className="col-sm-2 text-right">
            {!fullScreen && deleteItem.length > 0 && (
              <button
                className="btn btn-danger btn-sm shadow-sm"
                data-toggle="modal"
                data-target="#confirmCloseItem"
              >
                Delete Item(s)
              </button>
            )}
          </div>
          <div className="col-sm-5 text-right">
            <button
              className="btn btn-info btn-sm shadow-sm float-right ml-2"
              style={{ marginTop: '-5px', scale: '0.8' }}
              onClick={() => {
                const filtered = [];
                const data =
                  filter.length > 0 || users.filter !== ''
                    ? [...filter]
                    : [...pending];
                data.map((item) =>
                  filtered.push({
                    'Requisition Number': item.requisitionNumber,
                    'Item Name': item.item,
                    Quantity: `${item.quantity} ${item.unit}`,
                    Rate: item.rate,
                    'Created By': item.createdBy,
                    'Created On': item.createdAt
                  })
                );
                downloadCSV(filtered);
              }}
            >
              <i className="fas fa-save" /> Export
            </button>

            <label className="fas fa-search mr-2" />
            <input
              placeholder="Search..."
              style={{ width: '50%', marginTop: '-6px' }}
              onChange={(e) => {
                // const searchFilter = pending.filter(item => {
                //     return item.item
                //         .toLowerCase()
                //         .includes(e.target.value.toLowerCase())
                // })

                const searchFilter = [];

                for (let i = 0; i < pending.length; i += 1) {
                  const item = pending[i];

                  if (
                    item.item
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  ) {
                    if (users.filter !== '') {
                      if (users.filter === item.createdBy) {
                        searchFilter.push(item);
                        break;
                      } else {
                        break;
                      }
                    }

                    searchFilter.push(item);
                  }
                }

                setFilter([...searchFilter]);
              }}
              className="form-control form-control-sm float-right"
            />
          </div>
        </div>

        <div className="table-responsive">
          <DataTable
            noHeader
            customStyles={{
              headCells: {
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold'
                }
              }
            }}
            columns={columns}
            highlightOnHover
            pointerOnHover
            data={filter.length > 0 || users.filter !== '' ? filter : pending}
            pagination
            selectableRows={!fullScreen}
            selectableRowsNoSelectAll
            onSelectedRowsChange={(row) =>
              !fullScreen && setDeleteItem([...row.selectedRows])
            }
            paginationTotalRows={pending.length || 0}
            onRowClicked={(row) => !fullScreen && insertItemIntoCart(row)}
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationPerPage={10}
          />
        </div>
      </div>
    </div>
  );
}

export default ItemsWaitingForPurchase;
