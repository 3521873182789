import React from 'react';

function SomethingWentWrong({ message }) {
  return (
    <footer id="footer">
      <div className="footer-card">
        <div className="footer-text">
          <p>
            {message}
            <span onClick={() => window.location.reload()}>Refresh</span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default SomethingWentWrong;
