import './style.css';
import axios from 'axios';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { downloadCSV } from '../utils/functions';
import { getPlants } from '../../actions/inventoryActions';
import { getAllUsersByRole } from '../../actions/userActions';

// import DatePicker from 'react-datepicker'

function SearchSort({
  role,
  search,
  filter,
  plants,
  filters,
  exports,
  category,
  setFilter,
  getPlants,
  clearFilter,
  addNewButton,
  getAllUsersByRole,
  customFilterButton,
  allowSearchOptions
}) {
  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)
  // const [click, setClick] = useState(0)
  const [users, setUsers] = useState({ role: '', users: [] });

  return (
    <div style={{ marginTop: '-10px' }}>
      {search && (
        <>
          <div className="float-left">
            <div className="input-group no-focus shadow-sm">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white text-black">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                type="text"
                className="form-control shadow-sm"
                placeholder="Search"
                value={filter.phrase || ''}
                onChange={e => search(e.target.value)}
              />
              <div className="input-group-append">
                <select
                  className="input-group-append"
                  disabled={!allowSearchOptions}
                  onChange={e => setFilter({ searchBy: e.target.value })}
                >
                  <option defaultValue value="by_order">
                    By Order
                  </option>
                  <option value="by_item">By Item</option>
                </select>
              </div>
            </div>
          </div>

          {customFilterButton && (
            <button
              className="btn btn-sm btn-info shadow-sm float-right ml-2"
              onClick={() => setFilter({ minStock: true })}
            >
              Items below Minimum Stock
            </button>
          )}

          {((filter && filter.minStock) || window.location.search) && (
            <div className="float-right">
              <button
                className="btn btn-danger btn-sm shadow-sm"
                onClick={() => setFilter({ minStock: false })}
              >
                Reset Filter
              </button>

              <button
                className="btn btn-info btn-sm shadow-sm ml-2"
                onClick={() => {
                  axios
                    .get(`/api/inventory/export/minimum_stock/${category}`, {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          'JWT_TOKEN'
                        )}`
                      }
                    })
                    .catch(err => console.log(err.response))
                    .then(res => {
                      const data = [];

                      if (res.data && res.data.data.length !== 0) {
                        res.data.data.map(item =>
                          data.push({
                            Particulars: item.item,
                            'In Stock': `${item.inStock} ${item.unit}`,
                            'Min Stock': `${item.minStock} ${item.unit}`,
                            'Usage To Date': `${item.onFloor} ${item.unit}`,
                            Rate: item.rate
                          })
                        );
                      }

                      downloadCSV([...data]);
                    });
                }}
              >
                <i className="fas fa-save" /> Export
              </button>
            </div>
          )}

          {filters && (
            <div className="float-right">
              <i className="fas fa-filter mr-3 font-weight-bold text-black" />

              {/* This feature will be developed later. */}

              {/* <div className="btn-group ml-2">
                <DatePicker
                  selected={startDate}
                  onChange={res => {
                    if (click === 0) {
                      setStartDate(res)
                      setClick(1)
                    } else if (click === 1) {
                      setEndDate(res)
                      setClick(0)
                    }
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={
                    <i className="fas fa-calendar-alt text-black"></i>
                  }
                  shouldCloseOnSelect={false}
                />
              </div> */}

              {exports && (
                <button
                  className="btn btn-info btn-sm shadow-sm float-left mt-1 mr-2"
                  style={{ scale: '0.8' }}
                  onClick={async () => {
                    axios
                      .post(
                        '/api/po/export',
                        { ...filter },
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              'JWT_TOKEN'
                            )}`
                          }
                        }
                      )
                      .catch(err => console.log(err))
                      .then(res => {
                        const data = [];

                        if (res.data && res.data.data.length !== 0) {
                          res.data.data.map(item =>
                            data.push({
                              'PO Number': item.poNumber,
                              'Requisition Number': item.requisitionNumber,
                              Particular: item.item,
                              Quantity: `${item.quantity} ${item.unit}`,
                              Discount: item.discount,
                              Received: item.received,
                              'PO By': item.po.createdBy,
                              'Requisition By': item.requisition.createdBy,
                              'Approved By': item.po.approvedBy
                                ? `${item.po.users.firstName} ${item.po.users.lastName}`
                                : '-',
                              'Approved At': item.po.approvedAt
                                ? new Date(item.po.approvedAt).toDateString()
                                : '-'
                            })
                          );
                        }

                        downloadCSV([...data]);
                      });
                  }}
                >
                  <i className="fas fa-save" /> Export
                </button>
              )}

              {filters.createdBy !== false && (
                <select
                  className="btn btn-light ml-2 mr-2"
                  value={filter.createdBy || ''}
                  onClick={async () => {
                    if (users.role !== role || users.users.length === 0) {
                      const user = await getAllUsersByRole(role || 'procflo');
                      user && setUsers({ role, users: [...user] });
                    }
                  }}
                  onChange={e => setFilter({ createdBy: e.target.value })}
                >
                  <option defaultValue value="">
                    Created By
                  </option>
                  {users.users.map(user => (
                    <option value={user.username} key={user.username}>
                      {user.username}
                    </option>
                  ))}
                </select>
              )}

              {filters.plants && (
                <select
                  className="btn btn-light ml-2 mr-2"
                  value={filter.plant || ''}
                  onClick={() => plants.length === 0 && getPlants()}
                  onChange={e => setFilter({ plant: e.target.value })}
                >
                  <option defaultValue value="">
                    Select plant
                  </option>
                  {plants.map(plant => (
                    <option key={plant.plant} value={plant.plant}>
                      {plant.plant}
                    </option>
                  ))}
                </select>
              )}

              {filters.status && (
                <select
                  className="btn btn-light mr-2"
                  value={filter.status || ''}
                  disabled={!!window.location.search}
                  onChange={e => setFilter({ status: e.target.value })}
                >
                  <option defaultValue value="">
                    Status
                  </option>

                  {filters.status.map(filter => (
                    <option key={filter.value} value={filter.value}>
                      {filter.title}
                    </option>
                  ))}
                </select>
              )}

              {filters.createdAt !== false && (
                <select
                  className="btn btn-light mr-2"
                  value={filter.createdAt || ''}
                  onChange={e => setFilter({ createdAt: e.target.value })}
                >
                  <option defaultValue value="2024">
                    2024
                  </option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                </select>
              )}

              {!window.location.search && (
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-danger btn-sm shadow-sm"
                >
                  Clear
                </button>
              )}
            </div>
          )}
        </>
      )}

      <div className="dropdown float-right">
        {addNewButton && (
          <button
            className="btn btn-info btn-circle btn-sm iccoon mr-4 mb-2"
            title="Add New User"
            data-toggle="modal"
            data-target={`#${addNewButton}`}
          >
            <i className="fas fa-plus" />
          </button>
        )}
      </div>
    </div>
  );
}

SearchSort.defaultProps = {
  filter: {
    phrase: ''
  }
};

const mapStateToProps = state => ({
  plants: state.inventory.plants,
  category: state.inventory.category
});

export default connect(mapStateToProps, { getAllUsersByRole, getPlants })(
  SearchSort
);
