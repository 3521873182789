import { combineReducers } from 'redux';
import grReducer from './grReducer';
import poReducer from './poReducer';
import logsReducer from './logsReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import configReducer from './configReducer';
import reportsReducer from './reportsReducer';
import inventoryReducer from './inventoryReducer';
import requisitionReducer from './requisitionReducer';

export default combineReducers({
  user: authReducer,
  logs: logsReducer,
  admin: userReducer,
  config: configReducer,
  inventory: inventoryReducer,
  requisitions: requisitionReducer,
  purchaseorders: poReducer,
  goodsreceived: grReducer,
  reports: reportsReducer
});
