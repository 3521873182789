import { ADMIN_GET_ALL_USERS, SET_ERROR, SET_LOADING } from '../actions/types';

const initialState = {
  users: [],
  usersPagination: {},
  error: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_GET_ALL_USERS:
      return {
        ...state,
        users: action.payload.data,
        usersPagination: action.payload.pagination,
        loading: false
      };

    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };

    case SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
