import React from 'react';
import { Link } from 'react-router-dom';

function Content({ dashboard }) {
  return (
    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
      {dashboard.map(item => (
        <Link
          key={item.title}
          className="col-xl-3 col-md-6 mb-3"
          to={item.to || '#'}
          style={{ textDecoration: 'none' }}
        >
          <div
            className={`card border-left-${item.style} shadow-sm h-100 py-2`}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div
                    className={`text-xs font-weight-bold text-${item.style} text-uppercase mb-1`}
                  >
                    {item.title}
                  </div>

                  {item.subHeading && (
                    <p
                      style={{ fontSize: '10px' }}
                      className="text-xs text-black p-0 m-0"
                    >
                      {item.subHeading}
                    </p>
                  )}

                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {item.data}
                  </div>
                </div>
                <div className="col-auto">
                  <i className={`fas fa-${item.icon} fa-2x text-gray-300`} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
      {/* <div className="col">
      <div className="progress progress-sm mr-2">
        <div
          className="progress-bar bg-info"
          role="progressbar"
          style={{ width: '50%' }}
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div> */}
    </div>
  );
}

Content.defaultProps = {
  dashboard: []
};

export default Content;
