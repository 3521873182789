import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';

function LogoutModal({ logoutUser }) {
  useEffect(
    () => () => {
      window.$('#logoutModal').modal('hide');
    },
    []
  );
  return (
    <div
      className="modal fade"
      id="logoutModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ready to Leave?</h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body text-black">
            Click on `&quot;`Logout`&quot;` below if you are ready to end your
            current session.
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary shadow"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <a className="btn btn-primary" onClick={logoutUser} href="/">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { logoutUser })(LogoutModal);
