import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import loadjs from 'loadjs';

function Sidebar({ role }) {
  useEffect(() => {
    loadjs('../../../js/sb-admin-2.min.js');
  }, []);
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center cursor-on-hover"
        to={`/${role}`}
      >
        <div className="sidebar-brand-icon">
          <img
            src="../../../img/procflo-lead.svg"
            width="75%"
            alt="ProcFlo Logo"
          />
        </div>

        <div className="sidebar-brand-text" style={{ marginLeft: '-13px' }}>
          <img src="../../../img/procflo-text.svg" width="95%" alt="ProcFlo" />
        </div>
      </Link>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        <Link className="nav-link cursor-on-hover" to={`/${role}`}>
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>Dashboard</span>
        </Link>
      </li>

      <hr className="sidebar-divider" />

      <div className="sidebar-heading">Utilities</div>

      {['admin', 'purchaseorder', 'production', 'inventory'].includes(role) && (
        <li className="nav-item">
          <Link
            to={`/${role}/inventory`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-wrench" />
            <span>Inventory</span>
          </Link>
        </li>
      )}

      {['admin', 'purchaseorder', 'production', 'inventory'].includes(role) && (
        <li className="nav-item">
          <Link
            to={`/${role}/requisition`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-folder" />
            <span>Requisition</span>
          </Link>
        </li>
      )}

      {['admin', 'purchaseorder', 'production', 'inventory'].includes(role) && (
        <li className="nav-item">
          <Link
            to={`/${role}/purchaseorder`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-file" />
            <span>Purchase Order</span>
          </Link>
        </li>
      )}

      {role === 'inventory' && (
        <li className="nav-item">
          <Link
            to={`/${role}/security`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-clipboard-check" />
            <span>Receive at Security</span>
          </Link>
        </li>
      )}

      {role === 'purchaseorder' && (
        <li className="nav-item">
          <Link
            to={`/${role}/security`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-clipboard-check" />
            <span>Force Close PO</span>
          </Link>
        </li>
      )}

      {role !== 'security' && (
        <li className="nav-item">
          <Link
            to={`/${role}/goodsreceived`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-inbox" />
            <span>Goods Received</span>
          </Link>
        </li>
      )}

      {['purchaseorder', 'admin'].includes(role) && (
        <li className="nav-item">
          <Link
            to={`/${role}/vendors`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-briefcase" />
            <span>Vendors</span>
          </Link>
        </li>
      )}

      {['admin', 'inventory'].includes(role) && (
        <li className="nav-item">
          <Link
            to={`/${role}/reports`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-chart-pie" />
            <span>Reports</span>
          </Link>
        </li>
      )}

      {role === 'admin' && (
        <li className="nav-item">
          <Link
            to={`/${role}/users`}
            className="nav-link collapsed cursor-on-hover"
          >
            <i className="fas fa-fw fa-users" />
            <span>Users</span>
          </Link>
        </li>
      )}

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle" />
      </div>

      <button
        style={{
          marginTop: '4em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          borderRadius: '0px'
        }}
        className="btn btn-sm btn-light shadow-sm text-black hov-pointer"
        onClick={() => window.open('/support.html', '_blank', 'noopener,noreferrer')}
      >
        Raise a Support Ticket
      </button>
    </ul>
  );
}

const mapStateToprops = state => ({
  role: state.user.role
});

export default connect(mapStateToprops)(Sidebar);
