import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import { addNewTool } from '../../../actions/inventoryActions';
import { getAllInventoryCategories } from '../../../actions/configActions';

function NewToolModal({
  error,
  loading,
  addToCart,
  addNewTool,
  getAllInventoryCategories
}) {
  const [itemDetails, setItemDetails] = useState([
    {
      name: '',
      category: '',
      unit: ''
    }
  ]);
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    const res = await getAllInventoryCategories();
    setCategories(res);
  };

  useEffect(() => {
    getCategories();

    return () => {
      window.$('#addNewTool').modal('hide');
    };
    // eslint-disable-next-line
  }, []);

  const onSubmitDo = async (e) => {
    e.preventDefault();

    const res = await addNewTool(itemDetails);

    if (res) {
      addToCart(res);
      setItemDetails([
        {
          name: '',
          category: '',
          unit: ''
        }
      ]);
      window.$('#addNewTool').modal('hide');
    }
  };

  return (
    <div
      className="modal fade"
      id="addNewTool"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Tool</h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => onSubmitDo(e)}>
              {itemDetails.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="form-group row" key={index}>
                  <div className="col-sm-5 mb-3 mb-sm-0">
                    <input
                      type="text"
                      className="form-control form-control-tool"
                      id="exampleFirstName"
                      placeholder="Item Name"
                      required
                      value={item.name || ''}
                      onChange={(e) => {
                        const data = itemDetails;
                        data[index].name = e.target.value.toUpperCase();
                        setItemDetails([...data]);
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-control-tool"
                      required
                      value={item.category || ''}
                      onChange={(e) => {
                        const data = itemDetails;
                        data[index].category = e.target.value;
                        setItemDetails([...data]);
                      }}
                    >
                      <option defaultValue value="">
                        Category
                      </option>
                      {categories.map((category) => (
                        <option
                          value={category.category}
                          key={category.category}
                        >
                          {category.category.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3 mb-3 mb-sm-0">
                    <select
                      className="form-control form-control-tool"
                      required
                      value={item.unit || ''}
                      onChange={(e) => {
                        const data = itemDetails;
                        data[index].unit = e.target.value;
                        setItemDetails([...data]);
                      }}
                    >
                      <option defaultValue value="">
                        Unit Type
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Ltr">Litre</option>
                      <option value="Mtr">Meter</option>
                      <option value="Nos">Nos</option>
                      <option value="Units">Units</option>
                      <option value="Sets">Sets</option>
                      <option value="Pairs">Pairs</option>
                    </select>
                  </div>
                  <div className="col-sm-1">
                    <i
                      onClick={() => {
                        if (Object.keys(itemDetails).length > 1) {
                          const data = itemDetails.filter(
                            (obj, i) => i !== index
                          );
                          setItemDetails(data);
                        }
                      }}
                      className="fas fa-times-circle text-danger btn"
                    />
                  </div>
                </div>
              ))}

              <button
                className="btn btn-sm btn-info btn-icon-split mb-3"
                onClick={() => {
                  setItemDetails([
                    ...itemDetails,
                    {
                      name: '',
                      category: '',
                      unit: ''
                    }
                  ]);
                }}
              >
                <span className="icon text-white-50">
                  <i className="fas fa-info-circle"> </i>
                </span>
                <span className="text">Add More</span>
              </button>

              {error && (
                <div className="alert alert-danger text-center">
                  <strong>Warning!</strong> {error}
                </div>
              )}

              <div className="modal-footer">
                {loading === true && <Spinner />}
                <button className="btn btn-success" type="submit">
                  Add Item(s)
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.inventory.error,
  loading: state.inventory.loading
});

export default connect(mapStateToProps, {
  addNewTool,
  getAllInventoryCategories
})(NewToolModal);
