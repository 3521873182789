import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Spinner from '../../common/Spinner';
import SearchSort from '../../common/SearchSort';
import customStyles from '../../utils/dataTableCustomStyle';
import { getTools, searchTools } from '../../../actions/inventoryActions';

function SelectToolsModal({
  loading,
  cart,
  tools,
  count,
  addToCart,
  getTools,
  searchTools
}) {
  useEffect(() => {
    getTools('', 1);

    return () => {
      window.$('#selectTools').modal('hide');
    };
    // eslint-disable-next-line
  }, [])

  const [filter, setFilter] = useState({ phrase: '', minStock: false });

  const columns = [
    {
      name: 'Item',
      selector: 'item',
      width: '60%',
      sortable: true,
      cell: row => row.item
    },
    {
      grow: 0,
      width: '14%',
      center: true,
      cell: row => {
        let requestedQuantity = 0;
        let orderedQuantity = 0;

        if (row.isInRequisition[0] || row.isInPo[0]) {
          return (
            <div>
              <div>
                <span style={{ fontSize: '12px' }} className="badge badge-dark">
                  {row.isInRequisition.map(req => {
                    requestedQuantity
                      += parseFloat(req.quantity)
                      || parseFloat(req.requestedQuantity);
                    return true;
                  })}
                  {row.isInPo.map(po => {
                    orderedQuantity
                      += parseFloat(po.quantity) - parseFloat(po.received);
                    return true;
                  })}
                  Requested:
                  {' '}
                  {requestedQuantity}
                  {' '}
                  / Ordered:
                  {' '}
                  {orderedQuantity}
                </span>
              </div>
              <div className="text-center mt-1" style={{ fontSize: '10px' }}>
                {row.category.toUpperCase()}
              </div>
            </div>
          );
        }
        return (
          <div className="text-center" style={{ fontSize: '10px' }}>
            {row.category.toUpperCase()}
          </div>
        );
      }
    },
    {
      name: 'Min Stock',
      width: '13%',
      selector: 'minStock',
      sortable: true,
      right: true,
      format: row => `${row.minStock} ${row.unit}`
    },
    {
      name: 'In Stock',
      width: '13%',
      selector: 'inStock',
      sortable: true,
      right: true,
      format: row => `${row.inStock} ${row.unit}`
    }
  ];

  const rowStyling = [
    {
      when: row => cart.some(el => el.serialNumber === row.serialNumber),
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        color: 'white',
        '&:hover': {
          cursor: 'not-allowed'
        },
        borderRadius: '25px'
      },
      highlightOnHoverStyle: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        borderBottomColor: '#FFFFFF',
        borderRadius: '25px',
        outline: '1px solid #FFFFFF'
      }
    }
  ];

  return (
    <div
      className="modal fade"
      id="selectTools"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ backgroundColor: '#F8F8F8' }}>
            <h5 className="modal-title" id="exampleModalLabel">
              Select Tools
              {' '}
              {loading === true && <Spinner />}
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mt-4" />

            <SearchSort
              filter={filter}
              customFilterButton
              search={phrase => {
                setFilter({ ...filter, phrase });
                searchTools(phrase, '', 1, filter.minStock);
              }}
              setFilter={res => {
                searchTools(filter.phrase, '', 1, res.minStock);
                setFilter({ ...filter, ...res });
              }}
            />

            <div className="table-responsive">
              <DataTable
                noHeader
                columns={columns}
                customStyles={customStyles}
                pointerOnHover
                data={tools}
                onRowClicked={e => !cart.some(obj => obj.serialNumber === e.serialNumber)
                  && addToCart({
                    item: e.item,
                    unit: e.unit,
                    rate: e.rate,
                    inStock: e.inStock,
                    minStock: e.minStock,
                    serialNumber: e.serialNumber
                  })}
                conditionalRowStyles={rowStyling}
                pagination
                paginationTotalRows={count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                onChangePage={async pageNumber => await searchTools(
                  filter.phrase,
                  '',
                  pageNumber,
                  filter.minStock
                )}
                paginationPerPage={15}
              />
            </div>
          </div>

          <div className="modal-footer" style={{ backgroundColor: '#F8F8F8' }}>
            <button
              className="btn btn-primary"
              type="button"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.inventory.loading,
  tools: state.inventory.tools,
  count: state.inventory.count
});

export default connect(mapStateToProps, { getTools, searchTools })(
  SelectToolsModal
);
