import React, { useEffect } from 'react';

function ConfirmationModal({
  modalId,
  title,
  bodyText,
  buttonTitle,
  buttonStyle,
  selected,
  dismissOnClick,
  response
}) {
  useEffect(
    () => () => {
      window.$(`#${modalId}`).modal('hide');
    }
    // eslint-disable-next-line
  , [])

  return (
    <div
      className="modal fade"
      id={modalId}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h4 className="text text-center text-black">
              {title}
              <span
                className="float-right"
                style={{
                  cursor: 'pointer',
                  marginTop: '-4px'
                }}
                data-dismiss="modal"
              >
                x
              </span>
            </h4>

            <p className="text-center text-black text-sm mt-4 mb-4">
              {bodyText}
            </p>

            <div className="text-center mt-4">
              <div className="btn-group">
                <button
                  className="btn btn-warning shadow-sm"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className={`btn btn-${
                    buttonStyle || 'danger'
                  } shadow-sm`}
                  onClick={() => {
                    response(selected);
                    dismissOnClick && window.$(`#${modalId}`).modal('hide');
                  }}
                >
                  {buttonTitle}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
