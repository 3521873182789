import axios from 'axios';
import { GET_REPORTS, SET_LOADING } from './types';
import setError from '../components/utils/asyncHandler';

export const getAllReports = (page, filter) => async dispatch => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(
      `/api/report/${
        filter && filter.phrase !== '' ? filter.phrase : 'null'
      }?page=${page || 1}&sort=createdAt_DESC`,
      { filter },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: GET_REPORTS, payload: res.data });
  } catch (err) {
    setError(err, res => dispatch({ type: res.type, payload: res.data }));
  }
};

export const createReport = body => async dispatch => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(
      '/api/report/create/new',
      { ...body },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    const res = await axios.post(
      '/api/report/null?page=1&sort=createdAt_DESC',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: GET_REPORTS, payload: res.data });
  } catch (err) {
    setError(err, res => dispatch({ type: res.type, payload: res.data }));
  }
};
