import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getGrToBeApproved,
  approveGoodsReceived
} from '../../../actions/grActions';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import SuccessModal from '../../utils/SuccessModal';
import ConfirmationModal from '../../utils/ConfirmationModal';
import GRItemsForApproval from '../../utils/GRItemsForApproval';

function ViewGoodsReceived({
  getGrToBeApproved,
  approveGoodsReceived,
  role,
  username,
  approve
}) {
  const [pending, setPending] = useState([]);
  const [filter, setFilter] = useState([]);
  const [cart, setCart] = useState([]);

  async function getItemsToBeApproved() {
    const res = await getGrToBeApproved();
    setPending(res);
  }

  useEffect(() => {
    getItemsToBeApproved();
    // eslint-disable-next-line
  }, []);

  const onSubmitDo = async () => {
    await approveGoodsReceived(cart);
    setCart([]);
    getItemsToBeApproved();
  };

  const insertItemIntoCart = (row) => {
    if (username === row.requisition.createdBy) {
      const index = pending.findIndex(
        (obj) => obj.serialNumber === row.serialNumber
      );

      setPending(pending.slice(0, index).concat(pending.slice(index + 1)));

      setCart([...cart, row]);

      if (filter.length > 0) {
        const filterIndex = filter.findIndex(
          (obj) => obj.serialNumber === row.serialNumber
        );

        setFilter(
          filter.slice(0, filterIndex).concat(filter.slice(filterIndex + 1))
        );
      }
    }
  };

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Goods Received" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 pr-1">
                  <GRItemsForApproval
                    pending={pending}
                    filter={filter}
                    setFilter={(res) => setFilter(res)}
                    insertItemIntoCart={(row) => insertItemIntoCart(row)}
                  />
                </div>

                <div className="col-sm-6">
                  <div className="card small">
                    <div className="card-body">
                      <h6 className="font-weight-bold">Approve Items Cart</h6>

                      <div
                        className="btn-group float-right"
                        style={{
                          marginTop: '-35px',
                          marginBottom: '-10px'
                        }}
                      >
                        <Link
                          to={`/${role}/goodsreceived/grreversal`}
                          className="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm mr-2"
                        >
                          <i className="fas fa-undo fa-sm text-white-50" /> GR
                          Reversal
                        </Link>

                        <Link
                          to={`/${role}/goodsreceived/goodsregister`}
                          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                        >
                          <i className="fas fa-file fa-sm text-white-50" /> GR
                          Register
                        </Link>
                      </div>

                      <hr className="mb-0" />

                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          window.$('#confimGoodsReceived').modal('show');
                        }}
                      >
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th width="50%">Item</th>
                              <th className="text-center">Received Qty</th>
                              <th width="15%" className="text-center">
                                Approved Qty
                              </th>
                              <th width="10%" className="text-right">
                                Remove
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {cart.map((item, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <tr key={index}>
                                <td>{item.item}</td>

                                <td className="text-center">
                                  {`${item.receivedQuantity} ${item.unit}`}
                                </td>

                                <td className="text-center">
                                  <input
                                    required
                                    className={`form-control inline form-control-sm shadow-sm text-center ${
                                      item.approvedQuantity >
                                      item.receivedQuantity
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    max={item.receivedQuantity}
                                    placeholder="App Qty"
                                    value={
                                      item.approvedQuantity >= 0
                                        ? item.approvedQuantity
                                        : ''
                                    }
                                    onChange={(e) => {
                                      if (
                                        +e.target.value % 1 !== 0 &&
                                        [
                                          'Nos',
                                          'Units',
                                          'Pairs',
                                          'Sets'
                                        ].includes(item.unit)
                                      ) {
                                        return;
                                      }

                                      const newCart = [...cart];

                                      newCart[index].approvedQuantity = +Number(
                                        e.target.value
                                      ).toFixed(2);

                                      setCart(newCart);
                                    }}
                                  />

                                  <label className="invalid-feedback mb-0">
                                    Please check quantity again
                                  </label>
                                </td>

                                <td className="text-center">
                                  <i
                                    className="ml-2 fas fa-times-circle text-danger"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setPending([...pending, item]);

                                      setCart(
                                        cart
                                          .slice(0, index)
                                          .concat(cart.slice(index + 1))
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {Object.keys(cart).length === 0 && (
                          <div className="text-center">
                            <img
                              className="mt-4 mb-4"
                              src="/cart.svg"
                              alt="Requisition Cart"
                              draggable="false"
                              style={{ opacity: 0.2, height: 150, width: 150 }}
                            />

                            <h5
                              className="text-center mb-4"
                              style={{ opacity: 0.6 }}
                            >
                              CART IS EMPTY
                            </h5>
                          </div>
                        )}

                        <div className="btn-group float-right">
                          <button
                            className="btn btn-danger btn-sm shadow-sm"
                            type="button"
                            onClick={() => {
                              setPending(approve);
                              setCart([]);
                              setFilter([]);
                            }}
                          >
                            Clear
                          </button>
                          <button
                            className="btn btn-success btn-sm shadow-sm ml-2"
                            disabled={
                              !(cart[0] && cart[0].approvedQuantity > 0)
                            }
                            type="submit"
                          >
                            Approve Received Items
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <ConfirmationModal
        bodyText="Are you sure, you want to create this GR. This action cannot be reversed"
        title="Confirm Goods Received"
        modalId="confimGoodsReceived"
        buttonTitle="Create GR"
        buttonStyle="success"
        dismissOnClick
        response={() => {
          onSubmitDo();
          window.$('#successModal').modal('show');
        }}
      />
      <SuccessModal title="Goods Receipt Generated" redirectTo="#" />
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  username: state.user.username,
  approve: state.goodsreceived.approve,
  error: state.goodsreceived.error
});

export default connect(mapStateToProps, {
  getGrToBeApproved,
  approveGoodsReceived
})(ViewGoodsReceived);
