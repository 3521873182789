import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import { getVendorsList, searchVendors } from '../../../actions/poActions';

function SelectVendorModal({
  loading,
  filter,
  setFilter,
  vendors,
  count,
  selected,
  getVendorsList,
  searchVendors,
  setVendor
}) {
  useEffect(() => {
    getVendorsList(1);

    return () => {
      window.$('#selectVendor').modal('hide');
    };
    // eslint-disable-next-line
  }, []);

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
        textAlign: 'center',
        width: '89%'
      }
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '16px',
        fontWeight: 'bold'
      }
    },
    rows: {
      style: {
        fontFamily: 'sans-serif',
        width: '89%'
      },
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        borderRadius: '25px',
        outline: '1px solid #FFFFFF'
      }
    },
    pagination: {
      style: {
        border: 'none'
      }
    }
  };

  const columns = [
    {
      name: 'Vendor Name',
      selector: 'particulars',
      sortable: true
    },
    {
      name: 'Address',
      selector: 'address',
      left: true
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
      center: true
    },
    {
      name: 'Country',
      selector: 'country',
      sortable: true,
      center: true
    },
    {
      name: 'GSTIN',
      selector: 'gstin',
      sortable: true,
      center: true
    }
  ];

  return (
    <div
      className="modal fade"
      id="selectVendor"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header" style={{ backgroundColor: '#F8F8F8' }}>
            <h5 className="modal-title" id="exampleModalLabel">
              Select Vendor {loading && <Spinner />}
            </h5>
            <button
              className="close"
              type="button"
              aria-label="Close"
              onClick={() => window.$('#selectVendor').modal('hide')}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="float-left">
              <div className="input-group no-focus shadow-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white text-black">
                    <i className="fas fa-search" />
                  </span>
                </div>

                <input
                  type="text"
                  className="form-control shadow-sm"
                  placeholder="Search"
                  onChange={(e) => searchVendors(e.target.value)}
                />
              </div>
            </div>

            <DataTable
              style={{ overflow: 'hidden' }}
              noHeader
              columns={columns}
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              data={vendors}
              onRowClicked={(row) => {
                setVendor &&
                  setVendor(selected, row.serialNumber, row.particulars);
                setFilter &&
                  setFilter({
                    ...filter,
                    vendor: row.serialNumber,
                    vendorName: row.particulars
                  });
                window.$('#selectVendor').modal('hide');
              }}
              pagination
              paginationTotalRows={count}
              paginationServer
              paginationServerOptions={{
                persistSelectedOnPageChange: true
              }}
              onChangePage={async (pageNumber) => getVendorsList(pageNumber)}
              paginationPerPage={15}
            />
          </div>
          <div className="modal-footer" style={{ backgroundColor: '#F8F8F8' }}>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => window.$('#selectVendor').modal('hide')}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.purchaseorders.loading,
  vendors: state.purchaseorders.vendors,
  count: state.purchaseorders.vendorsCount,
  pagination: state.purchaseorders.vendorsPagination
});

export default connect(mapStateToProps, { getVendorsList, searchVendors })(
  SelectVendorModal
);
