import React from 'react';
import { connect } from 'react-redux';

function EditableListItem({
  name,
  edit,
  role,
  value,
  prefix,
  unit,
  onSubmitDo,
  onClickHandler,
  onChangeHandler
}) {
  if (edit) {
    return (
      <div className="row">
        <div className="col-sm-10">
          <input
            className="form-control"
            placeholder={value}
            onChange={e => {
              if (
                +e.target.value % 1 !== 0
                && ['Nos', 'Units', 'Pairs', 'Sets'].includes(unit)
              ) {
                return;
              }

              onChangeHandler(e.target.value);
            }}
          />
        </div>
        <div className="col-sm-2">
          <i
            onClick={() => onSubmitDo(true)}
            className="btn btn-success btn-sm float-right"
          >
            Save
          </i>
        </div>
      </div>
    );
  }
  return (
    <>
      <span className="text-black">
        {prefix}
        {' '}
        {value}
        {' '}
        {unit}
      </span>
      {role === 'inventory' && (
      <i
        onClick={() => onClickHandler(name)}
        className="fas fa-edit text-warning float-right iccoon"
      />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  role: state.user.role
});

export default connect(mapStateToProps, {})(EditableListItem);
