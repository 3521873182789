import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Footer from '../common/Footer';
import Topbar from '../common/Topbar';
import Sidebar from '../common/Sidebar';
import SearchSort from '../common/SearchSort';
import LogoutModal from '../common/LogoutModal';
import customStyles from '../utils/dataTableCustomStyle';
import { getGoodsRegisterItems } from '../../actions/grActions';

function GoodsRegister({
  role,
  count,
  client,
  loading,
  register,
  getGoodsRegisterItems
}) {
  const [filter, setFilter] = useState({
    phrase: '',
    searchBy: 'by_order',
    createdBy: '',
    status: '',
    createdAt: '2024'
  });

  useEffect(() => {
    getGoodsRegisterItems(1, filter);
    // eslint-disable-next-line
  }, []);

  let rateWithGst;
  let total;

  const columns = [
    {
      name: 'GR Number',
      selector: 'grNumber',
      sortable: true,
      center: true,
      format: (row) => (!row.grNumber ? 'NA' : row.grNumber)
    },
    {
      name: 'PO Number',
      selector: 'poNumber',
      sortable: true,
      center: true
    },
    {
      name: 'DM Number',
      selector: 'dmNumber',
      sortable: true,
      center: true,
      format: (row) => (!row.dmNumber ? 'NA' : row.dmNumber)
    },
    {
      name: 'Bill Number',
      selector: 'billNumber',
      sortable: true,
      center: true,
      format: (row) => (!row.billNumber ? 'NA' : row.billNumber)
    },
    {
      name: 'Particulars',
      selector: 'item',
      width: '25%',
      sortable: true,
      left: true
    },
    {
      name: 'Quantity',
      selector: 'approvedQuantity',
      sortable: true,
      center: true,
      format: (row) => {
        if (!row.approvedQuantity) {
          return 'NA';
        }

        return `${row.approvedQuantity} ${row.unit}`;
      }
    },
    {
      name: 'Status of Inspection',
      selector: 'status',
      sortable: true,
      center: true
    },
    {
      name: 'Rate W/Tax',
      omit: role !== 'admin',
      center: true,
      cell: (row) => {
        total =
          row.poDetails.rate -
          (row.poDetails.rate / 100) * (row.poDetails.discount || 0);

        rateWithGst = total + (total / 100) * row.poDetails.po.gstPercent;

        return row.approvedQuantity !== null
          ? `₹ ${(rateWithGst * row.approvedQuantity).toLocaleString()}`
          : 'NA';
      }
    },
    {
      name: 'PDF',
      center: true,
      cell: (row) => (
        <div>
          <button
            disabled={row.status !== 'APPROVED' && true}
            style={{ cursor: row.status !== 'APPROVED' && 'not-allowed' }}
            className="btn btn-light shadow-sm btn-sm fas fa-eye text-info mr-1"
            onClick={() => {
              import('../Production/GoodsReceived/GoodsReceivedPDF').then(
                ({ default: GoodsReceivedPDF }) =>
                  GoodsReceivedPDF({
                    gr: row,
                    vendorId: row.poDetails.po.vendor,
                    view: true,
                    client
                  })
              );
            }}
          />
          <button
            disabled={row.status !== 'APPROVED' && true}
            style={{ cursor: row.status !== 'APPROVED' && 'not-allowed' }}
            className="btn btn-light shadow-sm btn-sm"
            onClick={() => {
              import('../Production/GoodsReceived/GoodsReceivedPDF').then(
                ({ default: GoodsReceivedPDF }) =>
                  GoodsReceivedPDF({
                    gr: row,
                    vendorId: row.poDetails.po.vendor,
                    client
                  })
              );
            }}
          >
            <i className="fas fa-download text-info" />
          </button>
        </div>
      )
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Goods Register" loading={loading} />

            <div className="container-fluid bg-white shadow-sm pt-4">
              <SearchSort
                search={(phrase) => {
                  setFilter({ ...filter, phrase });
                  getGoodsRegisterItems(1, { ...filter, phrase });
                }}
                allowSearchOptions
                setFilter={(res) => {
                  setFilter({ ...filter, ...res });
                  getGoodsRegisterItems(1, { ...filter, ...res });
                }}
                clearFilter={() => {
                  setFilter({ createdAt: '2024' });
                  getGoodsRegisterItems(1);
                }}
                filter={filter}
                filters
                role="production,inventory"
              />

              <DataTable
                noHeader
                columns={columns}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                data={register}
                pagination
                paginationTotalRows={count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                paginationComponentOptions={{ noRowsPerPage: true }}
                onChangePage={async (pageNumber) =>
                  getGoodsRegisterItems(pageNumber, filter)
                }
                paginationPerPage={15}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  client: state.user.client,
  register: state.goodsreceived.register,
  count: state.goodsreceived.registerCount,
  loading: state.goodsreceived.loading
});

export default connect(mapStateToProps, { getGoodsRegisterItems })(
  GoodsRegister
);
