import React from 'react';
import spinner from './spinner.gif';

function Spinner({ height, width, classStyle }) {
  let center;

  if (classStyle) {
    center = {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    };
  }

  return (
    <img src={spinner} alt="spinner" style={{ height, width, ...center }} />
  );
}

Spinner.defaultProps = {
  height: '28px',
  width: '28px'
};

export default Spinner;
