import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import {
  getVendorsList,
  searchVendors,
  addNewVendor,
  updateVendor,
  deleteVendor
} from '../../../actions/poActions';
import UpdateVendor from './UpdateVendor';
import SearchSort from '../../common/SearchSort';
import './style.css';
import ConfirmationModal from '../../utils/ConfirmationModal';
import AddNewVendorModal from '../PurchaseOrder/AddNewVendorModal';
import customStyles from '../../utils/dataTableCustomStyle';

function ManageVendors({
  getVendorsList,
  searchVendors,
  deleteVendor,
  updateVendor,
  vendors,
  count,
  pagination,
  loading
}) {
  const [selected, setSelected] = useState('');
  const [time, setTime] = useState('');
  const [filter, setFilter] = useState({ phrase: '' });

  // DELETE VENDOR
  const deleteSelectedVendor = async (serialNumber) => {
    await deleteVendor(serialNumber, pagination && pagination.current);
  };

  // ADD OR UPDATE VENDOR
  const updateVedorFunc = async (data) => {
    await updateVendor(
      data.serialNumber,
      data,
      pagination && pagination.current
    );
  };

  useEffect(() => {
    getVendorsList(1);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'Vendor Name',
      width: '30%',
      selector: 'particulars',
      sortable: true
    },
    {
      name: 'Address',
      width: '35%',
      selector: 'address',
      left: true
    },
    {
      name: 'State',
      width: '10%',
      selector: 'state',
      sortable: true,
      center: true
    },

    {
      name: 'Country',
      width: '7%',
      selector: 'country',
      sortable: true,
      center: true
    },
    {
      name: 'GSTIN',
      width: '12%',
      selector: 'gstin',
      center: true,
      format: (row) => (row.gstin ? row.gstin : '-')
    },
    {
      width: '5%',
      right: true,
      cell: (row) => (
        <>
          <i
            className="fas fa-edit text-warning iccoon mr-3"
            onClick={() => {
              window.$('#updateVendor').modal('show');
              setSelected(row.serialNumber);
              setTime(Date.now());
            }}
          />
          <i
            className="fas fa-trash iccoon text-danger"
            data-toggle="modal"
            data-target="#deleteConfirmation"
            onClick={() => setSelected(row.serialNumber)}
          />
        </>
      )
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Manage Vendors" loading={loading} />

            <div className="container-fluid bg-white shadow-sm pt-4">
              <SearchSort
                filter={filter}
                search={(phrase) => {
                  setFilter({ ...filter, phrase });
                  searchVendors(phrase);
                }}
                addNewButton="addNewVendor"
              />

              <DataTable
                noHeader
                columns={columns}
                customStyles={customStyles}
                highlightOnHover
                data={vendors}
                pagination
                paginationTotalRows={count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                onChangePage={async (pageNumber) => getVendorsList(pageNumber)}
                paginationPerPage={15}
              />

              <AddNewVendorModal />

              <UpdateVendor
                selected={selected}
                time={time}
                vendors={vendors}
                updateVendor={(res) => {
                  updateVedorFunc(res);
                  window.$('#updateVendor').modal('hide');
                }}
              />

              <ConfirmationModal
                modalId="deleteConfirmation"
                title="Delete Vendor"
                bodyText="Are you sure you want to delete this vendor. This action can not be reversed"
                buttonTitle="Delete"
                selected={selected}
                response={(id) => {
                  deleteSelectedVendor(id);
                  window.$('#deleteConfirmation').modal('hide');
                }}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  vendors: state.purchaseorders.vendors,
  count: state.purchaseorders.vendorsCount,
  pagination: state.purchaseorders.vendorsPagination
});

export default connect(mapStateToProps, {
  getVendorsList,
  searchVendors,
  addNewVendor,
  updateVendor,
  deleteVendor
})(ManageVendors);
