import axios from 'axios';
import {
  GET_REQUISITIONS,
  GET_WFA_REQUISITIONS,
  GET_APPROVED_REQUISITIONS,
  GET_MY_REQUISITION_ITEMS,
  SET_ERROR,
  SET_LOADING
} from './types';
import setError from '../components/utils/asyncHandler';

export const getRequisitions = (page, filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    let res;

    if (filter) {
      res = await axios.post(
        `/api/requisition/search/${filter.phrase || 'null'}?page=${
          page || 1
        }&sort=createdAt_DESC`,
        { filter },
        {
          headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
        }
      );
    } else {
      res = await axios.get(
        `/api/requisition?page=${page || 1}&sort=createdAt_DESC`,
        {
          headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
        }
      );
    }

    dispatch({ type: GET_REQUISITIONS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getGroupedRequisitions = (page, group) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(
      `/api/requisition?page=${page || 1}&group=${
        group || ''
      }&sort=createdAt_DESC`,
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    group === 'APPROVED'
      ? dispatch({ type: GET_APPROVED_REQUISITIONS, payload: res.data })
      : dispatch({ type: GET_WFA_REQUISITIONS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getRequisitionByID = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/requisition/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
    });

    return res.data.data.requisitionItems;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const addNewRequisition = (data) => async (dispatch) => {
  try {
    await axios.post('/api/requisition', data, {
      headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
    });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const editRequisitionDetails =
  (requisitionNumber, requisitionBody, body) => async (dispatch) => {
    try {
      await axios.put(
        `/api/requisition/edit/${requisitionNumber}`,
        { data: [...body], requisition: { ...requisitionBody } },
        {
          headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
        }
      );
    } catch (err) {
      setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    }
  };

export const updateRequisition = (id, data) => async (dispatch) => {
  try {
    await axios.put(
      `/api/requisition/update/${id}`,
      { requisitionItems: [...data] },
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    const res = await axios.get(
      '/api/requisition?page=1&group=WFA&sort=createdAt_DESC',
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    const res1 = await axios.get(
      '/api/requisition?page=1&group=APPROVED&sort=createdAt_DESC',
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    dispatch({ type: GET_APPROVED_REQUISITIONS, payload: res1.data });
    dispatch({ type: GET_WFA_REQUISITIONS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const deleteRequisition = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/requisition/delete/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
    });

    const res = await axios.get(
      '/api/requisition?page=1&group=WFA&sort=createdAt_DESC',
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    const res1 = await axios.get(
      '/api/requisition?page=1&group=APPROVED&sort=createdAt_DESC',
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    dispatch({ type: GET_APPROVED_REQUISITIONS, payload: res1.data });
    dispatch({ type: GET_WFA_REQUISITIONS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const approveRequisition = (id, status) => async (dispatch) => {
  try {
    const action = status || 'approve';

    await axios.post(
      `/api/requisition/approve/${id}`,
      { action },
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    const res = await axios.get(
      '/api/requisition?page=1&group=WFA&sort=createdAt_DESC',
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    const res1 = await axios.get(
      '/api/requisition?page=1&group=APPROVED&sort=createdAt_DESC',
      {
        headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
      }
    );

    dispatch({ type: GET_APPROVED_REQUISITIONS, payload: res1.data });
    dispatch({ type: GET_WFA_REQUISITIONS, payload: res.data });
  } catch (err) {
    dispatch({ type: SET_ERROR, payload: err.response.data.error });
  }
};

export const getAllItemsByUser = (page, search) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const data = await axios.post(
      `/api/requisition/items/all?page=${page || 1}`,
      {
        ...search
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.JWT_TOKEN}`
        }
      }
    );

    dispatch({ type: GET_MY_REQUISITION_ITEMS, payload: data.data });
  } catch (err) {
    dispatch({ type: SET_ERROR, payload: err.response.data.error });
  }
};

export const setItemNotRequired = (body, page, search) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(
      `/api/requisition/items/all?page=${page || 1}`,
      { items: [...body] },
      {
        headers: {
          Authorization: `Bearer ${localStorage.JWT_TOKEN}`
        }
      }
    );

    const data = await axios.post(
      `/api/requisition/items/all?page=${page || 1}`,
      {
        ...search
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.JWT_TOKEN}`
        }
      }
    );

    dispatch({ type: GET_MY_REQUISITION_ITEMS, payload: data.data });
  } catch (err) {
    dispatch({ type: SET_ERROR, payload: err.response.data.error });
  }
};
