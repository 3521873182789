import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import RequisitionItemModal from './RequisitionItemModal';
import {
  getGroupedRequisitions,
  deleteRequisition
} from '../../../actions/requisitionActions';
import ConfirmationModal from '../../utils/ConfirmationModal';
import SuccessModal from '../../utils/SuccessModal';

function ViewRequisitions({
  wfa,
  role,
  loading,
  approved,
  deleteRequisition,
  createRequisition,
  getGroupedRequisitions
}) {
  useEffect(() => {
    getGroupedRequisitions(1, 'WFA');
    getGroupedRequisitions(1, 'APPROVED');

    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = useState('');
  const [deleteId, setDeleteId] = useState('');

  const showRequisitionButton =
    createRequisition === true
      ? {
          title: 'New Requisition',
          to: `/${role}/requisition/new`,
          icon: 'plus'
        }
      : null;

  const columns = [
    {
      selector: 'serialNumber',
      cell: (row) => (
        <div
          onClick={() => {
            setSelected(row.serialNumber);
            window.$('#showRequisitionDetails').modal('show');
          }}
        >
          <h6 className="font-weight-bold pt-3 mb-0 text-black">
            {row.serialNumber}
          </h6>
          <div>
            <div
              className="pb-2"
              data-tag="allowRowEvents"
              style={{
                color: 'grey',
                overflow: 'hidden',
                whiteSpace: 'wrap',
                textOverflow: 'ellipses'
              }}
            >
              {row.createdBy}
            </div>
          </div>
        </div>
      )
    },
    {
      selector: 'createdAt',
      right: true,
      cell: (row) => moment(row.createdAt).format('DD MMMM YYYY')
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar
              title="Requisitions"
              button={showRequisitionButton}
              loading={loading}
            />

            <div className="container-fluid">
              <div className="text-right mb-2">
                {['production', 'inventory'].includes(role) && (
                  <Link
                    to={`/${role}/requisition/items`}
                    className="btn btn-primary btn-sm shadow-sm mr-2"
                  >
                    <i className="fas fa-sticky-note" /> My Items
                  </Link>
                )}

                <Link
                  to={`/${role}/requisition/register`}
                  className="btn btn-info btn-sm shadow-sm"
                >
                  <i className="fas fa-sticky-note" /> Requisition Register
                </Link>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <DataTable
                    title="Waiting For Approval"
                    noTableHead
                    columns={columns}
                    highlightOnHover
                    pointerOnHover
                    data={wfa.data}
                    onRowClicked={(row) => {
                      setSelected(row.serialNumber);
                      window.$('#showRequisitionDetails').modal('show');
                    }}
                    pagination
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationTotalRows={wfa.count}
                    paginationServer
                    paginationServerOptions={{
                      persistSelectedOnPageChange: true
                    }}
                    onChangePage={async (pageNumber) =>
                      await getGroupedRequisitions(pageNumber, 'WFA')
                    }
                    paginationPerPage={15}
                  />
                </div>

                <div className="col-sm-6">
                  <DataTable
                    title="Approved"
                    noTableHead
                    columns={columns}
                    highlightOnHover
                    pointerOnHover
                    data={approved.data}
                    onRowClicked={(row) => {
                      setSelected(row.serialNumber);
                      window.$('#showRequisitionDetails').modal('show');
                    }}
                    pagination
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationTotalRows={approved.count}
                    paginationServer
                    paginationServerOptions={{
                      persistSelectedOnPageChange: true
                    }}
                    onChangePage={async (pageNumber) =>
                      await getGroupedRequisitions(pageNumber, 'APPROVED')
                    }
                    paginationPerPage={15}
                  />
                </div>
              </div>

              <RequisitionItemModal
                selected={selected}
                deleteRequisition={(res) => {
                  setDeleteId(res);
                  window.$('#showRequisitionDetails').modal('hide');
                  window.$('#confirmDeleteRequisition').modal('show');
                }}
              />

              <SuccessModal
                title={`Requsition ${deleteId} Deleted`}
                redirectTo={`/${role}/requisition`}
              />

              <ConfirmationModal
                bodyText="Are you sure you want to delete this requisition. This step cannot be reversed."
                modalId="confirmDeleteRequisition"
                buttonTitle={`Delete Requisition ${deleteId}`}
                title="Delete Requisition"
                buttonStyle="danger"
                dismissOnClick
                response={() => {
                  deleteRequisition(deleteId);
                  setDeleteId('');
                  window.$('#successModal').modal('show');
                }}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  error: state.requisitions.error,
  loading: state.requisitions.loading,
  wfa: state.requisitions.requisitions.wfa,
  approved: state.requisitions.requisitions.approved
});

export default connect(mapStateToProps, {
  getGroupedRequisitions,
  deleteRequisition
})(ViewRequisitions);
