import {
  GET_REQUISITIONS,
  GET_WFA_REQUISITIONS,
  GET_APPROVED_REQUISITIONS,
  GET_MY_REQUISITION_ITEMS,
  SET_ERROR,
  SET_LOADING
} from '../actions/types';

const initialState = {
  requisitionItems: [],
  requisitions: {
    wfa: {
      data: [],
      count: 0
    },
    approved: {
      data: [],
      count: 0
    }
  },
  requisition: [],
  myItems: {
    data: [],
    count: 0
  },
  loading: false,
  error: null
};

const requisitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUISITIONS:
      return {
        ...state,
        requisitionItems: action.payload.data,
        count: action.payload.count,
        loading: false
      };

    case GET_WFA_REQUISITIONS:
      return {
        ...state,
        requisitions: {
          ...state.requisitions,
          wfa: {
            data: action.payload.data,
            count: action.payload.count
          }
        },
        loading: false
      };

    case GET_APPROVED_REQUISITIONS:
      return {
        ...state,
        requisitions: {
          ...state.requisitions,
          approved: {
            data: action.payload.data,
            count: action.payload.count
          }
        },
        loading: false
      };

    case GET_MY_REQUISITION_ITEMS:
      return {
        ...state,
        loading: false,
        myItems: {
          data: action.payload.data.rows,
          count: action.payload.data.count
        }
      };

    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default requisitionReducer;
