import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import ConfirmationModal from '../../utils/ConfirmationModal';
import {
  getAllInventoryCategories,
  createConfig,
  getPODestinations,
  updateInventoryCategory,
  deleteInventoryCategory
} from '../../../actions/configActions';

function ManageInventoy({
  config,
  getAllInventoryCategories,
  createConfig,
  getPODestinations,
  updateInventoryCategory,
  deleteInventoryCategory,
  error
}) {
  const [selected, setSelected] = useState('');
  const [categories, setCategory] = useState([]);
  const [newCategory, setNewCategory] = useState({
    new: false,
    name: ''
  });
  const [newDestination, setNewDestination] = useState({
    new: false,
    configName: '',
    configValue: ''
  });

  // Hide alert after 3s
  error
    && window.setTimeout(() => {
      window.$('#error-alert').hide();
    }, [3000]);

  const getCategories = async () => {
    const res = await getAllInventoryCategories();
    setCategory(res);
  };

  useEffect(() => {
    getCategories();
    getPODestinations();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Settings" />

            <div className="container-fluid">
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show mt-2 shadow-sm"
                  id="error-alert"
                  role="alert"
                >
                  {error}

                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              <div className="row">
                <div className="col-sm-12">
                  <div className="card shadow-sm mb-4">
                    <div className="card-body">
                      <h5 className="text-black font-weight-bold">
                        Inventory Category
                      </h5>
                      <div className="table-responsive">
                        <table
                          className="table table-borderless table-hover small"
                          id="dataTable"
                          width="100%"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr className="text-center text-white bg-dark">
                              <th className="text-left">Category Name</th>
                              <th>Items in table</th>
                              <th width="7%">Clear Scrap</th>
                              <th width="7%">Edit</th>
                            </tr>
                          </thead>

                          <tbody>
                            {categories
                              && categories.map(category => (
                                <tr
                                  className="text-center text-black"
                                  key={category.category}
                                >
                                  <td className="text-left font-weight-bold">
                                    {category.category.toUpperCase()}
                                  </td>
                                  <td>{category.count}</td>
                                  <td
                                    onClick={() => setSelected(category.category)}
                                  >
                                    <i
                                      className="fas fa-broom text-danger iccoon"
                                      data-target="#clearConfirmation"
                                      data-toggle="modal"
                                    />
                                  </td>
                                  <td
                                    onClick={() => setSelected(category.category)}
                                  >
                                    <i
                                      className="fas fa-trash text-danger iccoon"
                                      data-target="#deleteCategoryConfirmation"
                                      data-toggle="modal"
                                    />
                                  </td>
                                </tr>
                              ))}

                            {newCategory.new && (
                              <tr className="text-center text-black">
                                <td className="text-left">
                                  <input
                                    className="form-control form-control-sm small"
                                    placeholder="Category name"
                                    value={newCategory.name}
                                    onChange={e => setNewCategory({
                                      new: true,
                                      name: e.target.value
                                    })}
                                  />
                                </td>
                                <td>0</td>
                                <td />
                                <td
                                  onClick={async () => {
                                    await createConfig(
                                      'inventory_category',
                                      newCategory.name,
                                      newCategory.name
                                    );
                                    setNewCategory({ new: false, name: '' });
                                    await getCategories();
                                  }}
                                >
                                  <i className="btn btn-info shadow-sm btn-sm">
                                    ADD
                                  </i>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        <div style={{ marginLeft: '15px' }}>
                          <hr />
                          <i
                            className="btn btn-success shadow-sm btn-sm"
                            onClick={() => setNewCategory({ new: true })}
                          >
                            Add New Category
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="card shadow-sm mb-4">
                    <div className="card-body">
                      <h5 className="text-black font-weight-bold">
                        Multiple PO Receival Destinations
                      </h5>
                      <div className="table-responsive">
                        <table
                          className="table table-borderless table-hover small"
                          id="dataTable"
                          width="100%"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr className="text-center text-white bg-dark">
                              <th className="text-left">Destination</th>
                              <th>Destination Initials</th>
                              <th width="7%">Delete</th>
                            </tr>
                          </thead>

                          <tbody>
                            {config.destinations
                              && config.destinations.map(destination => (
                                <tr
                                  className="text-center text-black"
                                  key={destination.configValue}
                                >
                                  <td className="text-left font-weight-bold">
                                    {destination.configName.toUpperCase()}
                                  </td>
                                  <td>{destination.configValue}</td>

                                  <td
                                    onClick={() => setSelected(destination.configName)}
                                  >
                                    <i
                                      className="fas fa-trash text-danger"
                                      style={{ cursor: 'not-allowed' }}
                                    />
                                  </td>
                                </tr>
                              ))}

                            {newDestination.new && (
                              <tr className="text-center text-black">
                                <td className="text-left">
                                  <input
                                    className="form-control form-control-sm small"
                                    placeholder="Category name"
                                    value={newDestination.configName}
                                    onChange={e => setNewDestination({
                                      ...newDestination,
                                      configName: e.target.value
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control form-control-sm small"
                                    placeholder="Category name"
                                    value={newDestination.configValue}
                                    onChange={e => setNewDestination({
                                      ...newDestination,
                                      configValue: e.target.value
                                    })}
                                  />
                                </td>
                                <td
                                  onClick={async () => {
                                    await createConfig(
                                      'po_destination',
                                      newDestination.configName,
                                      newDestination.configValue
                                    );
                                    setNewDestination({
                                      new: false,
                                      configName: '',
                                      configValue: ''
                                    });
                                    await getPODestinations();
                                  }}
                                >
                                  <i className="btn btn-info shadow-sm btn-sm">
                                    ADD
                                  </i>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        <div style={{ marginLeft: '15px' }}>
                          <hr />
                          <i
                            className="btn btn-success shadow-sm btn-sm"
                            onClick={() => setNewDestination({ new: true })}
                          >
                            Add New Destination
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="text-black font-weight-bold">Reset In Use</h5>
                  <p className="text-black mt-3">
                    This will set
                    {' '}
                    <b>IN USE</b>
                    {' '}
                    of all the inventory items to
                    {' '}
                    <b>0</b>
                    . Please be aware that this cannot be reversed.
                  </p>

                  <p className="font-italic">
                    <b>Note:</b>
                    {' '}
                    This can be done only once every month on the
                    1st day.
                  </p>

                  <button className="btn btn-danger shadow-sm">
                    Reset Now!
                  </button>
                </div>
              </div>

              <ConfirmationModal
                title="Clear Scrap"
                modalId="clearConfirmation"
                buttonTitle="Confirm Clear Scrap"
                dismissOnClick
                selected={selected}
                response={async categoryId => {
                  updateInventoryCategory(categoryId);
                  await getCategories();
                }}
                bodyText="Are you sure you want to clear scrap quantity of this category. This cannot be reversed."
              />

              <ConfirmationModal
                title="Delete Category"
                modalId="deleteCategoryConfirmation"
                buttonTitle="Confirm Delete"
                dismissOnClick
                selected={selected}
                response={async categoryId => {
                  deleteInventoryCategory(categoryId);
                  await getCategories();
                }}
                bodyText="Are you sure you want to delete this category. This cannot be reversed. Note that you should delete all items related to this category from inventory first"
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = state => ({
  error: state.inventory.error,
  config: state.config
});

export default connect(mapStateToProps, {
  getAllInventoryCategories,
  createConfig,
  getPODestinations,
  updateInventoryCategory,
  deleteInventoryCategory
})(ManageInventoy);
