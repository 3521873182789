import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Topbar from '../../common/Topbar';
import Footer from '../../common/Footer';
import Sidebar from '../../common/Sidebar';
import SearchSort from '../../common/SearchSort';
import LogoutModal from '../../common/LogoutModal';
import customStyles from '../../utils/dataTableCustomStyle';
import { getAllPOItemsPendingAfterApproval } from '../../../actions/poActions';

function PurchaseOrderTracker({
  loading,
  pending,
  getAllPOItemsPendingAfterApproval
}) {
  const [filter, setFilter] = useState({
    phrase: '',
    createdBy: '',
    status: ''
  });

  useEffect(() => {
    window.location.search && setFilter({ ...filter, status: 'PO_APPROVED' });
    getAllPOItemsPendingAfterApproval(1, {
      ...filter,
      status: window.location.search ? 'PO_APPROVED' : ''
    });
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'Item Name',
      selector: 'item',
      sortable: true,
      width: '30%',
      wrap: true
    },
    {
      name: 'Quantity',
      center: true,
      cell: (row) => `${row.quantity} ${row.unit}`,
      width: '10%'
    },
    {
      name: 'Requisition',
      center: true,
      cell: (row) => (
        <div className="text-center">
          <div className="font-weight-bold">{row.requisitionNumber}</div>
          <div>{row.requisition && row.requisition.createdBy}</div>
        </div>
      ),
      width: '15%'
    },
    {
      name: 'PO',
      center: true,
      cell: (row) => (
        <div className="text-center">
          <div className="font-weight-bold">{row.poNumber}</div>
          <div>{row.po.createdBy}</div>
        </div>
      ),
      width: '15%'
    },
    {
      name: 'Approved By',
      center: true,
      cell: (row) => {
        if (row.po.users) {
          return `${row.po.users.firstName} ${row.po.users.lastName}`;
        }
        return '-';
      },
      width: '15%'
    },
    {
      name: 'GR Status',
      selector: 'requiredBy',
      sortable: true,
      center: true,
      format: (row) => {
        if (row.gr.length > 0) {
          return row.gr[0].status;
        }
        return 'Not Received at Security';
      },
      width: '15%'
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Track Purchase Items" loading={loading} />

            <div className="container-fluid bg-white shadow-sm pt-4">
              <SearchSort
                search={(phrase) => {
                  setFilter({ ...filter, phrase });
                  getAllPOItemsPendingAfterApproval(1, { ...filter, phrase });
                }}
                exports
                role="production,inventory"
                filters={{
                  createdBy: true,
                  status: [
                    {
                      title: 'Waiting for Approval',
                      value: 'PO_GENERATED'
                    },
                    {
                      title: 'Approved',
                      value: 'PO_APPROVED'
                    },
                    {
                      title: 'Received',
                      value: 'RECEIVED'
                    },
                    {
                      title: 'Partially Received',
                      value: 'PARTIALY_RECEIVED'
                    },
                    {
                      title: 'GR Generated',
                      value: 'GR_GENERATED'
                    },
                    {
                      title: 'Rejected',
                      value: 'PO_REJECTED'
                    }
                  ],
                  createdAt: false
                }}
                filter={filter}
                setFilter={(res) => {
                  setFilter({ ...filter, ...res });
                  getAllPOItemsPendingAfterApproval(1, { ...filter, ...res });
                }}
                clearFilter={() => {
                  setFilter({});
                  getAllPOItemsPendingAfterApproval(1, {});
                }}
              />

              <DataTable
                noHeader
                columns={columns}
                highlightOnHover
                pointerOnHover
                data={pending.data}
                customStyles={customStyles}
                pagination
                paginationTotalRows={pending.count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                paginationComponentOptions={{ noRowsPerPage: true }}
                onChangePage={async (pageNumber) =>
                  await getAllPOItemsPendingAfterApproval(pageNumber, filter)
                }
                paginationPerPage={15}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  pending: state.purchaseorders.poPendingItems,
  error: state.purchaseorders.error,
  loading: state.purchaseorders.loading
});

export default connect(mapStateToProps, { getAllPOItemsPendingAfterApproval })(
  PurchaseOrderTracker
);
