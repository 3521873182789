import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

function Topbar({ title, user, button, loading, search }) {
  const history = useHistory();

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-2 static-top shadow-sm">
      {/* <!-- Sidebar Toggle (Topbar) --> */}
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        <i className="fa fa-bars" />
      </button>
      {/* <!-- Topbar Title --> */}
      <h3
        className="mr-auto ml-md-3 my-2 my-md-0 mw-100 font-weight-bold text-dark"
        style={
          window.innerWidth < 600
            ? { fontSize: '1.2rem' }
            : { fontSize: '1.6rem' }
        }
      >
        <Link
          to="#"
          onClick={() => history.goBack()}
          style={{
            display: window.innerWidth < 600 ? 'none' : '',
            height: '1.8rem'
          }}
          className="btn btn-light btn-sm shadow-sm mr-3 text-dark mb-1"
        >
          <span
            className="fas fa-long-arrow-alt-left"
            style={{ fontSize: '18px' }}
          />
        </Link>
        {title || ''}
      </h3>

      {loading && (
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}

      {/* <!-- Topbar Search --> */}
      {search && (
        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light border-0 small"
              placeholder="Search for..."
              onChange={(e) => search(e.target.value)}
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
          </div>
        </form>
      )}

      {/* Button on the top */}
      {button && (
        <Link
          to={button.to || '#'}
          onClick={() =>
            button.trigger && window.$(`#${button.trigger}`).modal('show')
          }
          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <i className={`fas fa-${button.icon} fa-sm text-white-50`} />
          {'  '}
          {button.title}
        </Link>
      )}

      {/* <!-- Topbar Navbar --> */}
      <ul className="navbar-nav ml-auto">
        <div className="topbar-divider d-none d-sm-block" />

        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
          <p
            className="nav-link dropdown-toggle cursor-on-hover"
            style={{ marginBottom: '0rem' }}
            to="/"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {user.username &&
                user.username.charAt(0).toUpperCase() + user.username.slice(1)}
            </span>
            <img
              className="img-profile rounded-circle"
              src={user.profilePicture || '../../img/default_profile.png'}
              alt="Profile Pic"
            />
          </p>
          {/* <!-- Dropdown - User Information --> */}
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <Link className="dropdown-item" to={`/${user.role}/profile`}>
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
              Profile
            </Link>
            <Link
              className="dropdown-item"
              to={user.role === 'admin' ? `/${user.role}/settings` : '#'}
            >
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
              Settings
            </Link>
            {/* <Link className="dropdown-item" to="#">
              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
              Activity Log
            </Link> */}
            <div className="dropdown-divider" />
            <Link
              className="dropdown-item"
              to="#"
              data-toggle="modal"
              data-target="#logoutModal"
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Topbar);
