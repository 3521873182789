import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginUser } from '../../actions/authActions';

function Login({ error, loginUser, history }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmitDo = async (e) => {
    e.preventDefault();

    const login = await loginUser(username, password);

    if (login.success === true) {
      setUsername('');
      setPassword('');
      history.push(`/${login.role}`);
    }

    setPassword('');
  };

  return (
    <div style={{ backgroundColor: '#999999' }}>
      <div className="limiter">
        <div className="container-login100">
          <div
            className="login100-more"
            style={{ backgroundImage: "url('./bg-img.svg')" }}
          />

          <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
            <form
              className="login100-form validate-form"
              onSubmit={(e) => onSubmitDo(e)}
            >
              <span className="login100-form-title p-b-59">
                Procflo - Procurement & Spend Management
              </span>
              <div
                className="wrap-input100 validate-input"
                data-validate="Valid email is required: ex@abc.xyz"
              >
                <span className="label-input100">Email</span>
                <input
                  className="input100"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="email"
                  name="email"
                  placeholder="Email addess..."
                />
                <span className="focus-input100" />
              </div>
              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <span className="label-input100">Password</span>
                <input
                  className="input100"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="pass"
                  placeholder="*************"
                />
                <span className="focus-input100" />
              </div>
              {error && <p className="text-danger">{error}</p>}
              <div className="flex-m w-full p-b-33">
                <div className="contact100-form-checkbox">
                  <input
                    className="input-checkbox100"
                    id="ckb1"
                    type="checkbox"
                    name="remember-me"
                  />
                  <label className="label-checkbox100" htmlFor="ckb1">
                    <span className="txt1">Remember Me</span>
                  </label>
                </div>
              </div>
              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn" />
                  <button className="login100-form-btn" type="submit">
                    Log In
                  </button>
                </div>
              </div>

              <button
                className="login100-form-btn"
                // onClick={methodDoesNotExist}
              >
                Break the world
              </button>

              <div className="flex-m w-full p-b-33 mt-4">
                <div className="contact100-form-checkbox">
                  <Link to="/forgotpassword" className="txt1">
                    Forgot Password ?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.user.error
});

export default connect(mapStateToProps, { loginUser })(Login);
