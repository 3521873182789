import {
  SET_ERROR,
  SET_ALL_PO,
  SET_WFA_PO,
  SET_VENDORS,
  SET_LOADING,
  SET_APPROVED_PO,
  SET_PENDING_ITEMS,
  GET_ALL_GR_REVERSAL,
  SET_PO_PENDING_ITEMS,
  ADD_PO_ITEM_INTO_CART,
  SET_PO_MONTHLY_SPENDINGS
} from '../actions/types';

const initialState = {
  purchaseorders: {
    wfa: {
      data: [],
      count: 0
    },
    approved: {
      data: [],
      count: 0
    },
    register: {
      data: [],
      count: 0
    }
  },
  pendingItems: [],
  poPendingItems: {
    data: [],
    count: 0
  },
  new: [],
  vendors: [],
  vendorsCount: 0,
  vendorsPagination: {},
  monthlySpendings: null,
  grReversal: {
    rows: [],
    count: 0
  },
  loading: false,
  error: null
};

const poReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PO:
      return {
        ...state,
        purchaseorders: {
          ...state.purchaseorders,
          register: {
            data: action.payload.data,
            count: action.payload.count
          }
        },
        loading: false
      };

    case SET_WFA_PO:
      return {
        ...state,
        purchaseorders: {
          ...state.purchaseorders,
          wfa: {
            data: action.payload.data,
            count: action.payload.count
          }
        },
        loading: false
      };

    case ADD_PO_ITEM_INTO_CART:
      return {
        ...state,
        new: action.payload
      };

    case SET_APPROVED_PO:
      return {
        ...state,
        purchaseorders: {
          ...state.purchaseorders,
          approved: {
            data: action.payload.data,
            count: action.payload.count
          }
        },
        loading: false
      };

    case SET_PENDING_ITEMS:
      return {
        ...state,
        pendingItems: action.payload,
        loading: false
      };

    case SET_PO_PENDING_ITEMS:
      return {
        ...state,
        poPendingItems: {
          data: action.payload.data,
          count: action.payload.count
        },
        loading: false
      };

    case SET_PO_MONTHLY_SPENDINGS:
      return {
        ...state,
        loading: false,
        monthlySpendings: action.payload
      };

    case SET_VENDORS:
      return {
        ...state,
        vendors: action.payload.data,
        vendorsCount: action.payload.count,
        vendorsPagination: action.payload.pagination,
        loading: false
      };

    case GET_ALL_GR_REVERSAL:
      return {
        ...state,
        loading: false,
        grReversal: {
          rows: action.payload.data,
          count: action.payload.count
        }
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default poReducer;
