import axios from 'axios';
import {
  SET_ALL_PO,
  SET_WFA_PO,
  SET_VENDORS,
  SET_LOADING,
  SET_APPROVED_PO,
  SET_PENDING_ITEMS,
  GET_ALL_GR_REVERSAL,
  SET_PO_PENDING_ITEMS,
  ADD_PO_ITEM_INTO_CART,
  SET_PO_MONTHLY_SPENDINGS
} from './types';
import setError from '../components/utils/asyncHandler';

export const getAllPo = (page, filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    let res;

    if (filter) {
      res = await axios.post(
        `/api/po/search/${filter.phrase || 'null'}?page=${
          page || 1
        }&sort=createdAt_DESC`,
        { filter },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );
    } else {
      res = await axios.get(`/api/po?page=${page || 1}&sort=createdAt_DESC`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      });
    }

    dispatch({ type: SET_ALL_PO, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getGroupedPo = (page, group, notIn) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(
      `/api/po?page=${page || 1}&group=${group || ''}&sort=createdAt_DESC${
        notIn ? '&not=true' : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    group === 'WFA'
      ? dispatch({ type: SET_WFA_PO, payload: res.data })
      : dispatch({ type: SET_APPROVED_PO, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getAllPOItemsPendingAfterApproval =
  (page, filter) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      const res = await axios.post(
        `/api/po/items/pending/${filter.phrase || 'null'}?page=${
          page || 1
        }&sort=id_DESC`,
        { filter },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      dispatch({ type: SET_PO_PENDING_ITEMS, payload: res.data });
    } catch (err) {
      setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    }
  };

export const getPOMonthlySpendings = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/po/spendings', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({
      type: SET_PO_MONTHLY_SPENDINGS,
      payload: res.data.spendings
    });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getPendingRequisitionItems = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/po/pending', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    let items = [];

    // eslint-disable-next-line no-return-assign
    res.data.data.map((item) => (items = [...items, item.item]));

    const existingRate = await axios.post(
      '/api/inventory/search/batch',
      { items },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_PENDING_ITEMS, payload: res.data.data });

    res.data.data.map((item) => {
      const index = existingRate.data.data.items.findIndex(
        (obj) => obj.item === item.item
      );

      // eslint-disable-next-line no-param-reassign
      item.existingRate = existingRate.data.data.items[index];
      return true;
    });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const forceClsoeRequisitionItem =
  (itemsToBeClosed) => async (dispatch) => {
    try {
      await axios.put(
        '/api/po/edit/requisition',
        { items: itemsToBeClosed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      return true;
    } catch (err) {
      return setError(err, (res) =>
        dispatch({ type: res.type, payload: res.data })
      );
    }
  };

export const addItemIntoCart = (body) => async (dispatch) => {
  dispatch({
    type: ADD_PO_ITEM_INTO_CART,
    payload: body
  });
};

export const createPuchaseOrder = (body) => async (dispatch) => {
  try {
    await axios.post('/api/po', body, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const approvePurchaseOrder =
  (id, poNumber, multiple, status) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      const action = status || 'approve';

      await axios.post(
        `/api/po/approve/${id}/${poNumber}?multiple=${multiple || ''}`,
        { action },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      const res = await axios.get(
        '/api/po?page=1&group=WFA_REJECTED_DELETED&sort=createdAt_DESC&not=true',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      const res1 = await axios.get(
        '/api/po?page=1&group=WFA&sort=createdAt_DESC',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      dispatch({ type: SET_APPROVED_PO, payload: res.data });
      dispatch({ type: SET_WFA_PO, payload: res1.data });
    } catch (err) {
      setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    }
  };

export const UNapprovePurchaseOrder = (id, poNumber) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(
      `/api/po/unapprove/${id}/${poNumber}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    const res = await axios.get(
      '/api/po?page=1&group=WFA_REJECTED_DELETED&sort=createdAt_DESC&not=true',
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    const res1 = await axios.get(
      '/api/po?page=1&group=WFA&sort=createdAt_DESC',
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_APPROVED_PO, payload: res.data });
    dispatch({ type: SET_WFA_PO, payload: res1.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const deletePurchaseOrder = (poNumber) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.delete(`/api/po/edit/${poNumber}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    const res = await axios.get(
      '/api/po?page=1&group=WFA_REJECTED_DELETED&sort=createdAt_DESC&not=true',
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    const res1 = await axios.get(
      '/api/po?page=1&group=WFA&sort=createdAt_DESC',
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_APPROVED_PO, payload: res.data });
    dispatch({ type: SET_WFA_PO, payload: res1.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

/**
 * VENDOR ACTIONS
 */

export const getVendorsList = (page) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`/api/vendors?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: SET_VENDORS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const searchVendors = (phrase) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    let res;

    if (phrase === '') {
      res = await axios.get('/api/vendors?page=1', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      });
    } else {
      res = await axios.get(`/api/vendors/search/${phrase || ''}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      });
    }

    dispatch({ type: SET_VENDORS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const addNewVendor = (vendor) => async (dispatch) => {
  try {
    await axios.post('/api/vendors', vendor, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    const res = await axios.get(`/api/vendors/search/${vendor.particulars}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    return res.data.data;
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    return false;
  }
};

export const updateVendor = (id, data, page) => async (dispatch) => {
  try {
    await axios.put(`/api/vendors/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    const res = await axios.get(`/api/vendors?page=${page || 1}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: SET_VENDORS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const deleteVendor = (id, page) => async (dispatch) => {
  try {
    await axios.delete(`/api/vendors/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    const res = await axios.get(`/api/vendors?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: SET_VENDORS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

/**
 * GR REVERSAL
 */

export const getAllReversals = (page) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/po/grreversal/RETURNED?page=${page || 1}&sort=createdAt_DESC`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: GET_ALL_GR_REVERSAL, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const setReversalStatus = (id, page) => async (dispatch) => {
  try {
    await axios.post(
      `/api/po/grreversal/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    const res = await axios.get(
      `/api/po/grreversal/RETURNED?page=${page || 1}&sort=createdAt_DESC`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: GET_ALL_GR_REVERSAL, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};
