import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import {
  getPOMonthlySpendings,
  getGroupedPo,
  UNapprovePurchaseOrder
} from '../../../actions/poActions';
import { printElement } from '../../utils/functions';
import ConfirmationModal from '../../utils/ConfirmationModal';
import { forceClosePOItem } from '../../../actions/grActions';
import SendSMSNotification from '../../common/SendSMSNotification';
import ApprovePurchaseOrder from '../../Admin/PurchaseOrder/ApprovePurchaseOrder';
import './style.css';

function OrderItemModal({
  wfa,
  user,
  client,
  selected,
  deletePO,
  approved,
  register,
  spendings,
  getGroupedPo,
  forceClosePOItem,
  getPOMonthlySpendings,
  UNapprovePurchaseOrder
}) {
  const purchaseorders = [...wfa, ...approved, ...register];
  const { firstName, lastName, role } = user;

  const [vendor, setVendor] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [approvedBy, setApprovedBy] = useState({});
  const [sendRemark, setSendRemark] = useState({});
  const [quotationId, setQuotationId] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [approvedQuotation, setApprovedQuotation] = useState('');
  const [requisitionDetails, setRequisitionDetails] = useState([]);

  const getNotifications = (referenceNumber) => {
    axios
      .get(`/api/config/notification/${referenceNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      })
      .then((res) => setNotifications([...res.data.data]))
      .catch((err) => console.log(err));
  };

  let purchaseorder;
  let poDetails = [];

  useEffect(() => {
    if (purchaseorder) {
      setQuotationId('');
      let vendors = [];

      setApprovedQuotation('');

      const tempReq = [];
      poDetails.map((po) => {
        vendors = [...vendors, po.vendor];

        po.items.map(
          (item) =>
            !tempReq.includes(item.requisitionNumber) &&
            tempReq.push(item.requisitionNumber)
        );

        if (
          po.approved === 'T' ||
          (po.status && !['WFA', 'REJECTED'].includes(po.status))
        ) {
          setApprovedQuotation(po.vendor);
        }

        return true;
      });

      axios
        .post(
          '/api/vendors/find/batch',
          { vendors, userId: purchaseorder.approvedBy },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
            }
          }
        )
        .then((res) => {
          setVendor(res.data.data.vendors);
          setApprovedBy(res.data.data.users);
        })
        .catch(() => setVendor([]));

      axios
        .post(
          '/api/requisition/search/batch/all?attributes=serialNumber,plant,machine,remark',
          { requisitionNumber: tempReq },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
            }
          }
        )
        .then((res) => setRequisitionDetails([...res.data.data]))
        .catch((err) => console.log(err));

      purchaseorder &&
        purchaseorder.hasNotifications &&
        getNotifications(purchaseorder.poNumber);

      purchaseorder &&
        purchaseorder.status === 'WFA' &&
        user.role === 'admin' &&
        !spendings &&
        getPOMonthlySpendings();
    }

    return () => {
      setNotifications([]);
      window.$('#showPurchaseOrderDetails').modal('hide');
    };
    // eslint-disable-next-line
  }, [selected]);

  const index = purchaseorders.findIndex((obj) => obj.poNumber === selected);

  purchaseorder = purchaseorders[index];

  if (purchaseorder && Object.keys(purchaseorder.multiple).length > 0) {
    poDetails = [...purchaseorder.multiple];
  } else {
    poDetails = [{ ...purchaseorder }];
  }

  window.$('#showPurchaseOrderDetails').on('hidden.bs.modal', () => {
    window.$(`#pills-${activeTab}`).removeClass('active');
    window.$(`#pills-${activeTab}-tab`).removeClass('active');
  });

  let status;
  let totalAmount = 0;

  switch (purchaseorder && purchaseorder.status) {
    case 'WFA':
      status = 1;
      break;

    case 'APPROVED':
      status = 2;
      break;

    case 'PARTIALRECEIVED':
      status = 3;
      break;

    case 'RECEIVED':
      status = 4;
      break;

    default:
      status = 0;
      break;
  }

  const findVendorId = (serialNumber) =>
    vendor.findIndex((obj) => obj.serialNumber === serialNumber);

  const findPoId = (serialNumber) =>
    poDetails.findIndex((obj) => obj.vendor === serialNumber);

  let itemsCount = 0;
  let receivedCount = 0;

  if (approvedQuotation && purchaseorder) {
    purchaseorder.items.map((item) => {
      if (item.status === 'GR_GENERATED') {
        receivedCount += 1;
      }

      itemsCount += 1;
      return itemsCount;
    });
  }

  if (itemsCount > 0 && itemsCount === receivedCount) {
    status = 4;
  }

  return (
    <div
      className="modal fade"
      id="showPurchaseOrderDetails"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          {purchaseorder && (
            <div id="printThis" className="modal-body">
              <h4
                className="text text-left mt-1 ml-3 font-weight-bold text-black"
                style={{ fontFamily: 'sans-serif' }}
              >
                #{purchaseorder.poNumber}
                <span
                  className="float-right font-weight-lighter"
                  style={{
                    cursor: 'pointer',
                    marginTop: '-4px'
                  }}
                  data-dismiss="modal"
                >
                  x
                </span>
              </h4>

              <ul
                className="nav nav-pills mb-3"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '-2.5em'
                }}
                id="pills-tab"
                role="tablist"
              >
                {poDetails.map((quotation, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="nav-item" key={index}>
                    <a
                      className={`nav-link shadow-sm small mr-1 ${
                        index === 0 ? 'active' : ''
                      } ${
                        quotation.approved === 'T' ||
                        (quotation.status &&
                          !['WFA', 'DELETED'].includes(quotation.status))
                          ? quotation.status && quotation.status === 'REJECTED'
                            ? 'btn-danger'
                            : 'btn-success'
                          : ''
                      }`}
                      id={`pills-${index}-tab`}
                      data-toggle="pill"
                      onClick={() => {
                        setQuotationId(quotation.serialNumber);
                        setActiveTab(index);
                      }}
                      href={`#pills-${index}`}
                      role="tab"
                      aria-controls={`pills-${index}`}
                      aria-selected="true"
                    >
                      Quotation {index + 1}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="pills-tabContent">
                {poDetails.map((quotation, index) => {
                  totalAmount = 0;
                  let pnfCharge = 0;

                  const vendorId = findVendorId(quotation.vendor);

                  return (
                    <div
                      className={`tab-pane fade show ${
                        index === 0 ? 'active' : ''
                      }`}
                      id={`pills-${index}`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      role="tabpanel"
                      aria-labelledby={`pills-${index}-tab`}
                    >
                      <div className="card shadow mb-2">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              className="table table-borderless table-striped text-center small text-black table-hover"
                              width="100%"
                              cellSpacing="0"
                            >
                              <thead>
                                <tr className="bg-dark text-white op-0-9">
                                  <th width="1%">SR</th>
                                  <th className="text-left">Item</th>
                                  <th>Quantity</th>
                                  {!['WFA', 'REJECTED', 'DELETED'].includes(
                                    purchaseorder.status
                                  ) &&
                                    (!quotation.approved ||
                                      quotation.approved === 'T') && (
                                      <th>Received</th>
                                    )}
                                  <th>Requisition</th>
                                  <th>Rate</th>
                                  <th>Discount</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {quotation.items.map((item, index) => {
                                  if (quotation.approved === 'T') {
                                    const itemId =
                                      purchaseorder.items.findIndex(
                                        (obj) => obj.item === item.item
                                      );
                                    // eslint-disable-next-line no-param-reassign
                                    item = { ...purchaseorder.items[itemId] };
                                  }

                                  totalAmount +=
                                    item.rate * item.quantity -
                                    ((item.rate * item.quantity) / 100) *
                                      item.discount;

                                  let pnfCharges;

                                  if (quotation.pnfCharges) {
                                    pnfCharges =
                                      quotation.pnfCharges.split(' ');

                                    if (pnfCharges[1] === '%') {
                                      pnfCharge =
                                        (totalAmount * Number(pnfCharges[0])) /
                                        100;
                                    } else if (pnfCharges[1] === '₹') {
                                      pnfCharge = Number(pnfCharges[0]);
                                    }
                                  }

                                  return (
                                    <tr
                                      className={
                                        item.forceClosed === 'T'
                                          ? 'bg-warning no-hover op-0-8'
                                          : ''
                                      }
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={index}
                                    >
                                      <td>{index + 1}</td>
                                      <td className="text-left" width="40%">
                                        {item.item}{' '}
                                        {item.description && (
                                          <span className="font-italic text-secondary font-weight-bold">{`[${item.description}]`}</span>
                                        )}
                                        {item.forceClosed === 'T' && (
                                          <p className="text-danger text-xs mb-0">
                                            Force Closed{' '}
                                            <b>
                                              {item.quantity - item.received}
                                            </b>{' '}
                                            {item.unit}
                                          </p>
                                        )}
                                        {role === 'purchaseorder' &&
                                          item.quantity > item.received &&
                                          item.forceClosed !== 'T' &&
                                          (quotation.approved === 'T' ||
                                            (quotation.status &&
                                              ![
                                                'WFA',
                                                'REJECTED',
                                                'DELETED'
                                              ].includes(quotation.status))) &&
                                          ![
                                            'PO_DELETED',
                                            'PO_REJECTED'
                                          ].includes(purchaseorder.status) && (
                                            <button
                                              style={{
                                                transform: 'scale(0.7)'
                                              }}
                                              onClick={() => {
                                                const confirm = window.confirm(
                                                  `Are you sure you want to force close ${item.item} of ${purchaseorder.poNumber}`
                                                );

                                                if (confirm) {
                                                  forceClosePOItem(
                                                    purchaseorder.poNumber,
                                                    { items: [item.item] }
                                                  );
                                                  window.location.reload();
                                                }
                                              }}
                                              className="btn btn-danger btn-sm font-weight-bold m-0 float-right"
                                            >
                                              Force Close
                                            </button>
                                          )}
                                      </td>
                                      <td className="font-weight-bold">
                                        {`${item.quantity} ${item.unit}`}
                                      </td>

                                      {!['WFA', 'REJECTED', 'DELETED'].includes(
                                        purchaseorder.status
                                      ) &&
                                        (!quotation.approved ||
                                          quotation.approved === 'T') && (
                                          <td>
                                            {`${item.received} ${item.unit}`}
                                          </td>
                                        )}

                                      <td>{item.requisitionNumber}</td>

                                      <td className="font-weight-bold">
                                        ₹{' '}
                                        {(item.rate &&
                                          item.rate.toLocaleString()) ||
                                          0}
                                      </td>
                                      <td className="font-weight-bold">
                                        {item.discount} %
                                      </td>
                                      <td className="font-weight-bold">
                                        ₹{' '}
                                        {(
                                          item.rate * item.quantity -
                                          ((item.rate * item.quantity) / 100) *
                                            item.discount
                                        )
                                          .toFixed(2)
                                          .toLocaleString() || 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className="float-left">
                            {purchaseorder.status === 'WFA' &&
                              role === 'purchaseorder' && (
                                <button
                                  className="btn btn-sm btn-danger shadow-sm font-weight-bold"
                                  onClick={() =>
                                    deletePO(purchaseorder.poNumber)
                                  }
                                  type="button"
                                >
                                  <i className="fas fa-trash"> </i> Delete PO
                                </button>
                              )}

                            {purchaseorder.status === 'WFA' &&
                              role === 'admin' && (
                                <SendSMSNotification
                                  sendRemark={sendRemark}
                                  from={`${firstName} ${lastName}`}
                                  response={() => {
                                    getNotifications(purchaseorder.poNumber);
                                    purchaseorder &&
                                      !purchaseorder.hasNotifications &&
                                      getGroupedPo(1, 'WFA');
                                  }}
                                  sendDetails={{
                                    referenceNumber: purchaseorder.poNumber,
                                    messageTo: purchaseorder.createdBy,
                                    type: 'PO'
                                  }}
                                  setSendRemark={(response) =>
                                    setSendRemark({ ...response })
                                  }
                                />
                              )}

                            <ul className="list-group list-group-flush w-">
                              {notifications.map((notification, index) => (
                                <li
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  className="list-group-item text-black d-flex justify-content-between align-items-center pt-1 pb-1"
                                >
                                  {notification.messageBody}
                                  <span className="badge badge-light badge-pill ml-3">
                                    {notification.createdBy} <br />
                                    {moment(notification.createdAt).format(
                                      'DD/MM/YY hh:mm A'
                                    )}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div className="clearfix" />

                          <div className="row">
                            <div className="col-sm-5">
                              <ul className="list-group mt-2 mb-2">
                                {requisitionDetails.map(
                                  (pr) =>
                                    pr.plant && (
                                      <li
                                        className="list-group-item font-weight-bold"
                                        key={pr.serialNumber}
                                      >
                                        {pr.serialNumber} | Plant:
                                        {pr.plant} | Machine:
                                        {pr.machine}{' '}
                                        {pr.remark && `| ${pr.remark}`}
                                      </li>
                                    )
                                )}
                              </ul>
                            </div>
                            <div className="col-sm-2">
                              {(quotation.approved === 'T' ||
                                (!quotation.approved &&
                                  !['WFA', 'REJECTED'].includes(
                                    purchaseorder.status
                                  ))) && (
                                <span className="row float-right">
                                  <img
                                    src="../../../../img/icons/approved.png"
                                    alt="approved"
                                    draggable="false"
                                    style={{
                                      width: '4.5vw'
                                    }}
                                  />
                                </span>
                              )}

                              {purchaseorder.status === 'REJECTED' && (
                                <span className="row float-right">
                                  <img
                                    src="../../../../img/icons/rejected.png"
                                    alt="approved"
                                    draggable="false"
                                    style={{
                                      width: '4.5vw'
                                    }}
                                  />
                                </span>
                              )}
                            </div>

                            <div className="col-sm-5">
                              <dl className="row floating-row mr-4">
                                <dt className="col-sm-6">Sub-Total: </dt>
                                <dd className="col-sm-6 font-weight-bold text-black">
                                  ₹ {totalAmount.toFixed(2).toLocaleString()}
                                </dd>

                                {quotation.pnfCharges && (
                                  <>
                                    <dt className="col-sm-6">P&F Charges: </dt>
                                    <dd className="col-sm-6 font-weight-bold text-black">
                                      {quotation.pnfCharges}
                                    </dd>
                                  </>
                                )}

                                <dt className="col-sm-6">
                                  {quotation.gstType}:{' '}
                                </dt>
                                <dd className="col-sm-6 font-weight-bold text-black">
                                  ₹{' '}
                                  {(
                                    ((totalAmount + pnfCharge) / 100) *
                                    quotation.gstPercent
                                  )
                                    .toFixed(2)
                                    .toLocaleString()}
                                </dd>

                                <dt className="col-sm-6">Total Amount: </dt>
                                <dd className="col-sm-6 font-weight-bold text-black">
                                  ₹{' '}
                                  {(
                                    totalAmount +
                                    pnfCharge +
                                    ((totalAmount + pnfCharge) / 100) *
                                      quotation.gstPercent
                                  )
                                    .toFixed(2)
                                    .toLocaleString()}
                                </dd>

                                <dt className="col-sm-6">Total (Rd Off): </dt>
                                <dd className="col-sm-6 font-weight-bold text-black">
                                  ₹{' '}
                                  {Math.round(
                                    totalAmount +
                                      pnfCharge +
                                      ((totalAmount + pnfCharge) / 100) *
                                        quotation.gstPercent
                                  ).toLocaleString()}
                                  <i
                                    className="fas fa-print pl-2 text-black cursor-on-hover iccoon"
                                    onClick={() =>
                                      printElement(
                                        document.getElementById('printThis')
                                      )
                                    }
                                  />
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 mt-2 mb-2">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="card bg-light">
                              <div className="card-body shadow">
                                <h6>
                                  GST Details:
                                  <span className="font-weight-bold float-md-right">
                                    {quotation.gstType} [{quotation.gstPercent}%
                                    ]
                                  </span>
                                </h6>

                                <hr className="mt-2 mb-3" />
                                <h6>
                                  Payment Mode:
                                  <span className="font-weight-bold float-md-right">
                                    {quotation.paymentMode.toUpperCase()}
                                  </span>
                                </h6>

                                {quotation.paymentTerms && (
                                  <>
                                    <hr className="mt-2 mb-3" />
                                    <h6>
                                      Payment Terms:
                                      <span className="font-weight-bold float-md-right">
                                        {quotation.paymentTerms}
                                      </span>
                                    </h6>
                                  </>
                                )}

                                <hr className="mt-2 mb-3" />
                                <h6>
                                  Delivery Terms:
                                  <span className="font-weight-bold float-md-right">
                                    {quotation.deliveryTerms}
                                  </span>
                                </h6>

                                <hr className="mt-2 mb-3" />
                                <h6>
                                  Dispatched Through:
                                  <span className="font-weight-bold float-md-right">
                                    {quotation.dispatchTerms
                                      .toUpperCase()
                                      .replace('_', ' ')}
                                  </span>
                                </h6>

                                {purchaseorder.approvedAt && (
                                  <>
                                    <hr className="mt-2 mb-3" />
                                    <h6>
                                      {purchaseorder.status === 'REJECTED'
                                        ? 'Rejected'
                                        : 'Approved'}
                                      :
                                      {approvedBy && (
                                        <>
                                          <span className="font-weight-bold float-md-right">
                                            {`${approvedBy.firstName} ${approvedBy.lastName} (${approvedBy.role})`}
                                          </span>
                                          <br />
                                        </>
                                      )}
                                      {purchaseorder.status === 'REJECTED' && (
                                        <>
                                          <span className="font-weight-bold float-md-right">
                                            {purchaseorder.approvedBy}
                                          </span>
                                          <br />
                                        </>
                                      )}
                                      <span className="font-weight-bold float-md-right text-xs text-info">
                                        {moment(
                                          purchaseorder.approvedAt
                                        ).format('DD MMM YYYY - hh:mm A')}
                                      </span>
                                    </h6>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="card bg-light small">
                              <div className="card-body shadow">
                                <h6>
                                  Vendor:
                                  <span className="font-weight-bold float-md-right">
                                    {vendor[vendorId] &&
                                      vendor[vendorId].particulars}
                                  </span>
                                </h6>

                                <hr className="mt-2 mb-3" />

                                <h6 className="mb-4">
                                  Created By:
                                  <span className="font-weight-bold float-md-right">
                                    {purchaseorder.createdBy}
                                  </span>
                                  <br />
                                  <span className="font-weight-bold float-md-right text-xs text-info">
                                    {moment(purchaseorder.createdAt).format(
                                      'DD MMM YYYY - hh:mm A'
                                    )}
                                  </span>
                                </h6>

                                {quotation.dueOn && (
                                  <>
                                    <hr className="mt-2 mb-3" />
                                    <h6>
                                      Due On:
                                      <span className="font-weight-bold float-md-right">
                                        {moment(quotation.dueOn)
                                          .format('DD MMM YYYY')
                                          .toUpperCase()}
                                      </span>
                                    </h6>
                                  </>
                                )}

                                {quotation.supplierReferences && (
                                  <>
                                    <hr className="mt-2 mb-3" />
                                    <h6>
                                      Supplier References:
                                      <span className="font-weight-bold float-md-right">
                                        {quotation.supplierReferences}
                                      </span>
                                    </h6>
                                  </>
                                )}

                                <hr className="mt-2 mb-3" />
                                <h6>
                                  Status:
                                  <span className="font-weight-bold float-md-right">
                                    {purchaseorder.status === 'WFA'
                                      ? 'PO Generated'
                                      : status === 4
                                      ? 'GR Generated'
                                      : purchaseorder.status}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {user.role === 'admin' && purchaseorder.status === 'WFA' && (
                        <div className="card shadow-sm mb-2">
                          <div className="card-body font-weight-bold text-black row">
                            <div className="col-sm-6">
                              Spendings in {moment().format('MMMM')}: ₹{' '}
                              {spendings && spendings.toLocaleString()}
                            </div>
                            <div className="col-sm-6 mobile-float">
                              Spendings after this order ₹{' '}
                              {(
                                spendings +
                                totalAmount +
                                (totalAmount / 100) * quotation.gstPercent
                              ).toLocaleString()}
                            </div>
                            <div className="clearfix" />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="card shadow mb-4">
                <div className="card-body">
                  <ol className="requisition-progress mb-2 mt-2">
                    <li
                      className={status >= 1 ? 'is-active' : 'is-rejected'}
                      data-step="1"
                    >
                      PO Generated
                    </li>
                    <li
                      className={status >= 2 ? 'is-active' : null}
                      data-step="2"
                    >
                      PO Approved
                    </li>
                    <li
                      className={
                        status >= 4
                          ? 'is-active'
                          : status === 3
                          ? 'is-pending'
                          : null
                      }
                      data-step="3"
                    >
                      Material at Security
                    </li>
                    <li
                      className={status >= 4 ? 'is-active' : 'progress__last'}
                      data-step="4"
                    >
                      GR Generated
                    </li>
                  </ol>
                </div>
              </div>

              {/* APPROVE PURCHASE ORDER */}
              {role === 'admin' && purchaseorder.status === 'WFA' && (
                <ApprovePurchaseOrder
                  purchaseOrderId={purchaseorder.serialNumber}
                  poNumber={purchaseorder.poNumber}
                  disabled={quotationId === ''}
                  multipleVendorBody={purchaseorder}
                  multiple={
                    Object.keys(purchaseorder.multiple).length > 0
                      ? quotationId
                      : false
                  }
                />
              )}

              {role === 'admin' && purchaseorder.status === 'APPROVED' && (
                <button
                  className="btn btn-sm btn-danger shadow-sm btn-block mb-1"
                  onClick={() => {
                    window.$('#confirmUnApprove').modal('show');
                  }}
                >
                  Un-Approve PO
                </button>
              )}

              <ConfirmationModal
                bodyText={`Are you sure, you want to Un-Approve Purchase Order ${purchaseorder.poNumber}. This action cannot be reversed once you click Confirm button`}
                modalId="confirmUnApprove"
                title={`Un-Approve ${purchaseorder.poNumber}`}
                buttonTitle="Un-Approve"
                buttonStyle="danger"
                dismissOnClick
                response={async () => {
                  window.$('#showPurchaseOrderDetails').modal('hide');

                  await UNapprovePurchaseOrder(
                    purchaseorder.serialNumber,
                    purchaseorder.poNumber
                  );

                  window.alert(
                    `Purchase Order ${purchaseorder.poNumber} has been Unapproved Successfully.`
                  );
                }}
              />

              {['purchaseorder', 'admin'].includes(role) &&
                !['DELETED', 'WFA'].includes(purchaseorder.status) &&
                approvedQuotation && (
                  <div className="btn-group btn-block">
                    <button
                      className="btn btn-info btn-sm shadow-sm"
                      disabled={!(Object.keys(vendor).length > 0)}
                      onClick={() => {
                        const poIndex = findPoId(approvedQuotation);
                        const vendorIndex = findVendorId(approvedQuotation);

                        import('./DownloadPDF').then(
                          ({ default: DownloadPDF }) =>
                            DownloadPDF({
                              purchaseorder: poDetails[poIndex],
                              vendor: vendor[vendorIndex],
                              client
                            })
                        );
                      }}
                    >
                      Download Order PDF
                    </button>
                    <button
                      className="btn btn-info btn-sm shadow-sm"
                      disabled={!(Object.keys(vendor).length > 0)}
                      onClick={() => {
                        const poIndex = findPoId(approvedQuotation);

                        const vendorIndex = findVendorId(approvedQuotation);

                        import('./DownloadPDF').then(
                          ({ default: DownloadPDF }) =>
                            DownloadPDF({
                              purchaseorder: poDetails[poIndex],
                              vendor: vendor[vendorIndex],
                              view: true,
                              client
                            })
                        );
                      }}
                    >
                      View Order PDF
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  client: state.user.client,
  spendings: state.purchaseorders.monthlySpendings,
  wfa: state.purchaseorders.purchaseorders.wfa.data,
  approved: state.purchaseorders.purchaseorders.approved.data,
  register: state.purchaseorders.purchaseorders.register.data
});

export default connect(mapStateToProps, {
  UNapprovePurchaseOrder,
  getPOMonthlySpendings,
  forceClosePOItem,
  getGroupedPo
})(OrderItemModal);
