import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

function SuccessModal({ title, redirectTo }) {
  const history = useHistory();

  useEffect(
    () => () => {
      window.$('#successModal').modal('hide');
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div
      className="modal fade text-center"
      data-backdrop="static"
      data-keyboard="false"
      id="successModal"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center mt-2">
              <img
                className="img-fluid"
                src="/img/checkmark.gif"
                alt="success"
              />
            </div>

            <h4 className="text text-center text-success text-lg mt-4 mb-2">
              {title}
            </h4>

            <button
              data-dismiss="modal"
              className="btn btn-success btn-circle mt-3"
              onClick={() => {
                redirectTo && history.push(redirectTo);
                !redirectTo && window.$('#successModal').modal('hide');
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

SuccessModal.propTypes = {
  title: PropTypes.string,
  redirectTo: PropTypes.string
};

SuccessModal.defaultProps = {
  title: 'Success',
  redirectTo: '/'
};

export default SuccessModal;
