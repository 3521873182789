import axios from 'axios';
import setError from '../components/utils/asyncHandler';
import { ADMIN_GET_ALL_USERS, SET_LOADING } from './types';

export const getAllUsers = (page) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`/api/users?page=${page || 1}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({ type: ADMIN_GET_ALL_USERS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getAllUsersByRole = (role) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/role/${role || 'admin'}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const updateUserDetails = (id, body) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`/api/users/${id}`, body, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    const res = await axios.get('/api/users', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({ type: ADMIN_GET_ALL_USERS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.delete(`/api/users/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    const res = await axios.get('/api/users', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({ type: ADMIN_GET_ALL_USERS, payload: res.data });

    return true;
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));

    return false;
  }
};

export const registerNewUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post('/api/users', body, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    const res = await axios.get('/api/users', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({ type: ADMIN_GET_ALL_USERS, payload: res.data });

    return true;
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));

    return false;
  }
};
