import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import subDays from 'date-fns/subDays';
import DatePicker from 'react-datepicker';
import { Redirect } from 'react-router-dom';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import SuccessModal from '../../utils/SuccessModal';
import SelectVendorModal from './SelectVendorModal';
import AddNewVendorModal from './AddNewVendorModal';
import ConfirmationModal from '../../utils/ConfirmationModal';
import { createPuchaseOrder } from '../../../actions/poActions';

function AddPODetailsModal({ cart, config, user, createPuchaseOrder }) {
  const [tab, addTab] = useState([
    {
      items: [...cart],
      title: '1',
      pnfCharges: { show: false, pnfCharges: null, pnfType: '' }
    }
  ]);
  const [selected, setSelected] = useState('');
  const [createdPO, setCreatePO] = useState(true);
  const [pnfCharges, setpnfCharges] = useState([{ tabindex: 0, show: false }]);

  useEffect(() => {
    // Check if all required inputs are filled
    const allQuotation = [];

    tab.forEach((quotation) => {
      if (
        !quotation.vendor ||
        !quotation.gstType ||
        !quotation.gstPercent ||
        !quotation.paymentMode ||
        !quotation.dispatchTerms ||
        (config.multiplePODestinations && !tab[0].destination)
      ) {
        allQuotation.push(true);
      } else {
        allQuotation.push(false);
      }
    });

    setCreatePO(!!allQuotation.includes(true));
    // eslint-disable-next-line
  }, [tab]);

  if (Object.keys(cart).length === 0) {
    return <Redirect to="/purchaseorder/purchaseorder/new" />;
  }

  const setQuotationValue = (index, subIndex, field, value) => {
    if (field === 'discount') {
      if (value > 100 || value < 0) {
        return true;
      }
    }

    const tempTab = [...tab];

    const tempQuot = [...tempTab[index].items];

    const tempItem = { ...tempQuot[subIndex] };

    tempItem[`${field}`] = value;

    tempQuot[subIndex] = tempItem;

    tempTab[index].items = tempQuot;

    return addTab([...tempTab]);
  };

  function validateItemsRate() {
    for (let i = 0; i < tab.length; i += 1) {
      const quotation = tab[i].items;

      for (let ii = 0; ii < quotation.length; ii += 1) {
        const item = quotation[ii];

        if (
          !item.rate ||
          item.rate === '' ||
          typeof item.rate === 'undefined'
        ) {
          return {
            success: false,
            message: `Please add rate in Quotation ${i + 1} - ${item.item}`
          };
        }
      }
    }

    return { success: true };
  }

  let amount = 0;

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <Topbar title="Create Purchase Order" />
          <div className="container-fluid" id="content">
            <SelectVendorModal
              selected={selected}
              setVendor={(selected, serialNumber, name) => {
                const index = tab.findIndex((obj) => obj.title === selected);

                const temp = [...tab];

                temp[index].vendor = serialNumber;
                temp[index].vendorName = name;

                addTab([...temp]);
              }}
            />

            <AddNewVendorModal
              selected={selected}
              setVendor={(selected, serialNumber, name) => {
                const index = tab.findIndex((obj) => obj.title === selected);

                const temp = [...tab];

                temp[index].vendor = serialNumber;
                temp[index].vendorName = name;

                addTab([...temp]);
              }}
            />

            <div
              className="card shadow"
              style={{ borderWidth: '2px', borderColor: '#444' }}
            >
              <div className="card-body">
                <div className="container-fluid">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    {tab.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="nav-item" key={index}>
                        <a
                          className={
                            index === 0
                              ? 'nav-link small active mr-1 shadow-sm'
                              : 'nav-link small mr-1 shadow-sm'
                          }
                          id={`pills-${item.title}-tab`}
                          data-toggle="pill"
                          href={`#pills-${item.title}`}
                          role="tab"
                          aria-controls={`pills-${item.title}`}
                          aria-selected={index === 0 ? 'true' : 'false'}
                        >
                          Quotation {item.title}
                        </a>
                      </li>
                    ))}
                    <li
                      className="nav-item btn"
                      onClick={() => {
                        addTab([
                          ...tab,
                          {
                            items: [...tab[0].items],
                            title: `${Number(tab[tab.length - 1].title) + 1}`,
                            pnfCharges: {
                              show: false,
                              pnfCharges: null,
                              pnfType: ''
                            }
                          }
                        ]);

                        setpnfCharges([
                          ...pnfCharges,
                          {
                            tabindex: Number(tab[tab.length - 1].title) + 1,
                            show: false
                          }
                        ]);
                      }}
                    >
                      <i className="fas fa-plus text-primary text-lg" />
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {tab.map((item, tabindex) => {
                      amount = 0;

                      let pnfCharge = 0;

                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={tabindex}
                          className={
                            tabindex === 0
                              ? 'tab-pane fade show active'
                              : 'tab-pane fade'
                          }
                          id={`pills-${item.title}`}
                          role="tabpanel"
                          aria-labelledby={`pills-${item.title}-tab`}
                        >
                          {/* CART TABLE GOES HERE */}
                          <form>
                            <div className="row mt-4 mb-4">
                              <div className="col-sm-11 pl-5 mb-4">
                                <div
                                  className="input-group shadow-sm"
                                  data-toggle="modal"
                                  data-target="#selectVendor"
                                >
                                  <input
                                    type="text"
                                    className="form-control bg-light text-black border-0 small"
                                    value={item.vendorName || ''}
                                    onClick={() => setSelected(item.title)}
                                    placeholder="Select vendor..."
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      <i className="fas fa-user fa-sm" />
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="col-1 pr-5">
                                <button
                                  className="btn btn-primary btn-block shadow-sm"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#addNewVendor"
                                  onClick={() => setSelected(item.title)}
                                >
                                  <i className="fas fa-user-plus fa-sm" />
                                </button>
                              </div>

                              <div className="col-sm-4 pl-5 pr-5">
                                <label className="text-dark mb-0">
                                  Payment Mode
                                </label>
                                <select
                                  className="form-control text-black shadow-sm"
                                  name="mode_of_payment"
                                  value={item.paymentMode || ''}
                                  onChange={(e) => {
                                    const tempTab = [...tab];
                                    tempTab[tabindex].paymentMode =
                                      e.target.value;

                                    addTab([...tempTab]);
                                  }}
                                >
                                  <option value="">Select...</option>
                                  <option value="cash">Cash</option>
                                  <option value="cheque">Cheque</option>
                                  <option value="rtgs">RTGS</option>
                                  <option value="neft">NEFT</option>
                                  <option value="imps">IMPS</option>
                                </select>
                              </div>

                              <div className="col-sm-4 pl-5 pr-5">
                                <label className="text-dark mb-0">
                                  Payment Terms
                                </label>
                                <input
                                  type="text"
                                  className="form-control text-black shadow-sm"
                                  placeholder="terms of payment"
                                  value={item.paymentTerms || ''}
                                  onChange={(e) => {
                                    const tempTab = [...tab];
                                    tempTab[tabindex].paymentTerms =
                                      e.target.value;

                                    addTab([...tempTab]);
                                  }}
                                />
                              </div>

                              <div className="col-sm-4 pl-5 pr-5">
                                <label className="text-dark mb-0">Due On</label>

                                <div className="customDatePickerWidth">
                                  <DatePicker
                                    placeholderText="DD/MM/YYYY"
                                    className="form-control text-black shadow-sm"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={subDays(Date.now(), 0)}
                                    selected={item.dueOn}
                                    onChange={(date) => {
                                      const tempTab = [...tab];
                                      tempTab[tabindex].dueOn = date;

                                      addTab([...tempTab]);
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-4 pl-5 pr-5 mt-3">
                                <label className="text-dark mb-0">
                                  Dispatch Through
                                </label>

                                <select
                                  className="form-control text-black shadow-sm"
                                  name="dispatched_through"
                                  value={item.dispatchTerms || ''}
                                  onChange={(e) => {
                                    const tempTab = [...tab];
                                    tempTab[tabindex].dispatchTerms =
                                      e.target.value;

                                    addTab([...tempTab]);
                                  }}
                                >
                                  <option value="">Select...</option>
                                  <option value="courier">Courier</option>
                                  <option value="transport">Transport</option>
                                  <option value="self_pickup">
                                    Self Pickup
                                  </option>
                                </select>
                              </div>

                              <div className="col-sm-4 pl-5 pr-5 mt-3">
                                <label className="text-dark mb-0">
                                  Delivery Terms
                                </label>

                                <input
                                  placeholder="in days..."
                                  className="form-control text-black shadow-sm"
                                  name="terms_of_delivery"
                                  value={item.deliveryTerms || ''}
                                  onChange={(e) => {
                                    const tempTab = [...tab];
                                    tempTab[tabindex].deliveryTerms =
                                      e.target.value;

                                    addTab([...tempTab]);
                                  }}
                                />
                              </div>

                              <div className="col-sm-4 pl-5 pr-5 mt-3">
                                <label className="text-dark mb-0">
                                  Supplier References
                                </label>

                                <input
                                  placeholder="Optional"
                                  className="form-control text-black shadow-sm"
                                  name="terms_of_delivery"
                                  value={item.supplierReferences || ''}
                                  onChange={(e) => {
                                    const tempTab = [...tab];
                                    tempTab[tabindex].supplierReferences =
                                      e.target.value;

                                    addTab([...tempTab]);
                                  }}
                                />
                              </div>
                            </div>
                          </form>

                          <div className="row">
                            {config.multiplePODestinations && (
                              <div className="col-sm-4 pl-5 pr-5">
                                <label className="text-dark mb-0">
                                  Destination Location
                                </label>

                                <select
                                  className="form-control text-black shadow-sm"
                                  required
                                  value={tab[0].destination || ''}
                                  onChange={(e) => {
                                    const tempTab = [...tab];
                                    tempTab[0].destination = e.target.value;

                                    addTab([...tempTab]);
                                  }}
                                >
                                  <option value="">Select destination</option>
                                  {config.destinations.map((dest) => (
                                    <option
                                      value={dest.configName}
                                      key={dest.configName}
                                    >
                                      {dest.configName}
                                    </option>
                                  ))}
                                </select>

                                {config.multiplePODestinations &&
                                  config.destinations.length === 0 && (
                                    <p className="text mt-1 text-danger">
                                      <i className="fas fa-info-circle text-sm-left" />{' '}
                                      Ask your admin to add destinations
                                    </p>
                                  )}
                              </div>
                            )}

                            <div
                              className={`col-sm-${
                                config.multiplePODestinations ? '4' : '6'
                              } pl-5 pr-5`}
                            >
                              <label className="text-dark mb-0">GST Type</label>

                              <select
                                className="form-control text-black shadow-sm"
                                value={item.gstType || ''}
                                onChange={(e) => {
                                  const tempTab = [...tab];
                                  tempTab[tabindex].gstType = e.target.value;

                                  addTab([...tempTab]);
                                }}
                              >
                                <option value="">Select type</option>
                                <option value="CGST/SGST">CGST/SGST</option>
                                <option value="IGST">IGST</option>
                              </select>
                            </div>

                            <div
                              className={`col-sm-${
                                config.multiplePODestinations ? '4' : '6'
                              } pl-5 pr-5`}
                            >
                              <label className="text-dark mb-0">
                                GST Percent
                              </label>

                              <select
                                className="form-control text-black shadow-sm"
                                value={item.gstPercent || ''}
                                onChange={(e) => {
                                  const tempTab = [...tab];
                                  tempTab[tabindex].gstPercent = e.target.value;

                                  addTab([...tempTab]);
                                }}
                              >
                                <option value="">Select percent</option>
                                <option value="0">0</option>
                                <option value="5">5</option>
                                <option value="12">12</option>
                                <option value="18">18</option>
                                <option value="28">28</option>
                              </select>
                            </div>
                          </div>

                          <table className="table table-borderless text-black table-striped text-center shadow-sm mt-5">
                            <thead>
                              <tr>
                                <th width="3%">SN</th>
                                <th className="text-left" width="28%">
                                  Item
                                </th>
                                <th width="12%">Description</th>
                                <th width="10%">Quantity</th>
                                <th width="12%">Rate (₹)</th>
                                <th width="10%">Amount (₹)</th>
                                <th width="10%">Discount (%)</th>
                                <th width="10%">Discount Amount</th>
                                <th width="5%">-</th>
                              </tr>
                            </thead>

                            <tbody>
                              {item.items.map((quot, index) => {
                                amount +=
                                  quot.rate * quot.quantity -
                                  ((quot.rate * quot.quantity) / 100) *
                                    (quot.discount || 0);

                                if (
                                  index === item.items.length - 1 &&
                                  item.pnfCharges
                                ) {
                                  if (item.pnfCharges.pnfType === '%') {
                                    pnfCharge =
                                      (amount *
                                        Number(item.pnfCharges.pnfCharges)) /
                                      100;
                                  } else if (item.pnfCharges.pnfType === '₹') {
                                    pnfCharge = Number(
                                      item.pnfCharges.pnfCharges
                                    );
                                  }
                                }

                                return (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <tr key={index} className="text-xs">
                                    <td>{index + 1}</td>
                                    <td className="text-left font-weight-bold">
                                      {quot.item}
                                    </td>
                                    <td>
                                      <input
                                        className="form-control form-control-sm text-black shadow-sm"
                                        placeholder="description"
                                        type="text"
                                        value={quot.description || ''}
                                        onChange={(e) =>
                                          setQuotationValue(
                                            tabindex,
                                            index,
                                            'description',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>{`${quot.quantity} ${quot.unit}`}</td>
                                    <td>
                                      <input
                                        className="form-control form-control-sm text-center text-black shadow-sm"
                                        placeholder="Rate ₹"
                                        type="number"
                                        value={quot.rate || ''}
                                        onChange={(e) =>
                                          setQuotationValue(
                                            tabindex,
                                            index,
                                            'rate',
                                            Number(e.target.value)
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      ₹{' '}
                                      {(
                                        quot.rate * quot.quantity
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      <input
                                        className="form-control form-control-sm text-center text-black shadow-sm"
                                        placeholder="0 %"
                                        type="number"
                                        value={quot.discount || ''}
                                        onChange={(e) =>
                                          setQuotationValue(
                                            tabindex,
                                            index,
                                            'discount',
                                            Number(e.target.value)
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      ₹{' '}
                                      {(
                                        quot.rate * quot.quantity -
                                        ((quot.rate * quot.quantity) / 100) *
                                          (quot.discount || 0)
                                      )
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </td>
                                    <td>
                                      <i
                                        className="fas fa-trash text-danger hov-pointer iccoon"
                                        onClick={() => {
                                          const temp = [...tab];

                                          temp.map((tabQuot) => {
                                            let tempQuot = tabQuot.items;
                                            const itemIndex =
                                              tempQuot.findIndex(
                                                (obj) => obj.item === quot.item
                                              );

                                            if (itemIndex !== -1) {
                                              tempQuot = tempQuot.splice(
                                                itemIndex,
                                                1
                                              );
                                            }

                                            return true;
                                          });

                                          if (temp[0].items.length === 0) {
                                            window.location.reload();
                                          } else addTab([...temp]);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <div
                            className="text-black font-weight-bold text-right m-4"
                            style={{ fontFamily: 'sans-serif' }}
                          >
                            <div
                              className="text-secondary"
                              style={{ fontSize: '14px' }}
                            >
                              <input
                                type="checkbox"
                                defaultChecked={false}
                                onChange={() => {
                                  const temp = [...pnfCharges];
                                  temp[tabindex].show = !temp[tabindex].show;

                                  setpnfCharges([...temp]);
                                }}
                              />
                              <label className="ml-2 font-weight-normal mb-3">
                                Includes P&F Charges
                              </label>

                              <br />

                              {pnfCharges[tabindex].show && (
                                <>
                                  <label className="font-weight-normal mr-3">
                                    Payment Type
                                  </label>
                                  <select
                                    onChange={(e) => {
                                      const tempTab = [...tab];

                                      tempTab[tabindex].pnfCharges.pnfType =
                                        e.target.value;

                                      addTab([...tempTab]);
                                    }}
                                  >
                                    <option value="">Select charge type</option>
                                    <option value="%">Percentage (%)</option>
                                    <option value="₹">Rupees (₹)</option>
                                  </select>

                                  <br />
                                  <label className="font-weight-normal mr-1">
                                    Charges
                                  </label>
                                  <input
                                    placeholder="Enter charges"
                                    type="number"
                                    style={{ width: '15%' }}
                                    onChange={(e) => {
                                      // eslint-disable-next-line no-restricted-globals
                                      if (isNaN(+e.target.value)) {
                                        return;
                                      }

                                      const tempTab = [...tab];

                                      tempTab[tabindex].pnfCharges.pnfCharges =
                                        e.target.value;

                                      addTab([...tempTab]);
                                    }}
                                    className="form-control form-control-sm form-inline float-right text-center"
                                  />
                                </>
                              )}
                            </div>

                            <hr className="mt-4" />

                            <h5>
                              Sub Total{' '}
                              <span className="font-weight-bolder">
                                ₹ {amount.toFixed(2).toLocaleString()}
                              </span>
                            </h5>

                            {pnfCharges[tabindex].show && (
                              <h5>
                                P&F Charges:{' '}
                                <span className="font-weight-bold">
                                  {item.pnfCharges.pnfCharges || 0}{' '}
                                  {item.pnfCharges.pnfType}
                                </span>
                              </h5>
                            )}

                            {item.gstType && (
                              <h5>
                                {item.gstType} {item.gstPercent || 0}%
                                <span className="ml-3 font-weight-bolder">
                                  ₹{' '}
                                  {(
                                    ((amount + Number(pnfCharge)) / 100) *
                                    (item.gstPercent || 0)
                                  )
                                    .toFixed(2)
                                    .toLocaleString()}
                                </span>
                              </h5>
                            )}

                            <h5>
                              Total{' '}
                              <span className="font-weight-bolder">
                                ₹{' '}
                                {(
                                  amount +
                                  Number(pnfCharge) +
                                  ((amount + Number(pnfCharge)) / 100) *
                                    (item.gstPercent || 0)
                                )
                                  .toFixed(2)
                                  .toLocaleString()}
                              </span>
                            </h5>
                          </div>

                          {tabindex > 0 && (
                            <button
                              className="btn btn-danger btn-sm shadow-sm mt-2 mb-4 float-right"
                              onClick={() => {
                                const data = tab.filter(
                                  (obj, i) => i !== tabindex
                                );
                                addTab(data);
                              }}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <SuccessModal
                    title="Purchase Order Generated"
                    redirectTo={`/${user.role}/purchaseorder`}
                  />

                  <button
                    className="btn btn-block btn-success shadow-sm"
                    disabled={createdPO}
                    onClick={() => {
                      const res = validateItemsRate();

                      if (res.success) {
                        window.$('#confirmPurchaseOrder').modal('show');
                      } else {
                        window.alert(res.message);
                      }
                    }}
                  >
                    Create Purchase Order
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ConfirmationModal
        bodyText="Are you sure, you want to create this Purchase Order. This action cannot be reversed once you click Confirm button"
        modalId="confirmPurchaseOrder"
        title="Confirm Purchase Order"
        buttonTitle="Create PO"
        buttonStyle="success"
        dismissOnClick
        response={async () => {
          await createPuchaseOrder({
            requiredBy: new Date().toISOString(),
            quotations: [...tab]
          });

          window.$('#successModal').modal('show');
        }}
      />
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.config,
  cart: state.purchaseorders.new
});

export default connect(mapStateToProps, { createPuchaseOrder })(
  AddPODetailsModal
);
