import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  receivePoItems,
  forceClosePOItem,
  getPoToBeReceived
} from '../../actions/grActions';
import AreYouSure from '../common/AreYouSure';

function ReceiveGoodsModal({
  role,
  filter,
  selected,
  receive,
  receivePoItems,
  forceClosePOItem,
  getPoToBeReceived
}) {
  const [billDetails, setBillDetails] = useState({});
  const [purchaseorder, setPurchaseorder] = useState({
    items: []
  });
  const [selectedItem, setSelected] = useState({
    index: -1
  });

  useEffect(() => {
    setSelected({ index: -1 });

    const index = receive.findIndex((obj) => obj.poNumber === selected);

    setPurchaseorder(receive[index]);

    // eslint-disable-next-line
  }, [selected, receive]);

  // If all the items are closed than close the model
  // Because the state wont have the po details
  if (!purchaseorder) {
    window.$('#receiveGoodsModal').modal('hide');
  }

  let pendingCount = 0;

  return (
    <div
      className="modal fade"
      id="receiveGoodsModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h4 className="text text-center text-black">
              #{purchaseorder && purchaseorder.poNumber}
              <span
                className="float-right"
                style={{
                  cursor: 'pointer',
                  marginTop: '-4px'
                }}
                data-dismiss="modal"
              >
                x
              </span>
            </h4>

            <div className="card shadow mb-2">
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-borderless table-striped table-hover"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr className="text-center text-white bg-dark op-0-9">
                        <th className="text-left">Item</th>
                        <th>For</th>
                        <th>Quantity</th>
                        <th>Pending</th>
                        {role === 'purchaseorder' && (
                          <th width="20%">Force Close</th>
                        )}
                        {['security', 'inventory'].includes(role) && (
                          <th width="20%">Received</th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {purchaseorder &&
                        purchaseorder.items.map((item, index) => {
                          if (item.pending > 0) {
                            pendingCount += 1;
                          }

                          return (
                            <tr
                              key={item.serialNumber}
                              className="text-center text-black"
                            >
                              <td className="text-left" width="40%">
                                {item.item}
                              </td>
                              <td className="font-weight-bold">
                                {item.requisition.createdBy}
                              </td>
                              <td className="font-weight-bold">
                                {`${Math.round(item.quantity * 100) / 100} ${
                                  item.unit
                                }`}
                              </td>
                              <td className="font-weight-bold">
                                {`${Math.round(item.pending * 100) / 100} ${
                                  item.unit
                                }`}
                              </td>

                              {role === 'purchaseorder' && (
                                <td>
                                  {selectedItem.index === index && (
                                    <AreYouSure
                                      selected={selectedItem}
                                      clicked={async (res) => {
                                        await forceClosePOItem(res.poNumber, {
                                          items: [res.item]
                                        });
                                        setSelected({ index: -1 });
                                        await getPoToBeReceived(
                                          filter.destination,
                                          filter.date
                                        );
                                      }}
                                      cancel={() => setSelected({ index: -1 })}
                                    />
                                  )}

                                  {selectedItem.index === -1 && (
                                    <button
                                      className="btn btn-danger shadow-sm"
                                      disabled={
                                        !!(
                                          item.pending === 0 ||
                                          item.forceClosed === 'T'
                                        )
                                      }
                                      onClick={() =>
                                        setSelected({
                                          index,
                                          poNumber: purchaseorder.poNumber,
                                          item: item.item
                                        })
                                      }
                                    >
                                      Force Close
                                    </button>
                                  )}
                                </td>
                              )}

                              {['security', 'inventory'].includes(role) && (
                                <td>
                                  <input
                                    className="form-control shadow-sm text-center"
                                    type="number"
                                    disabled={item.pending === 0 && true}
                                    value={
                                      item.receivedQuantity >= 0
                                        ? item.receivedQuantity
                                        : ''
                                    }
                                    onChange={(e) => {
                                      if (
                                        +e.target.value % 1 !== 0 &&
                                        [
                                          'Nos',
                                          'Units',
                                          'Pairs',
                                          'Sets'
                                        ].includes(item.unit)
                                      ) {
                                        return;
                                      }

                                      const temp = [...purchaseorder.items];
                                      temp[index].receivedQuantity = +Number(
                                        e.target.value
                                      ).toFixed(2);

                                      setPurchaseorder({
                                        ...purchaseorder,
                                        items: [...temp]
                                      });
                                    }}
                                  />

                                  {item.receivedQuantity > item.pending && (
                                    <p className="text-danger text-xs mb-0">
                                      Received quantity should not be greater
                                      than pending quantity
                                    </p>
                                  )}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                {pendingCount === 0 && (
                  <h5 className="text-center font-weight-bold text-info">
                    <hr />
                    All the items are received, waiting for GR Generation
                  </h5>
                )}

                {['security', 'inventory'].includes(role) && pendingCount > 0 && (
                  <>
                    <h5 className="font-weight-bold">Order Details</h5>

                    <div className="row mt-4 mb-4">
                      <div className="col-sm-6">
                        <label className="text-sm">Bill Number</label>
                        <input
                          className="form-control shadow-sm"
                          type="text"
                          placeholder="Enter bill number"
                          value={billDetails.billNumber || ''}
                          onChange={(e) =>
                            setBillDetails({
                              ...billDetails,
                              billNumber: e.target.value
                            })
                          }
                        />
                      </div>

                      <div className="col-sm-6">
                        <label className="text-sm">DM Number</label>
                        <input
                          className="form-control shadow-sm"
                          placeholder="Enter DM number"
                          type="text"
                          value={billDetails.dmNumber || ''}
                          onChange={(e) =>
                            setBillDetails({
                              ...billDetails,
                              dmNumber: e.target.value
                            })
                          }
                        />
                      </div>
                    </div>

                    <button
                      className="btn btn-success btn-block shadow-sm"
                      data-dismiss="modal"
                      disabled={
                        !!(!billDetails.billNumber && !billDetails.dmNumber)
                      }
                      onClick={async () => {
                        await receivePoItems(
                          purchaseorder.poNumber,
                          purchaseorder.items,
                          billDetails
                        );

                        setBillDetails({});

                        await getPoToBeReceived(
                          filter.destination,
                          filter.date
                        );
                      }}
                    >
                      RECEIVE GOODS
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  receive: state.goodsreceived.receive
});

export default connect(mapStateToProps, {
  receivePoItems,
  forceClosePOItem,
  getPoToBeReceived
})(ReceiveGoodsModal);
