import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import { deletePurchaseOrder, getGroupedPo } from '../../../actions/poActions';
import OrderItemsModal from './OrderItemsModal';
import SuccessModal from '../../utils/SuccessModal';
import ConfirmationModal from '../../utils/ConfirmationModal';

function ViewPurchaseOrders({
  wfa,
  role,
  loading,
  approved,
  createPo,
  getGroupedPo,
  deletePurchaseOrder
}) {
  useEffect(() => {
    getGroupedPo(1, 'WFA');
    getGroupedPo(1, 'WFA_REJECTED_DELETED', true);
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = useState('');
  const [deleteId, setDeleteId] = useState('');

  const onPOClickDo = (row) => {
    setSelected(row.poNumber);
    window.$('#showPurchaseOrderDetails').modal('show');

    // Every time the modal is triggered, quotation tabs should
    // display first quotation
    window.$('#pills-0').tab('show');
    window.$('#pills-0-tab').tab('show');
  };

  const createPoButton =
    createPo === true
      ? {
          title: 'New Purchase Order',
          to: `/${role}/purchaseorder/new`,
          icon: 'plus'
        }
      : null;

  const columns = [
    {
      selector: 'poNumber',
      cell: (row) => (
        <div onClick={() => onPOClickDo(row)}>
          <h6 className="font-weight-bold pt-3 mb-0 text-black">
            {row.poNumber}
          </h6>
          <div>
            <div
              className="pb-2"
              data-tag="allowRowEvents"
              style={{
                color: 'grey',
                overflow: 'hidden',
                whiteSpace: 'wrap',
                textOverflow: 'ellipses'
              }}
            >
              {row.createdBy}
            </div>
          </div>
        </div>
      )
    },
    {
      selector: 'createdAt',
      right: true,
      cell: (row) => moment(row.createdAt).format('DD MMMM YYYY')
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar
              title="Purchase Order"
              button={createPoButton}
              loading={loading}
            />

            <div className="container-fluid">
              <div className="text-right mb-2">
                {role === 'purchaseorder' && (
                  <p
                    className="text-black text-left"
                    style={{ marginBottom: '-25px' }}
                  >
                    Note: Close off items using{' '}
                    <Link
                      className="text-decoration-none font-weight-bold"
                      to={`/${role}/security`}
                    >
                      Force Close PO
                    </Link>{' '}
                    option
                  </p>
                )}

                <Link
                  to={`/${role}/purchaseorder/track`}
                  className="btn btn-primary btn-sm shadow-sm mr-2"
                >
                  <i className="fas fa-sticky-note" /> Track PO Items
                </Link>

                <Link
                  to={`/${role}/purchaseorder/register`}
                  className="btn btn-info btn-sm shadow-sm"
                >
                  <i className="fas fa-sticky-note" /> PO Register
                </Link>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <DataTable
                    title="Waiting For Approval"
                    noTableHead
                    columns={columns}
                    highlightOnHover
                    pointerOnHover
                    data={wfa.data}
                    onRowClicked={(row) => onPOClickDo(row)}
                    pagination
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationTotalRows={wfa.count}
                    paginationServer
                    paginationServerOptions={{
                      persistSelectedOnPageChange: true
                    }}
                    onChangePage={async (pageNumber) =>
                      getGroupedPo(pageNumber, 'WFA')
                    }
                    paginationPerPage={15}
                  />
                </div>

                <div className="col-sm-6">
                  <DataTable
                    title="Approved"
                    noTableHead
                    columns={columns}
                    highlightOnHover
                    pointerOnHover
                    data={approved.data}
                    onRowClicked={(row) => onPOClickDo(row)}
                    pagination
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationTotalRows={approved.count}
                    paginationServer
                    paginationServerOptions={{
                      persistSelectedOnPageChange: true
                    }}
                    onChangePage={async (pageNumber) =>
                      getGroupedPo(pageNumber, 'WFA_REJECTED_DELETED', true)
                    }
                    paginationPerPage={15}
                  />
                </div>
              </div>

              <OrderItemsModal
                selected={selected}
                deletePO={(poNumber) => {
                  setDeleteId(poNumber);
                  window.$('#showPurchaseOrderDetails').modal('hide');
                  window.$('#confirmDeletePO').modal('show');
                }}
              />

              <SuccessModal
                title={`Purchase Order ${deleteId} Deleted`}
                redirectTo={`/${role}/purchaseorder`}
              />

              <ConfirmationModal
                bodyText="Are you sure you want to delete this purchase order. This step cannot be reversed."
                modalId="confirmDeletePO"
                buttonTitle={`Delete PO ${deleteId}`}
                title="Delete PO"
                buttonStyle="danger"
                dismissOnClick
                response={() => {
                  deletePurchaseOrder(deleteId);
                  setDeleteId('');
                  window.$('#successModal').modal('show');
                }}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  wfa: state.purchaseorders.purchaseorders.wfa,
  approved: state.purchaseorders.purchaseorders.approved,
  loading: state.purchaseorders.loading,
  error: state.purchaseorders.error
});

export default connect(mapStateToProps, { getGroupedPo, deletePurchaseOrder })(
  ViewPurchaseOrders
);
