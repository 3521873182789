import React, { useState, useEffect } from 'react';
import './style.css';
import moment from 'moment';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { getPlants } from '../../actions/inventoryActions';
import { createReport } from '../../actions/reportActions';

function NewReportModal({ createReport, getPlants, plants }) {
  const [details, setDetails] = useState({});
  const [startDate, setStartDate] = useState(
    moment().startOf('month').format('YYYY/MM')
  );
  const [endDate, setEndDate] = useState(
    moment().startOf('month').format('YYYY/MM')
  );

  useEffect(() => {
    plants.length === 0 && getPlants();

    return () => {
      window.$('#createNewReport').modal('hide');
    };
    // eslint-disable-next-line
  }, [])

  const today = new Date();

  return (
    <div
      className="modal fade"
      id="createNewReport"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="spanner">
          <img
            style={{ marginTop: '25%' }}
            src="/img/generate.gif"
            alt="Generatign report"
            draggable={false}
          />
          <h5 className="text-white font-weight-bold mt-4">
            Generating report please wait..
          </h5>
        </div>

        <div className="modal-content">
          <h4 className="text text-center text-black-50 mt-3 mr-4">
            <span className="text-black float-left ml-3">New Report</span>
            <span
              className="float-right"
              style={{
                cursor: 'pointer',
                marginTop: '-4px'
              }}
              data-dismiss="modal"
            >
              x
            </span>
          </h4>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-7">
                <form
                  onSubmit={async e => {
                    e.preventDefault();

                    window.$('div.spanner').addClass('show');
                    window.$('div.overlay').addClass('show');

                    await createReport({ ...details, startDate, endDate });

                    window.$('div.spanner').removeClass('show');
                    window.$('div.overlay').removeClass('hide');

                    setDetails({});

                    window.$('#createNewReport').modal('hide');
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="reportName">Report Name</label>
                    <input
                      className="form-control shadow-sm"
                      value={`${details.type ? details.type : 'General'}_${details.plant
                        ? details.plant.replace(' ', '_')
                        : 'Report'
                      }_D${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}_T${today.getHours()}_${today.getMinutes()}`}
                      id="reportName"
                      readOnly
                      placeholder="Monthly report..."
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="reportType">Report Type</label>
                    <select
                      className="form-control shadow-sm"
                      onChange={e => setDetails({ ...details, type: e.target.value })}
                      value={details.type || ''}
                      id="reportType"
                      required
                    >
                      <option value="" defaultValue>
                        Select report type..
                      </option>
                      <option value="Consumption">Consumption Report</option>
                      <option value="Purchase">Purchase Report</option>
                    </select>
                  </div>

                  {details.type === 'Consumption' && (
                    <div className="form-group">
                      <label htmlFor="reportType">Select Plant</label>
                      <select
                        className="form-control shadow-sm"
                        onChange={e => setDetails({ ...details, plant: e.target.value })}
                        value={details.plant || ''}
                        id="reportType"
                        required
                      >
                        <option value="" defaultValue>
                          Select plant..
                        </option>
                        {plants.map(plant => (
                          <option key={plant.plant} value={plant.plant}>
                            {plant.plant}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="reportDuration">Duration</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text shadow-sm">
                          <i className="fas fa-calendar" />
                        </div>
                      </div>
                      <select
                        className="form-control shadow-sm"
                        id="reportDuration"
                        onChange={e => {
                          setDetails({ ...details, duration: e.target.value });

                          switch (e.target.value) {
                            case 'current_month':
                              setStartDate(
                                moment().startOf('month').format('YYYY/MM')
                              );
                              setEndDate(
                                moment()
                                  .add(1, 'month')
                                  .startOf('month')
                                  .format('YYYY/MM')
                              );
                              break;

                            case 'previos_month':
                              setStartDate(
                                moment()
                                  .subtract(1, 'month')
                                  .startOf('month')
                                  .format('YYYY/MM')
                              );
                              setEndDate(
                                moment().startOf('month').format('YYYY/MM')
                              );
                              break;

                            case '3_months':
                              setStartDate(
                                moment()
                                  .subtract(3, 'month')
                                  .startOf('month')
                                  .format('YYYY/MM')
                              );
                              setEndDate(moment().format('YYYY/MM'));
                              break;
                            default:
                              break;
                          }
                        }}
                        value={details.duration || ''}
                        required
                      >
                        <option value="" defaultValue>
                          Select duration..
                        </option>
                        <option value="current_month">Current Month</option>
                        <option value="previos_month">Previous Month</option>
                        <option value="3_months">Last 3 Months</option>
                        <option value="custom">Custom Period</option>
                      </select>
                    </div>
                  </div>
                  {details.duration === 'custom' && (
                    <>
                      <div className="form-group">
                        <label htmlFor="reportDuration">Report From</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text shadow-sm">
                              <i className="fas fa-calendar" />
                            </div>
                          </div>

                          <div
                            className="customDatePickerWidth"
                            style={{ width: '90%' }}
                          >
                            <DatePicker
                              className="form-control shadow-sm w-100"
                              placeholderText="Select Start Date"
                              selected={moment(startDate, 'YYYY/MM')._d}
                              onChange={date => setStartDate(
                                `${date.getFullYear()}/${date.getMonth() + 1}`
                              )}
                              selectsStart
                              maxDate={Date.now()}
                              startDate={moment(startDate, 'YYYY/MM')._d}
                              endDate={moment(endDate, 'YYYY/MM')._d}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="reportDuration">Report To</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text shadow-sm">
                              <i className="fas fa-calendar" />
                            </div>
                          </div>

                          <div
                            className="customDatePickerWidth"
                            style={{ width: '90%' }}
                          >
                            <DatePicker
                              className="form-control shadow-sm w-100"
                              placeholderText="Select End Date"
                              selected={moment(endDate, 'YYYY/MM')._d}
                              onChange={date => setEndDate(
                                `${date.getFullYear()}/${date.getMonth() + 1}`
                              )}
                              selectsEnd
                              maxDate={Date.now()}
                              startDate={moment(startDate, 'YYYY/MM')._d}
                              endDate={moment(endDate, 'YYYY/MM')._d}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="text-center mt-4 mb-3">
                    <button
                      type="submit"
                      disabled={
                        !!(details.duration === 'custom' && !startDate && !endDate)
                      }
                      className="btn btn-md btn-success shadow font-weight-bold text-uppercase"
                    >
                      Generate Report
                    </button>
                  </div>
                </form>
              </div>

              <div className="com-sm-5">
                <img
                  className="img-fluid"
                  draggable={false}
                  alt="Report"
                  src="/img/report.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  plants: state.inventory.plants
});

export default connect(mapStateToProps, { createReport, getPlants })(
  NewReportModal
);
