import { SET_CLIENT_CONFIGS } from '../actions/types';

const initialState = {
  multiplePODestinations: false,
  destinations: []
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_CONFIGS:
      return {
        ...state,
        destinations: [...action.payload.destinations],
        multiplePODestinations: action.payload.multiplePODestinations,
        loading: false
      };

    default:
      return state;
  }
};

export default configReducer;
