import { SET_STATUS, SET_ERROR } from '../../actions/types';

const setError = (err, cb) => {
  if (!err.response || err.request.status === 404) {
    return cb({
      type: SET_STATUS,
      data: { error: true, message: 'Oops! It seems something went wrong.' }
    });
  }
  return cb({ type: SET_ERROR, data: err.response.data.error });
};

export default setError;
