import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import { getGrToBeApproved } from '../../../actions/grActions';
import GRItemsForApproval from '../../utils/GRItemsForApproval';

function WaitingToBeReceived({ getGrToBeApproved, approve, loading }) {
  useEffect(() => {
    getGrToBeApproved();
    // eslint-disable-next-line
  }, [])

  const [filter, setFilter] = useState([]);

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Goods Received" loading={loading} />

            <div className="container-fluid mb-4">
              <GRItemsForApproval
                fullScreen
                pending={approve}
                filter={filter}
                setFilter={res => setFilter(res)}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = state => ({
  error: state.goodsreceived.error,
  loading: state.goodsreceived.loading,
  approve: state.goodsreceived.approve
});

export default connect(mapStateToProps, {
  getGrToBeApproved
})(WaitingToBeReceived);
