const styles = {
  headRow: {
    style: {
      border: 'none',
      textAlign: 'center'
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  },
  rows: {
    style: {
      fontFamily: 'sans-serif'
    },
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF'
    }
  },
  pagination: {
    style: {
      border: 'none'
    }
  }
};

export default styles;
