import React, { useState } from 'react';
import { connect } from 'react-redux';
import SomethingWentWrong from '../utils/SomethingWentWrong';
import './register.css';
import {
  registerClient,
  sendVerificationOTP,
  verifyVerificationOTP
} from '../../actions/authActions';

function Register({
  error,
  logs,
  registerClient,
  sendVerificationOTP,
  verifyVerificationOTP
}) {
  const [info, setInfo] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);

  const onSubmitDo = async e => {
    e.preventDefault();

    setLoading(true);

    const response = await registerClient(info);

    if (response && response.success) {
      setInfo({ ...info, subDomain: response.subDomain });
      setStep(4);
    }

    setLoading(false);
  };

  function onChange(e) {
    setInfo({
      ...info,
      [e.target.name]: e.target.value
    });
  }

  const nextPage = (e, page) => {
    e.preventDefault();
    setStep(page);
  };

  let current;

  switch (step) {
    case 1:
      current = (
        <main
          id="part1"
          style={{ marginTop: otpVerification ? '50px' : '80px' }}
        >
          <form onSubmit={e => nextPage(e, 2)}>
            <div className="custom-input-group">
              <label htmlFor="company">Company</label>
              <input
                onChange={onChange}
                required
                value={info.company || ''}
                name="company"
                type="text"
                id="company"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                required
                value={info.address || ''}
                name="address"
                onChange={onChange}
                id="address"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="phone">Contact Number</label>
              <input
                type="text"
                required
                pattern="[0-9]*"
                maxLength="10"
                minLength="10"
                value={info.phone || ''}
                disabled={!!otpVerification}
                name="phone"
                onChange={onChange}
                id="phone"
              />
              <button
                className="side-button"
                disabled={
                  !!(otpVerification || !/^[6-9]{1}?[0-9]{9}$/.test(info.phone))
                }
                type="button"
                onClick={async () => {
                  const res = await sendVerificationOTP(info.phone);
                  res === true && setOtpVerification(true);
                }}
              >
                Send OTP
              </button>
              <p className="text-follows">
                OTP SMS will be sent to this number
              </p>
            </div>

            <div
              className="custom-input-group"
              style={{ display: otpVerification ? '' : 'none' }}
            >
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                required
                maxLength="6"
                minLength="6"
                placeholder="OTP"
                value={info.otp || ''}
                name="otp"
                onChange={onChange}
                id="otp"
                disabled={otpVerified}
              />
              <button
                className="side-button"
                type="button"
                disabled={otpVerified}
                onClick={async () => {
                  const res = await verifyVerificationOTP(info.phone, info.otp);
                  res === true && setOtpVerified(true);
                }}
              >
                {otpVerified ? 'OTP Verified' : 'Verify OTP'}
              </button>
              {/* <p className="text-follows resend">
                  Didn't receive the sms.{' '}
                  <button
                    type="button"
                    disabled={timer !== 0}
                    onClick={async () => {
                      const res = await sendVerificationOTP(info.phone)
                      res && setTimer(120)
                    }}
                  >
                    Resend
                  </button>
                  <span> in {timer} seconds</span>
                </p> */}
            </div>

            <div className="custom-input-group subdomain">
              <label htmlFor="subDomain">
                Subdomain
                {' '}
                <span className="small-text-faded">( optional )</span>
              </label>
              <input
                name="subDomain"
                maxLength="15"
                minLength="4"
                pattern="^[a-zA-Z0-9][a-zA-Z0-9-]*$"
                value={info.subDomain || ''}
                onChange={onChange}
                type="text"
                id="subDomain"
              />
              <button type="button" style={{ cursor: 'initial' }}>
                .procflo.com
              </button>
              <p className="text-follows">
                If left empty, a system generated domain will be assigned
              </p>
            </div>

            <div className="next-button">
              <button disabled={loading} type="submit">
                Next Step
              </button>
            </div>
          </form>
        </main>
      );
      break;
    case 2:
      current = (
        <main id="part2">
          <form onSubmit={e => nextPage(e, 3)}>
            <div className="section-heading">
              <p>Admin User Details</p>
            </div>

            <div className="custom-input-group">
              <label htmlFor="firstName">First Name</label>
              <input
                autoFocus
                type="text"
                required
                value={info.firstName || ''}
                onChange={onChange}
                id="firstName"
                name="firstName"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                required
                value={info.lastName || ''}
                onChange={onChange}
                id="lastName"
                name="lastName"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                required
                value={info.email || ''}
                onChange={onChange}
                name="email"
                id="email"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                required
                minLength="6"
                onChange={onChange}
                value={info.password || ''}
                id="password"
              />
            </div>

            <div className="next-button">
              <div className="back-button">
                <button type="button" onClick={() => setStep(1)}>
                  Back
                </button>
              </div>

              <button disabled={loading} type="submit">
                Next Step
              </button>
            </div>
          </form>
        </main>
      );
      break;

    case 3:
      current = (
        <main id="part3">
          <form onSubmit={e => onSubmitDo(e)}>
            <div className="section-heading">
              <p>
                Add Users
                {' '}
                <span className="small-text-faded"> ( optional ) </span>
              </p>
            </div>
            <div className="custom-input-group">
              <label htmlFor="user1">User 1</label>
              <input
                type="email"
                name="user1"
                placeholder="user@procflo.com"
                value={info.user1 || ''}
                onChange={onChange}
                id="user1"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="user2">User 2</label>
              <input
                type="email"
                name="user2"
                placeholder="user@procflo.com"
                value={info.user2 || ''}
                onChange={onChange}
                id="user2"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="user3">User 3</label>
              <input
                type="email"
                name="user3"
                placeholder="user@procflo.com"
                value={info.user3 || ''}
                onChange={onChange}
                id="user3"
              />
            </div>

            <div className="custom-input-group">
              <label htmlFor="user4">User 4</label>
              <input
                type="email"
                name="user4"
                placeholder="user@procflo.com"
                value={info.user4 || ''}
                onChange={onChange}
                id="user4"
              />
            </div>

            <div className="next-button">
              <div className="back-button">
                <button type="button" onClick={() => setStep(2)}>
                  Back
                </button>
              </div>

              <button disabled={loading} type="submit">
                Finish
              </button>
            </div>
          </form>
        </main>
      );
      break;

    case 4:
      current = (
        <>
          <div className="header">
            <div className="main-heading text-center">
              <h1>Alright! You are now a member</h1>
              <p>You can now start using your application</p>
            </div>
          </div>
          <main id="part4">
            <div className="section-heading">
              <h2 className="text-center">Check your email inbox !</h2>
            </div>

            <div className="center-image">
              <img className="img" src="./img/mail_sent.gif" alt="Email Sent" />
            </div>

            <div className="next-button">
              <button
                onClick={() => window.open(info.subDomain, '_blank')}
                type="button"
              >
                Go to your dashboard
              </button>
            </div>
          </main>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <div className="my-container">
      {step !== 4 && (
        <div className="header">
          <div className="main-heading">
            <h1>Register on Procflo</h1>
            <p>This will help us to know you better</p>
          </div>
          <div className="page-number" id="pageNumber">
            <p>
              {step}
              {' '}
              / 3
            </p>
          </div>
        </div>
      )}

      {current}

      {error && (
      <p className="text-center text-danger">
        {' '}
        {error}
      </p>
      )}

      {logs.error && <SomethingWentWrong message={logs.message} />}
    </div>
  );
}

const mapStateToProps = state => ({
  error: state.user.error,
  logs: state.logs
});

export default connect(mapStateToProps, {
  registerClient,
  sendVerificationOTP,
  verifyVerificationOTP
})(Register);
