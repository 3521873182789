import React from 'react';
import { connect } from 'react-redux';
import SomethingWentWrong from '../utils/SomethingWentWrong';

function Footer({ logs }) {
  return (
    <div className="mt-3">
      {logs && logs.error && <SomethingWentWrong message={logs.message} />}
    </div>
    // <footer className="sticky-footer bg-white shadow-sm mt-3">
    //   {logs && logs.error && <SomethingWentWrong message={logs.message} />}

  //   <div className="container my-auto">
  //     <div className="copyright text-center my-auto">
  //       <span className="text-gray font">
  //         Copyright &copy; {new Date().getFullYear()} Procflo | All Rights
  //         Reserved
  //       </span>
  //     </div>
  //   </div>
  // </footer>
  );
}

const mapStateToProps = state => ({
  logs: state.logs
});

export default connect(mapStateToProps, {})(Footer);
