import React, { useState } from 'react';
import { connect } from 'react-redux';
import cities from '../../utils/cities';
import Spinner from '../../common/Spinner';
import { registerNewUser } from '../../../actions/userActions';

function AddNewUser({ error, client, loading, registerNewUser }) {
  const [details, setDetails] = useState({});

  function onChange(e) {
    setDetails({ ...details, [e.target.name]: e.target.value });
  }

  let cityList;
  if (details.state) {
    const stateId = cities.findIndex((obj) => obj.state === details.state);

    cityList = cities[stateId].cities.map((city) => (
      <option key={city} value={city}>
        {city}
      </option>
    ));
  }

  return (
    <div
      className="modal fade"
      id="addNewUser"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add New User {loading && <Spinner />}
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card shadow-sm mb-4">
              <div className="card-body">
                <form
                  className="mb-2 text-black"
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const res = await registerNewUser(details);

                    if (res === true) setDetails({});
                  }}
                >
                  <div className="form-row">
                    <div className="form-group col-sm-6">
                      <label>Company</label>
                      <input
                        className="form-control"
                        type="text"
                        value={client.name || ''}
                        readOnly
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Username</label>
                      <input
                        className="form-control"
                        type="text"
                        name="username"
                        required
                        value={details.username || ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-sm-6">
                      <label>First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        name="firstName"
                        placeholder="John"
                        value={details.firstName || ''}
                        onChange={onChange}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        name="lastName"
                        placeholder="Doe"
                        value={details.lastName || ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-sm-6">
                      <label>Mobile</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        required
                        placeholder="Enter your mobile number"
                        value={details.phone || ''}
                        onChange={onChange}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Role</label>

                      <select
                        className="form-control mb-2"
                        value={details.role || ''}
                        name="role"
                        required
                        onChange={onChange}
                      >
                        <option value={null} defaultValue>
                          Select Role...
                        </option>
                        <option value="inventory">Inventory</option>
                        <option value="production">Production</option>
                        <option value="purchaseorder">Purchase Order</option>
                        <option value="security">Security</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-sm-6">
                      <label>Email</label>
                      <input
                        type="email"
                        required
                        name="email"
                        className="form-control"
                        placeholder="yourname@procflo.com"
                        value={details.email || ''}
                        onChange={onChange}
                      />
                    </div>

                    <div className="form-group col-sm-6">
                      <label>Password</label>
                      <input
                        required
                        type="password"
                        name="password"
                        minLength="6"
                        onChange={onChange}
                        className="form-control"
                        value={details.password || ''}
                        placeholder="Enter a strong password"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-sm-4">
                      <label>State</label>
                      <select
                        className="form-control"
                        name="state"
                        required
                        onChange={onChange}
                        value={details.state || ''}
                      >
                        <option value="">Select state</option>
                        {cities.map((state) => (
                          <option key={state.state} value={state.state}>
                            {state.state}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-sm-4">
                      <label>City</label>

                      <select
                        className="form-control"
                        name="city"
                        required
                        onChange={onChange}
                        value={details.city || ''}
                      >
                        <option value="">Select city</option>
                        {cityList}
                      </select>
                    </div>

                    <div className="form-group col-sm-4">
                      <label>Pincode</label>
                      <input
                        type="text"
                        name="pincode"
                        className="form-control"
                        value={details.pincode || ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-info btn-block shadow-sm font-weight-bold"
                    type="submit"
                  >
                    {loading && <Spinner />} Add User
                  </button>
                </form>

                {/* Validation Error */}
                {error && (
                  <p className="text-danger text-center bold mt-3 mb-3">
                    {error}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.user.error,
  client: state.user.client,
  loading: state.admin.loading
});

export default connect(mapStateToProps, {
  registerNewUser
})(AddNewUser);
