import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Topbar from '../common/Topbar';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import LogoutModal from '../common/LogoutModal';
import Content from '../common/Dashboard/Content';
import { getDashboard } from '../../actions/configActions';

function Production({ dashboardData, getDashboard }) {
  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line
  }, [])

  const dashboard = [
    {
      title: 'Total Requisitions',
      data: dashboardData.activeRequisitions,
      to: '/production/requisition',
      icon: 'calendar',
      style: 'primary'
    },
    {
      title: 'Items Pending for Purchase',
      subHeading: 'Requisition approved, PO not created',
      data: dashboardData.pendingForPurchase,
      to: '/production/requisition/items',
      icon: 'shopping-cart',
      style: 'warning'
    },
    {
      title: 'Items to be Received',
      subHeading: 'PO approved, Items not received',
      data: dashboardData.itemsToBeReceived,
      to: '/production/purchaseorder/track?filter=approved',
      icon: 'truck',
      style: 'secondary'
    },
    {
      title: 'Pending GR',
      data: dashboardData.receivedAtSecurity,
      to: '/production/goodsreceived',
      icon: 'clipboard',
      style: 'success'
    },
    {
      title: 'Items Below Minimum Stock',
      data: dashboardData.belowMinimumStock || '-',
      to: '/production/inventory?filter=below_minimum',
      icon: 'exclamation-circle',
      style: 'danger'
    }
  ];
  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Dashboard" />

            <div className="container-fluid">
              <div
                className="alert alert-primary alert-dismissible fade show mt-2 shadow-sm"
                id="error-alert"
                role="alert"
              >
                Mark items as not required by clicking on my items from the
                requisition option. Check This frequently to avoid
                duplicate/overlap items.
                <Link to="/production/requisition/items">
                  {' '}
                  Click here to check items
                </Link>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <Content dashboard={dashboard} />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = state => ({
  dashboardData: state.user.dashboard
});

export default connect(mapStateToProps, { getDashboard })(Production);
