import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import subDays from 'date-fns/subDays';
import DatePciker from 'react-datepicker';
import Topbar from '../../common/Topbar';
import Footer from '../../common/Footer';
import Sidebar from '../../common/Sidebar';
import NewToolModal from './NewToolModal';
import SelectToolsModal from './SelectToolsModal';
import LogoutModal from '../../common/LogoutModal';
import SuccessModal from '../../utils/SuccessModal';
import 'react-datepicker/dist/react-datepicker.css';
import ConfirmationModal from '../../utils/ConfirmationModal';
import {
  addNewRequisition,
  getRequisitionByID,
  editRequisitionDetails
} from '../../../actions/requisitionActions';
import { getPlants, getMachines } from '../../../actions/inventoryActions';

function NewRequisition({
  user,
  match,
  plants,
  history,
  machines,
  getPlants,
  getMachines,
  editRequisition,
  addNewRequisition,
  getRequisitionByID,
  editRequisitionDetails
}) {
  const [cart, addToCart] = useState([]);
  const [details, setDetails] = useState({
    requiredBy: Date.now(),
    plant: '',
    machine: '',
    remark: ''
  });
  const [advanced, setAdvanced] = useState(false);
  const [updatedValues, setUpdatedValues] = useState([]);
  const [futureRequisition, setFutureRequisition] = useState('');
  const [requisitionUpdates, setRequisitionUpdates] = useState({});

  const submitRequisition = () => {
    if (editRequisition) {
      editRequisitionDetails(
        match.params.id,
        requisitionUpdates,
        updatedValues
      );
    } else {
      addNewRequisition({
        requiredBy: details.requiredBy,
        plant: details.plant,
        machine: details.machine,
        remark: details.remark,
        items: cart
      });
    }
    addToCart([]);
  };

  // GET REQUISITION NUMBER BEFORE CREATING IT
  const getFutureRequisitionNumber = async () => {
    const response = await axios.get('/api/requisition/number/future', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    setFutureRequisition(response.data.data);
  };

  useEffect(() => {
    // Fetch plant list if state does not have it already
    advanced && plants.length === 0 && getPlants();
    // eslint-disable-next-line
  }, [advanced]);

  useEffect(() => {
    if (!editRequisition) {
      getFutureRequisitionNumber();
    } else {
      setFutureRequisition(match.params.id || 'NA');

      /**
       * Since we are fetching these requisition details from requistion table
       * We don't knoe about the current stock and rates in inventory
       * So, to get those details we need to batch search in Inventory
       */

      let items = [];

      const p1 = Promise.resolve(getRequisitionByID(match.params.id));

      p1.then((res) => {
        let temp = [];

        res.map((item) => {
          // eslint-disable-next-line
          items = [...items, item.item];
          temp = [...temp, { ...item, quantity: item.requestedQuantity }];

          return true;
        });

        axios
          .post(
            '/api/inventory/search/batch',
            { items },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
              }
            }
          )
          .then((res) => {
            let tempRes = [];
            temp.map((item) => {
              const index = res.data.data.items.findIndex(
                (obj) => obj.item === item.item
              );

              tempRes = [
                ...tempRes,
                {
                  item: item.item,
                  unit: item.unit,
                  rate: res.data.data.items[index].rate,
                  requestedQuantity: item.requestedQuantity,
                  inStock: res.data.data.items[index].inStock,
                  serialNumber: res.data.data.items[index].serialNumber
                }
              ];

              return true;
            });

            addToCart(tempRes);
          })
          .catch((err) => console.log(err));
      }).catch(() => history.push(`/${user.role}/requisition`));
    }
    // eslint-disable-next-line
  }, []);

  let totalAmount = 0;

  /**
   * On updating an item save its updated deatils in state
   * We only send the updated values along with item id to server
   */

  const updateEdit = (item, payload) => {
    const editTemp = [...updatedValues];

    const editIndex = editTemp.findIndex((obj) => obj.payload.item === item);

    if (editIndex !== -1) {
      if (editTemp[editIndex].action === 'delete') {
        editTemp[editIndex].action = 'upsert';
      }

      editTemp[editIndex].payload.requestedQuantity = payload.requestedQuantity;

      setUpdatedValues([...editTemp]);
    } else {
      setUpdatedValues([
        ...updatedValues,
        {
          action: 'upsert',
          payload
        }
      ]);
    }
  };

  const deleteFromEdit = (item) => {
    const tempDelete = [...updatedValues];

    const deleteIndex = tempDelete.findIndex(
      (obj) => obj.payload.item === item
    );

    if (deleteIndex !== -1) {
      tempDelete[deleteIndex].action = 'delete';

      setUpdatedValues([...tempDelete]);
    } else {
      setUpdatedValues([
        ...tempDelete,
        {
          action: 'delete',
          payload: {
            item
          }
        }
      ]);
    }
  };

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar
              title={
                editRequisition ? `Edit ${match.params.id}` : 'New Requisition'
              }
            />

            <div className="container-fluid">
              <p className="text-black text-center lh-1-2 mt-0 mb-0">
                Note: <br />
                1. If item exists in store, use <b>Add Item From Stores</b>{' '}
                option <br />
                2. If item is being ordered for the first time use{' '}
                <b>Request New Item</b> option
              </p>

              <button
                className="d-none d-sm-inline-block btn btn-sm btn-info btn-icon-split shadow-sm"
                data-toggle="modal"
                data-target="#selectTools"
              >
                <span className="icon">
                  <i className="fas fa-plus"> </i>
                </span>
                <span className="text">Add Item From Stores</span>
              </button>

              <button
                className="d-none d-sm-inline-block btn btn-sm btn-info btn-icon-split shadow-sm ml-2"
                data-toggle="modal"
                data-target="#addNewTool"
              >
                <span className="icon">
                  <i className="fas fa-plus"> </i>
                </span>
                <span className="text">Request New Item</span>
              </button>

              <SelectToolsModal
                cart={cart}
                addToCart={(item) => addToCart([...cart, item])}
              />

              <NewToolModal
                addToCart={(response) => {
                  const items = response.concat(cart);
                  addToCart([...items]);
                }}
              />

              <div
                className="card text-black shadow-sm"
                style={{ borderWidth: '2px', borderColor: '#444' }}
              >
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      window.$('#requisitionConfirmation').modal('show');
                    }}
                  >
                    {cart.length > 0 && (
                      <div className="table-responsive table-borderless table-hover">
                        <table className="table text-center text-black">
                          <thead>
                            <tr>
                              <th className="text-left">Item</th>
                              <th>In Stock</th>
                              <th>Min Stock</th>
                              <th>Rate</th>
                              <th>Quantity</th>
                              <th>Amount</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart.map((item, index) => {
                              // Increment total amount on each iteration
                              totalAmount +=
                                item.rate * item.requestedQuantity || 0;

                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr key={index}>
                                  <td className="text-left" width="35%">
                                    {item.item}
                                  </td>
                                  <td width="10%" className="text-center">
                                    {`${item.inStock} ${item.unit}`}
                                  </td>

                                  <td width="10%" className="text-center">
                                    {`${item.minStock || 0} ${item.unit}`}
                                  </td>

                                  <td className="font-weight-bold" width="10%">
                                    ₹ {item.rate || 0}
                                  </td>

                                  <td width="15%">
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        max="999999"
                                        step="0.01"
                                        className="form-control form-control-tool shadow-sm text-center border-0"
                                        required
                                        placeholder="Quantity"
                                        value={
                                          item.requestedQuantity > 0
                                            ? item.requestedQuantity
                                            : ''
                                        }
                                        onChange={(e) => {
                                          const temp = [...cart];

                                          if (
                                            +e.target.value % 1 !== 0 &&
                                            [
                                              'Nos',
                                              'Units',
                                              'Pairs',
                                              'Sets'
                                            ].includes(item.unit)
                                          ) {
                                            return;
                                          }

                                          temp[index].requestedQuantity =
                                            +Number(e.target.value).toFixed(2);

                                          addToCart([...temp]);

                                          editRequisition &&
                                            updateEdit(item.item, temp[index]);
                                        }}
                                      />

                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                        >
                                          {item.unit}
                                        </button>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="font-weight-bold" width="12%">
                                    ₹{' '}
                                    {(
                                      item.requestedQuantity * item.rate || 0
                                    ).toLocaleString()}
                                  </td>

                                  <td
                                    width="5%"
                                    className="text-center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      addToCart(
                                        cart
                                          .slice(0, index)
                                          .concat(cart.slice(index + 1))
                                      );

                                      editRequisition &&
                                        deleteFromEdit(item.item);
                                    }}
                                  >
                                    <i className="fas fa-trash text-danger" />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="col-sm-8 mb-4 float-left">
                      {Object.keys(cart).length === 0 && (
                        <div className="text-center">
                          <img
                            className="mt-5 mb-4"
                            src="/cart.svg"
                            alt="Requisition Cart"
                            draggable="false"
                            style={{ opacity: 0.3, height: 200, width: 200 }}
                          />

                          <h5 className="text-center">CART IS EMPTY</h5>
                        </div>
                      )}
                    </div>

                    {/* Estimation Section Here */}

                    <div className="col-sm-4 mt-4 mb-4 float-right">
                      <h3 className="m-0 mb-2 font-weight-bolder">Details</h3>

                      <div className="card bg-light small">
                        <div className="card-body border-left-secondary">
                          <h6>
                            Total Cart Value
                            <span className="font-weight-bold float-md-right">
                              ₹ {totalAmount.toLocaleString()}
                            </span>
                          </h6>

                          <hr />

                          <h6>
                            Requisition Number:
                            <span className="font-weight-bold float-md-right">
                              {futureRequisition}
                            </span>
                          </h6>

                          <hr />

                          <h6>
                            Created By:
                            <span className="font-weight-bold float-md-right text-right">
                              {user.username.charAt(0).toUpperCase() +
                                user.username.slice(1)}
                            </span>
                          </h6>

                          {!editRequisition && (
                            <>
                              <hr />

                              <h6>
                                Required By:
                                <span className="font-weight-bold float-right">
                                  <DatePciker
                                    className="col-sm-7 float-right form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={subDays(Date.now(), 0)}
                                    selected={details.requiredBy}
                                    onChange={(date) =>
                                      setDetails({
                                        ...details,
                                        requiredBy: date
                                      })
                                    }
                                  />
                                </span>
                              </h6>
                            </>
                          )}

                          <button
                            type="button"
                            className="btn btn-sm btn-secondary shadow-sm mb-0 mt-2"
                            onClick={() => setAdvanced(!advanced)}
                          >
                            Advanced Options
                          </button>

                          {advanced && (
                            <>
                              <h6 className="mt-3">
                                Plant:
                                <span className="font-weight-bold float-right">
                                  <select
                                    className="float-right form-control form-control-sm"
                                    required
                                    name="plant"
                                    value={details.plant}
                                    onChange={(e) => {
                                      e.target.value &&
                                        getMachines(e.target.value);

                                      setDetails({
                                        ...details,
                                        plant: e.target.value
                                      });

                                      if (editRequisition) {
                                        setRequisitionUpdates({
                                          ...requisitionUpdates,
                                          plant: e.target.value
                                        });
                                      }
                                    }}
                                  >
                                    <option defaultValue key="123" value="">
                                      Select Plant
                                    </option>
                                    {plants.map((plant) => (
                                      <option
                                        key={plant.plant}
                                        value={plant.plant}
                                      >
                                        {plant.plant}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </h6>

                              <hr />

                              <h6>
                                Machine:
                                <span className="font-weight-bold float-right">
                                  <select
                                    className="float-right form-control form-control-sm"
                                    required
                                    name="machine"
                                    value={details.machine}
                                    onChange={(e) => {
                                      setDetails({
                                        ...details,
                                        machine: e.target.value
                                      });

                                      if (editRequisition) {
                                        setRequisitionUpdates({
                                          ...requisitionUpdates,
                                          machine: e.target.value
                                        });
                                      }
                                    }}
                                  >
                                    <option defaultValue value="">
                                      Select Machine
                                    </option>
                                    {machines.map((machine) => (
                                      <option
                                        key={machine.machine}
                                        value={machine.machine}
                                      >
                                        {machine.machine}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </h6>

                              <hr />

                              <h6>
                                Remark:
                                <span className="font-weight-bold float-right">
                                  <textarea
                                    onChange={(e) => {
                                      setDetails({
                                        ...details,
                                        remark: e.target.value
                                      });

                                      if (editRequisition) {
                                        setRequisitionUpdates({
                                          ...requisitionUpdates,
                                          remark: e.target.value
                                        });
                                      }
                                    }}
                                    required
                                    value={details.remark || ''}
                                    className="form-control shadow-sm"
                                  />
                                </span>
                              </h6>
                            </>
                          )}
                        </div>
                      </div>

                      <button
                        type="submit"
                        disabled={!(cart[0] && cart[0].requestedQuantity > 0)}
                        className="d-none mt-4 d-lg-inline-block btn btn-success shadow-sm btn-block"
                      >
                        {editRequisition
                          ? 'Update Purchase Request'
                          : 'Generate Purchase Request'}
                      </button>
                    </div>
                  </form>
                </div>

                <SuccessModal
                  title={
                    editRequisition
                      ? 'Requisition Updated'
                      : 'Requisition Generated'
                  }
                  redirectTo={`/${user.role}/requisition`}
                />

                <ConfirmationModal
                  bodyText="Are you sure you want to submit this requisition. This step cannot be reversed."
                  modalId="requisitionConfirmation"
                  buttonTitle={
                    editRequisition
                      ? 'Update Requisition'
                      : 'Create Requisition'
                  }
                  title="Confirm Requisition"
                  buttonStyle="success"
                  dismissOnClick
                  response={() => {
                    submitRequisition();
                    window.$('#successModal').modal('show');
                  }}
                />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  plants: state.inventory.plants,
  machines: state.inventory.machines
});

export default connect(mapStateToProps, {
  getPlants,
  getMachines,
  addNewRequisition,
  getRequisitionByID,
  editRequisitionDetails
})(NewRequisition);
