import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getUserProfile } from '../../actions/authActions';

function PrivateRoute({ component: Component, user, getUserProfile, ...rest }) {
  useEffect(() => {
    if (!user.email) {
      getUserProfile();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.isAuthenticated === true) {
          return <Component {...props} {...rest} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, { getUserProfile })(PrivateRoute);
