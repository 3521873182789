import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { approveRequisition } from '../../../actions/requisitionActions';

function ApproveRequisition({ requisitionId, approveRequisition }) {
  const history = useHistory();

  return (
    <div className="btn-group mt-2 mb-2 m-auto btn-block" data-dismiss="modal">
      <button
        className="btn btn-danger"
        onClick={() => approveRequisition(requisitionId, 'reject')}
      >
        Reject Requisition
      </button>
      <button
        to="#"
        className="btn btn-warning"
        onClick={() => history.push(`/admin/requisition/edit/${requisitionId}`)}
      >
        Edit Requisition
      </button>
      <button
        className="btn btn-success"
        onClick={() => approveRequisition(requisitionId)}
      >
        Approve Requisition
      </button>
    </div>
  );
}

export default connect(null, { approveRequisition })(ApproveRequisition);
