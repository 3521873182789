import {
  SET_PO_WAITING_TOBE_RECEIVED,
  SET_PO_WAITING_APPROVAL,
  SET_ALL_RECEIVED_GOODS,
  SET_GOODS_REGISTER_ITEMS,
  SET_GR_LOADING,
  SET_LOADING,
  SET_ERROR
} from '../actions/types';

const initialState = {
  receive: [],
  received: [],
  approve: [],
  register: [],
  loading: false,
  error: null
};

const grReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GR_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case SET_PO_WAITING_TOBE_RECEIVED:
      return {
        ...state,
        error: null,
        receive: action.payload,
        loading: false
      };

    case SET_PO_WAITING_APPROVAL:
      return {
        ...state,
        error: null,
        approve: action.payload,
        loading: false
      };

    case SET_ALL_RECEIVED_GOODS:
      return {
        ...state,
        error: null,
        loading: false,
        received: action.payload.data,
        count: action.payload.count
      };

    case SET_GOODS_REGISTER_ITEMS:
      return {
        ...state,
        error: null,
        loading: false,
        registerCount: action.payload.count,
        register: action.payload.data
      };

    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default grReducer;
