import { connect } from 'react-redux';
import React, { lazy, Suspense, useEffect } from 'react';
import Topbar from '../common/Topbar';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import LogoutModal from '../common/LogoutModal';
import { getDashboard } from '../../actions/configActions';

const Stats = lazy(() => import('../common/Dashboard/Stats'));
const Content = lazy(() => import('../common/Dashboard/Content'));

function Admin({ getDashboard, dashboardData }) {
  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line
  }, [])

  const dashboard = [
    {
      title: 'Total Requisitions',
      data: dashboardData.activeRequisitions,
      to: '/admin/requisition',
      icon: 'calendar',
      style: 'primary'
    },
    {
      title: 'Requisitions Pending for Approval',
      data: dashboardData.requisitionForApproval,
      to: '/admin/requisition',
      icon: 'clipboard',
      style: 'info'
    },
    {
      title: 'PO Pending for Approval',
      data: dashboardData.poForApproval,
      to: '/admin/purchaseorder',
      icon: 'stopwatch',
      style: 'warning'
    },
    {
      title: 'Items Pending to Purchase',
      subHeading: 'Requisition approved, PO not created',
      data: dashboardData.pendingForPurchase,
      to: '/admin/purchaseorder/pending',
      icon: 'shopping-cart',
      style: 'dark'
    },
    {
      title: 'Items to be Received',
      subHeading: 'PO approved, Items not received',
      data: dashboardData.itemsToBeReceived,
      to: '/admin/purchaseorder/track?filter=approved',
      icon: 'truck',
      style: 'secondary'
    },
    {
      title: 'Pending GR',
      data: dashboardData.receivedAtSecurity,
      to: '/admin/goodsreceived/waiting',
      icon: 'check-square',
      style: 'success'
    },
    {
      title: 'Items Below Minimum Stock',
      data: dashboardData.belowMinimumStock || '-',
      to: '/admin/inventory?filter=below_minimum',
      icon: 'exclamation-circle',
      style: 'danger'
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Dashboard" />

            <div className="container-fluid">
              <Suspense
                fallback={
                  <div className="text-black text-center">Loading...</div>
                }
              >
                <Content dashboard={dashboard} />

                <Stats />
              </Suspense>
              <div className="row" />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {/* <ScrollToTop /> */}
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = state => ({
  dashboardData: state.user.dashboard
});

export default connect(mapStateToProps, { getDashboard })(Admin);
