import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Topbar from '../common/Topbar';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import SearchSort from '../common/SearchSort';
import LogoutModal from '../common/LogoutModal';
import customStyles from '../utils/dataTableCustomStyle';
import { getRequisitions } from '../../actions/requisitionActions';
import RequisitionItemModal from '../Production/Requisitions/RequisitionItemModal';

function RequisitionRegister({
  loading,
  count,
  requisitions,
  getRequisitions
}) {
  const [filter, setFilter] = useState({
    phrase: '',
    searchBy: 'by_order',
    createdBy: '',
    status: '',
    createdAt: '2024'
  });
  const [selected, setSelected] = useState('');

  useEffect(() => {
    getRequisitions(1, filter);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'Requisition Number',
      selector: 'serialNumber',
      sortable: true,
      center: true
    },
    {
      name: 'Created By',
      selector: 'createdBy',
      sortable: true,
      center: true
    },
    {
      name: 'Created On',
      selector: 'createdAt',
      sortable: true,
      center: true,
      format: (row) => moment(row.createdAt).format('DD-MM-YYYY : hh:mm A')
    },
    {
      name: 'Required By',
      selector: 'requiredBy',
      sortable: true,
      center: true,
      format: (row) => moment(row.requiredBy).format('DD-MM-YYYY')
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (row) => (row.status === 'WFA' ? 'PENDING APPROVAL' : row.status)
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Requisition Register" loading={loading} />

            <div className="container-fluid bg-white shadow-sm pt-4">
              <SearchSort
                search={(phrase) => {
                  setFilter({ ...filter, phrase });
                  getRequisitions(1, { ...filter, phrase });
                }}
                allowSearchOptions
                setFilter={(res) => {
                  setFilter({ ...filter, ...res });
                  getRequisitions(1, { ...filter, ...res });
                }}
                clearFilter={() => {
                  setFilter({ createdAt: '2024' });
                  getRequisitions(1);
                }}
                role="production,inventory"
                filter={filter}
                filters={{
                  status: [
                    {
                      title: 'Waiting for Approval',
                      value: 'WFA'
                    },
                    {
                      title: 'Approved',
                      value: 'APPROVED'
                    },
                    {
                      title: 'Rejected',
                      value: 'REJECTED'
                    },
                    {
                      title: 'Deleted',
                      value: 'DELETED'
                    }
                  ]
                }}
              />

              <DataTable
                noHeader
                columns={columns}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                data={requisitions}
                onRowClicked={(row) => {
                  setSelected(row.serialNumber);
                  window.$('#showRequisitionDetails').modal('show');
                }}
                pagination
                paginationTotalRows={count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                paginationComponentOptions={{ noRowsPerPage: true }}
                onChangePage={async (pageNumber) =>
                  getRequisitions(pageNumber, filter)
                }
                paginationPerPage={15}
              />

              <RequisitionItemModal selected={selected} />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  requisitions: state.requisitions.requisitionItems,
  count: state.requisitions.count,
  error: state.requisitions.error,
  loading: state.requisitions.loading
});

export default connect(mapStateToProps, { getRequisitions })(
  RequisitionRegister
);
