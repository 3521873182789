import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {
  getPlants,
  getMachines,
  getAllocationTree,
  createAllocation,
  updateInventoryItem
} from '../../../actions/inventoryActions';
import { printElement } from '../../utils/functions';
import EditableListItem from '../../common/EditableListItem';
import { getAllInventoryCategories } from '../../../actions/configActions';

function ModifyAllocationModal({
  selected,
  filter,
  role,
  viewOnly,
  tools,
  category,
  categories,
  pagination,
  plants,
  machines,
  tree,
  treeItemCount,
  getPlants,
  getMachines,
  getAllocationTree,
  createAllocation,
  updateInventoryItem,
  getAllInventoryCategories,
  loading,
  error
}) {
  useEffect(() => {
    !viewOnly && getPlants();
    selected && getAllocationTree(selected);

    return () => {
      window.$('#modifyAllocation').modal('hide');
    };
    // eslint-disable-next-line
  }, []);

  const [plant, setPlant] = useState('');
  const [remark, setRemark] = useState('');
  const [machine, setMachine] = useState('');
  const [quantity, setQuantity] = useState('');
  const [edit, setEdit] = useState({ type: '' });
  const [updateDetails, setUpdateDetails] = useState({});
  const [reversalQuantity, setReversalQuantity] = useState('');

  const [advancedOptions, setAdvancedOptions] = useState('ADD');

  const index = tools.findIndex((obj) => obj.serialNumber === selected);
  const tool = tools[index];

  if (index === -1) {
    window.$('#modifyAllocation').modal('hide');
    return false;
  }

  const onSubmitDo = (e, reversal) => {
    e.preventDefault();

    createAllocation(
      selected,
      {
        quantity: reversal ? reversalQuantity : quantity,
        plant,
        machine,
        type: reversal ? 'RETURNED' : advancedOptions,
        remark,
        status: reversal ? 'RETURN_REQUESTED' : null
      },
      category,
      pagination.current,
      filter
    );

    setQuantity('');
    setPlant('');
    setMachine('');
    setRemark('');
    setReversalQuantity('');

    getAllocationTree(selected);
  };

  const onUpdateDo = async () => {
    await updateInventoryItem(
      selected,
      updateDetails,
      category,
      pagination.current,
      filter
    );

    await getAllocationTree(selected);
    setEdit({ type: '' });
    setUpdateDetails({});
  };

  const columns = [
    {
      name: 'Plant',
      selector: 'plant',
      sortable: true,
      bold: true,
      width: '12%',
      format: (row) => (row.plant ? row.plant : '-')
    },
    {
      name: 'Allocation Type',
      selector: 'type',
      sortable: true,
      center: true,
      width: '25%',
      // eslint-disable-next-line no-confusing-arrow
      format: (row) =>
        row.type === 'RETURNED'
          ? row.status
            ? `${row.type} (Approved)`
            : `${row.type} (Pending)`
          : row.type
    },
    {
      name: 'Quantity',
      selector: 'quantity',
      sortable: true,
      center: true,
      width: '15%'
    },
    {
      name: 'Machine',
      selector: 'machine',
      sortable: true,
      center: true,
      format: (row) => (row.machine ? row.machine : '-')
    },
    {
      name: 'Date',
      selector: 'createdAt',
      sortable: true,
      center: true,
      format: (row) => moment(row.createdAt).format('DD-MM-YYYY : hh:mm A')
    }
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.type === 'REPLACE',
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        color: 'white'
      }
    },
    {
      when: (row) => row.type === 'ADD',
      style: {
        backgroundColor: 'rgba(54, 185, 204, 0.9)',
        color: 'white'
      }
    },
    {
      when: (row) => row.type === 'RECEIVE',
      style: {
        backgroundColor: 'rgba(248, 148, 6, 0.9)',
        color: 'white'
      }
    },
    {
      when: (row) => row.type === 'RETURNED',
      style: {
        backgroundColor: 'rgba(231, 74, 59, 0.9)',
        color: 'white'
      }
    }
  ];

  window.$('[data-toggle="tooltip"]').tooltip();

  return (
    <div
      className="modal fade"
      id="modifyAllocation"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-black">
              Modify Allocation
              <span className="text-black font-weight-bold ml-3">
                #{tool && tool.item}
              </span>
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {tool && (
            <div className="modal-body" style={{ backgroundColor: '#F8F8F8' }}>
              <div className="row">
                <div className={`col-sm-${role === 'inventory' ? '8' : '12'}`}>
                  <div id="printThis" className="row">
                    <div className="col-sm-12">
                      <div className="row mt-2 mb-4">
                        <div className="col-sm-6">
                          <ul className="list-group shadow-sm">
                            <li className="list-group-item">
                              Item:{' '}
                              <EditableListItem
                                name="item"
                                edit={edit.type === 'item'}
                                value={tool.item}
                                onClickHandler={(res) => setEdit({ type: res })}
                                onSubmitDo={() => onUpdateDo()}
                                onChangeHandler={(res) =>
                                  setUpdateDetails({ item: res.toString() })
                                }
                              />
                            </li>
                            <li className="list-group-item">
                              Rate:{' '}
                              <EditableListItem
                                name="rate"
                                prefix="₹"
                                edit={edit.type === 'rate'}
                                value={tool.rate}
                                onClickHandler={(res) => setEdit({ type: res })}
                                onSubmitDo={() => onUpdateDo()}
                                onChangeHandler={(res) =>
                                  setUpdateDetails({ rate: parseFloat(res) })
                                }
                              />
                            </li>
                            <li className="list-group-item">
                              Minimum Stock:{' '}
                              <EditableListItem
                                name="minStock"
                                edit={edit.type === 'minStock'}
                                value={tool.minStock}
                                unit={tool.unit}
                                onSubmitDo={() => onUpdateDo()}
                                onClickHandler={(res) => setEdit({ type: res })}
                                onChangeHandler={(res) =>
                                  setUpdateDetails({
                                    minStock: parseFloat(res)
                                  })
                                }
                              />
                            </li>
                            <li className="list-group-item">
                              Category:{' '}
                              <span className="text-black">
                                {tool.category.toUpperCase()}
                              </span>
                              {role === 'inventory' && (
                                <i
                                  onClick={() => setEdit({ type: 'category' })}
                                  className="fas fa-edit text-warning float-right iccoon"
                                />
                              )}
                              {edit.type === 'category' && (
                                <div className="row">
                                  <div className="col-sm-10">
                                    <select
                                      className="form-control"
                                      defaultValue=""
                                      onChange={(e) =>
                                        setUpdateDetails({
                                          category: e.target.value
                                        })
                                      }
                                    >
                                      <option>Select category</option>
                                      {categories.map((category) => (
                                        <option
                                          key={category.category}
                                          value={category.category}
                                        >
                                          {category.category.toUpperCase()}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-sm-2">
                                    <i
                                      onClick={async () => {
                                        await onUpdateDo();
                                        await getAllInventoryCategories();
                                      }}
                                      className="btn btn-success btn-sm float-right"
                                    >
                                      Save
                                    </i>
                                  </div>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>

                        <div className="col-sm-6">
                          <ul className="list-group shadow-sm">
                            <li className="list-group-item">
                              In Stock:{' '}
                              <span className="text-black">
                                {tool.inStock} {tool.unit}
                              </span>
                              {/* <EditableListItem
                                name="inStock"
                                edit={edit.type === 'inStock' ? true : false}
                                value={tool.inStock}
                                unit={tool.unit}
                                onSubmitDo={() => onUpdateDo()}
                                onClickHandler={res => setEdit({ type: res })}
                                onChangeHandler={res =>
                                  setUpdateDetails({ inStock: parseFloat(res) })
                                }
                              /> */}
                            </li>
                            <li className="list-group-item">
                              On Floor:{' '}
                              <EditableListItem
                                name="onFloor"
                                edit={edit.type === 'onFloor'}
                                value={tool.onFloor}
                                unit={tool.unit}
                                onSubmitDo={() => onUpdateDo()}
                                onClickHandler={(res) => setEdit({ type: res })}
                                onChangeHandler={(res) =>
                                  setUpdateDetails({ onFloor: parseFloat(res) })
                                }
                              />
                            </li>
                            <li className="list-group-item">
                              Scrap:{' '}
                              <span className="text-black ml-4">
                                {' '}
                                {tool.scrap} {tool.unit}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* Allocation History Tree */}
                    <div className="col-sm-12">
                      <DataTable
                        title="Allocation History"
                        actions={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <>
                            {role === 'inventory' && (
                              <button
                                className="btn btn-danger btn-sm shadow-sm"
                                onClick={() => {
                                  window.$('#modifyAllocation').modal('hide');
                                  window.$('#confirmItemDelete').modal('show');
                                }}
                              >
                                Delete Item
                              </button>
                            )}

                            <i
                              className="fas fa-print pl-2 text-black cursor-on-hover iccoon"
                              onClick={() =>
                                printElement(
                                  document.getElementById('printThis')
                                )
                              }
                            />
                          </>
                        }
                        className="shadow-sm"
                        columns={columns}
                        customStyles={{
                          headCells: {
                            style: {
                              fontSize: '14px',
                              fontWeight: 'bold'
                            }
                          },
                          rows: {
                            style: {
                              fontFamily: 'sans-serif'
                            }
                          }
                        }}
                        data={tree}
                        pagination
                        pointerOnHover
                        conditionalRowStyles={conditionalRowStyles}
                        paginationTotalRows={treeItemCount}
                        paginationServer
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        paginationServerOptions={{
                          persistSelectedOnPageChange: true
                        }}
                        onChangePage={async (pageNumber) =>
                          await getAllocationTree(selected, pageNumber)
                        }
                        paginationPerPage={15}
                      />
                    </div>
                    {/* Allocation History Tree */}
                  </div>
                </div>

                {role === 'inventory' && (
                  <div className="col-sm-4 mt-2 float-right">
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <h4 className="text text-center text-black mb-2">
                          {advancedOptions === 'RECEIVE'
                            ? 'Receive from Floor'
                            : advancedOptions === 'ADD'
                            ? 'Allocate without Scrap'
                            : 'Create Allocation'}
                        </h4>

                        <form className="user mt-3" onSubmit={onSubmitDo}>
                          <div className="form-group">
                            <label className="mb-1 mt-3">Quantity</label>
                            <input
                              type="number"
                              step="0.01"
                              className="form-control shadow-sm"
                              name="quantity"
                              min="0"
                              placeholder="Enter Quantity"
                              required
                              value={quantity}
                              onChange={(e) => {
                                if (
                                  +e.target.value % 1 !== 0 &&
                                  ['Nos', 'Units', 'Pairs', 'Sets'].includes(
                                    tool.unit || ''
                                  )
                                ) {
                                  return;
                                }

                                setQuantity(e.target.value);
                              }}
                            />

                            {/* SELECT PLANT */}

                            <label className="mb-1 mt-3">Plant</label>
                            <select
                              className="form-control shadow-sm"
                              required
                              name="plant"
                              value={plant}
                              onChange={(e) => {
                                getMachines(e.target.value);
                                setPlant(e.target.value);
                              }}
                            >
                              <option defaultValue key="123" value="">
                                Select Plant
                              </option>
                              {plants.map((plant) => (
                                <option key={plant.plant} value={plant.plant}>
                                  {plant.plant}
                                </option>
                              ))}
                            </select>

                            {/* SELECT MACHINE */}
                            <label className="mb-1 mt-3">Machine</label>
                            <select
                              className="form-control shadow-sm"
                              required
                              name="machine"
                              value={machine}
                              onChange={(e) => setMachine(e.target.value)}
                            >
                              <option defaultValue value="">
                                Select Machine
                              </option>
                              {machines.map((machine) => (
                                <option
                                  key={machine.machine}
                                  value={machine.machine}
                                >
                                  {machine.machine}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/* ADVANCED OPTIONS */}
                          <div className="text-center mt-5">
                            <div className="btn-group">
                              {/* <button
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Allocate item to floor"
                                type="button"
                                onClick={() => setAdvancedOptions('REPLACE')}
                                className={`btn btn-${
                                  advancedOptions === 'REPLACE'
                                    ? 'primary'
                                    : 'light'
                                } shadow-sm`}
                              >
                                Replace
                              </button> */}
                              <button
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add allocation to floor without adding into scrap"
                                type="button"
                                onClick={() => setAdvancedOptions('ADD')}
                                className={`btn btn-${
                                  advancedOptions === 'ADD'
                                    ? 'primary'
                                    : 'light'
                                } shadow-sm`}
                              >
                                Add
                              </button>
                              <button
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Receive item from floor"
                                type="button"
                                onClick={() => setAdvancedOptions('RECEIVE')}
                                className={`btn btn-${
                                  advancedOptions === 'RECEIVE'
                                    ? 'primary'
                                    : 'light'
                                } shadow-sm`}
                              >
                                Receive
                              </button>
                            </div>
                          </div>

                          {/* DISPLAY ERROR */}

                          {error && (
                            <p className="text-danger text-center">{error}</p>
                          )}

                          <button
                            type="submit"
                            className="btn btn-success btn-block shadow-sm mt-4"
                          >
                            Allocate
                            {loading && (
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </form>
                      </div>
                    </div>

                    <div className="card mt-3">
                      <div className="card-body shadow-sm">
                        <h5 className="text-center text-black mb-3">
                          GR Reversal
                        </h5>

                        <form onSubmit={(e) => onSubmitDo(e, true)}>
                          <label className="mb-0">Quantity</label>
                          <input
                            className="form-control shadow-sm mb-3"
                            min="0"
                            max={tool.inStock}
                            step="0.01"
                            placeholder="Quantity to return"
                            type="number"
                            required
                            value={reversalQuantity || ''}
                            onChange={(e) => {
                              if (
                                +e.target.value % 1 !== 0 &&
                                ['Nos', 'Units', 'Pairs', 'Sets'].includes(
                                  tool.unit || ''
                                )
                              ) {
                                return;
                              }

                              setReversalQuantity(e.target.value);
                            }}
                          />

                          <label className="mb-0">Remarks</label>
                          <textarea
                            className="form-control shadow-sm mb-3"
                            type="text"
                            placeholder="Remarks for return"
                            value={remark || ''}
                            onChange={(e) => setRemark(e.target.value)}
                          />

                          <button
                            className="btn btn-danger shadow-sm btn-block mt-4"
                            type="submit"
                          >
                            Return Item
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tools: state.inventory.tools,
  role: state.user.role,
  plants: state.inventory.plants,
  pagination: state.inventory.pagination,
  category: state.inventory.category,
  categories: state.inventory.categories,
  machines: state.inventory.machines,
  tree: state.inventory.tree.rows,
  treeItemCount: state.inventory.tree.count,
  loading: state.inventory.loading,
  error: state.inventory.error
});

export default connect(mapStateToProps, {
  getPlants,
  getMachines,
  getAllocationTree,
  createAllocation,
  updateInventoryItem,
  getAllInventoryCategories
})(ModifyAllocationModal);
