import axios from 'axios';
import {
  SET_LOADING,
  SET_DASHBOARD,
  REFRESH_STATS,
  SET_CLIENT_CONFIGS,
  SET_INVENTORY_CATEGORIES
} from './types';
import setError from '../components/utils/asyncHandler';

export const getAllInventoryCategories = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/inventory/categories', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({ type: SET_INVENTORY_CATEGORIES, payload: res.data.data });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const getPODestinations = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/config/destinations', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    const destinations = [...res.data.data];

    let multiplePODestinations = false;
    if (res.data.data.length > 0) {
      multiplePODestinations = true;
    }

    return dispatch({
      type: SET_CLIENT_CONFIGS,
      payload: { multiplePODestinations, destinations }
    });
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const createConfig =
  (configType, configName, configValue) => async (dispatch) => {
    try {
      const res = await axios.post(
        '/api/inventory/categories',
        { configName, configType, configValue },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      return res.data.data;
    } catch (err) {
      return setError(err, (res) =>
        dispatch({ type: res.type, payload: res.data })
      );
    }
  };

export const updateInventoryCategory = (categoryId) => async (dispatch) => {
  try {
    await axios.put(
      '/api/inventory/categories',
      { name: categoryId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    return;
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const deleteInventoryCategory = (categoryId) => async (dispatch) => {
  try {
    await axios.delete('/api/inventory/categories', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      },
      data: {
        name: categoryId
      }
    });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getDashboard = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/config/dashboard', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: SET_DASHBOARD, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const refreshStats = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/config/stats/refresh', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: REFRESH_STATS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};
