import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SET_USER, SET_LOADING, CLEAR_ERROR, LOGOUT_USER } from './types';
import setError from '../components/utils/asyncHandler';

export const setLoading = () => (dispatch) => {
  dispatch({ type: SET_LOADING });
};

export const loginUser = (username, password) => async (dispatch) => {
  try {
    setLoading();
    dispatch({ type: CLEAR_ERROR });

    const res = await axios.post('/api/auth/login', {
      email: username,
      password
    });

    localStorage.setItem('JWT_TOKEN', res.data.token);

    const decoded = jwtDecode(res.data.token);

    dispatch({
      type: SET_USER,
      payload: decoded
    });

    return {
      success: true,
      role: decoded.role
    };
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));

    return { success: false };
  }
};

/**
 * Register a new client
 */
export const registerClient = (data) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });

    const res = await axios.post('/api/auth/signup', data);

    if (res.data) {
      return {
        success: true,
        subDomain: res.data.data.subDomain
      };
    }

    return null;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const sendVerificationOTP = (phoneNumber) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });

    await axios.post('/api/auth/signup/otp', { phoneNumber });

    return true;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const verifyVerificationOTP = (phoneNumber, otp) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });

    await axios.post('/api/auth/signup/otp/verify', {
      phoneNumber,
      OTP: otp
    });

    return true;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

/**
 * Register a new client
 */

export const setCurrentUser = (decoded) => ({
  type: SET_USER,
  payload: decoded
});

export const logoutUser = () => {
  localStorage.removeItem('JWT_TOKEN');

  return {
    type: LOGOUT_USER
  };
};

export const getUserProfile = () => async (dispatch) => {
  try {
    // dispatch({ type: SET_LOADING })

    const res = await axios.post(
      '/api/auth/me',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({
      type: SET_USER,
      payload: res.data.data
    });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const updateUserProfile = (data) => async (dispatch) => {
  try {
    await axios.put('/api/auth/me', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    const res = await axios.post(
      '/api/auth/me',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({
      type: SET_USER,
      payload: res.data.data
    });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const updateUserProfilePicture = (file) => async (dispatch) => {
  try {
    return await axios.put('/api/auth/me/profilepic', file, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const forgotPassword = (email) => async () => {
  try {
    const res = await axios.put(
      '/api/auth/reset/token',
      { email },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    return res.data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data.error;
    }
    return 'Error';
  }
};

export const checkProvidedToken = (token) => async () => {
  try {
    const res = await axios.put(
      '/api/auth/reset/check/token',
      { token },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    return res.data.success;
  } catch (err) {
    if (err.response) {
      return err.response.data.error;
    }
    return 'Error';
  }
};

export const resetPassword = (email, password, token) => async () => {
  try {
    const res = await axios.put(
      '/api/auth/reset/password',
      { email, password, token },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    return res.data.data;
  } catch (err) {
    if (err.response) {
      return err.response.data.error;
    }
    return 'Error';
  }
};
