import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addItemIntoCart,
  forceClsoeRequisitionItem,
  getPendingRequisitionItems
} from '../../../actions/poActions';
import { getPODestinations } from '../../../actions/configActions';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import SuccessModal from '../../utils/SuccessModal';
import ConfirmationModal from '../../utils/ConfirmationModal';
import ItemsWaitingForPurchase from '../../utils/ItemsWaitingForPurchase';

function NewPurchaseOrder({
  user,
  cart,
  loading,
  pendingItems,
  addItemIntoCart,
  getPODestinations,
  forceClsoeRequisitionItem,
  getPendingRequisitionItems
}) {
  const [deleteItem, setDeleteItem] = useState([]);
  const [pending, setPending] = useState([]);
  const [filter, setFilter] = useState([]);

  async function getItems() {
    const res = await getPendingRequisitionItems();
    res && setPending(res);
  }

  useEffect(() => {
    getItems();
    getPODestinations();
    addItemIntoCart([]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Create Purchase Order" />

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-7">
                  <ItemsWaitingForPurchase
                    cart={cart}
                    addItemIntoCart={addItemIntoCart}
                    loading={loading}
                    filter={filter}
                    pending={pending}
                    deleteItem={deleteItem}
                    setFilter={setFilter}
                    setPending={setPending}
                    setDeleteItem={setDeleteItem}
                  />
                </div>

                <div className="col-sm-5">
                  <div className="card text-black shadow-sm">
                    <div className="card-body">
                      <h6 className="text">PURCHASING LIST</h6>

                      {!Object.keys(cart).length > 0 && (
                        <div className="text-center">
                          <img
                            className="mt-4 mb-4"
                            src="../../../cart.svg"
                            alt="Purchase Cart"
                            draggable="false"
                            style={{ opacity: 0.3, height: 300, width: 300 }}
                          />

                          <h5 className="text-black text-center">
                            PURCHASING LIST IS EMPTY
                          </h5>
                          <hr />
                        </div>
                      )}

                      <ul className="list-group list-group-flush mt-3 mb-4">
                        {cart.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li className="list-group-item" key={index}>
                            <div className="row">
                              <div className="col-sm-9 font-weight-bold">
                                {item.item}
                              </div>
                              <div className="col-sm-3">
                                <span className="float-right">
                                  <i
                                    className="btn btn-info btn-sm font-weight-bold"
                                    style={{ cursor: 'default' }}
                                  >
                                    {`${item.quantity} ${item.unit}`}
                                  </i>

                                  <i
                                    className="ml-2 fas fa-times-circle text-danger"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setPending([...pending, item]);
                                      setFilter([]);

                                      addItemIntoCart(
                                        cart
                                          .slice(0, index)
                                          .concat(cart.slice(index + 1))
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="float-right mt-1">
                        <button
                          className="btn btn-danger btn-sm shadow-sm"
                          disabled={!(Object.keys(cart).length > 0)}
                          onClick={() => {
                            setPending(pendingItems);
                            addItemIntoCart([]);
                          }}
                        >
                          CLEAR LIST
                        </button>

                        <Link
                          className="ml-2 btn btn-success btn-sm shadow-sm"
                          to={`/${user.role}/purchaseorder/details`}
                          disabled={!(Object.keys(cart).length > 0)}
                        >
                          CREATE PURCHASE ORDER
                        </Link>
                      </div>

                      <SuccessModal
                        title="Purchase Order Generated"
                        redirectTo={`/${user.role}/purchaseorder`}
                      />

                      <ConfirmationModal
                        bodyText="Are you sure, you want to force close this/these item(s). This action cannot be reversed once you click Confirm button"
                        modalId="confirmCloseItem"
                        title="Confirm Force Close"
                        buttonTitle="Force Close"
                        buttonStyle="danger"
                        dismissOnClick
                        response={async () => {
                          await forceClsoeRequisitionItem(deleteItem);
                          setDeleteItem([]);
                          await getItems();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  cart: state.purchaseorders.new,
  pendingItems: state.purchaseorders.pendingItems,
  loading: state.purchaseorders.loading
});

export default connect(mapStateToProps, {
  getPendingRequisitionItems,
  forceClsoeRequisitionItem,
  getPODestinations,
  addItemIntoCart
})(NewPurchaseOrder);
