import React, { useState, useEffect } from 'react';
import './style.css';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { printElement } from '../../utils/functions';
import SendSMSNotification from '../../common/SendSMSNotification';
import { getGroupedRequisitions } from '../../../actions/requisitionActions';
import ApproveRequisition from '../../Admin/Requisition/ApproveRequisition';

function RequisitionItemModal({
  wfa,
  user,
  selected,
  register,
  approved,
  deleteRequisition,
  getGroupedRequisitions
}) {
  const requisitionItems = [...wfa, ...approved, ...register];

  const [amount, setAmount] = useState([]);
  const [approvedBy, setApprovedBy] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [sendRemark, setSendRemark] = useState({
    trigger: false,
    remark: '',
    custom: false
  });

  let items = [];
  let ifAmount;
  let totalAmount = 0;

  const index = requisitionItems.findIndex(
    (obj) => obj.serialNumber === selected
  );

  const requisition = requisitionItems[index];

  const getNotifications = (referenceNumber) => {
    axios
      .get(`/api/config/notification/${referenceNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      })
      .then((res) => setNotifications([...res.data.data]))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    requisition &&
      axios
        .post(
          '/api/inventory/search/batch',
          { items, userId: requisition && requisition.approvedBy },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
            }
          }
        )
        .then((res) => {
          requisition.status === 'WFA' && setAmount(res.data.data.items);
          setApprovedBy(res.data.data.users);
        })
        .catch((err) => console.log(err));

    requisition &&
      requisition.hasNotifications === 'T' &&
      getNotifications(requisition.serialNumber);

    return () => {
      setNotifications([]);
      window.$('#showRequisitionDetails').modal('hide');
    };
    // eslint-disable-next-line
  }, [selected]);

  requisition &&
    // eslint-disable-next-line no-return-assign
    requisition.requisitionItems.map((item) => (items = [...items, item.item]));

  const status = [];

  if (requisition && requisition.status === 'APPROVED') {
    requisition.requisitionItems.map((item) => {
      if (item.po) {
        switch (item.po.status) {
          case 'PO_GENERATED':
            status.push(3);
            break;

          case 'PO_APPROVED':
            status.push(4);
            break;

          case 'PARTIALY_RECEIVED':
            status.push(5);
            break;

          case 'RECEIVED':
            status.push(6);
            break;

          case 'GR_GENERATED':
            status.push(7);
            break;

          default:
            break;
        }
      } else {
        item.quantity > 0 && status.push(2);
      }

      return true;
    });
  } else if (requisition && requisition.status === 'REJECTED') {
    status.push(1.5);
  } else if (requisition && requisition.status === 'DELETED') {
    status.push(0);
  } else {
    status.push(1);
  }

  let min = Math.min(...status);
  let max = Math.max(...status);

  /**
   * If all PR items are rejected then status === []
   * hence, min & max methods returns Infinity
   * which results in Progressbar showing Material at security
   */
  if (min === Infinity || max === Infinity) {
    min = 2;
    max = 2;
  }

  return (
    <div
      className="modal fade"
      id="showRequisitionDetails"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          {requisition && (
            <div className="modal-body" id="printThis">
              <h4 className="text text-center text-black-50">
                <span className="text-black">{requisition.serialNumber}</span>
                <span
                  className="float-right"
                  style={{
                    cursor: 'pointer',
                    marginTop: '-4px'
                  }}
                  data-dismiss="modal"
                >
                  x
                </span>
              </h4>

              <div className="card shadow mb-2">
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-borderless table-striped table-hover small text-center text-black"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr className="bg-dark text-white op-0-9">
                          <th width="1%">SR</th>
                          <th className="text-left">Item</th>
                          {requisition.status === 'WFA' && <th>In Stock</th>}
                          <th>Requested Qt</th>
                          <th>Approved Qt</th>
                          <th>Rate</th>
                          <th>Amount</th>
                          <th>PO</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {requisition.requisitionItems.map((item, index) => {
                          if (requisition.status === 'WFA') {
                            ifAmount =
                              amount[
                                amount.findIndex(
                                  (obj) => obj.item === item.item
                                )
                              ];

                            ifAmount
                              ? (totalAmount +=
                                  ifAmount.rate * item.requestedQuantity)
                              : (totalAmount += 0);
                          } else {
                            ifAmount = item;

                            totalAmount += item.rate * item.quantity;
                          }

                          return (
                            /**
                             * If requisition is approved and quantity is 0
                             * That means 0 qty was approved by admin
                             * Hence, Show text in red color
                             */
                            <tr
                              key={item.id}
                              className={`${
                                requisition.status !== 'WFA' &&
                                item.quantity === 0
                                  ? 'text-danger'
                                  : ''
                              } ${
                                item.rejected === 'T'
                                  ? 'bg-warning no-hover op-0-8'
                                  : ''
                              }`}
                            >
                              <td>{index + 1}</td>
                              <td className="text-left" width="30%">
                                {item.item}
                                {item.rejected && (
                                  <i className="text-danger font-weight-bold float-right">
                                    {item.rejectionStatus ||
                                      'Rejected - reason not available'}
                                  </i>
                                )}
                              </td>

                              {requisition.status === 'WFA' && ifAmount && (
                                <td>{`${ifAmount.inStock} ${item.unit}`}</td>
                              )}

                              <td width="10%">
                                {`${item.requestedQuantity} ${item.unit}`}
                              </td>

                              <td width="10%">
                                {item.quantity
                                  ? `${item.quantity} ${item.unit}`
                                  : 'NA'}
                              </td>

                              <td>
                                ₹{' '}
                                {(ifAmount && ifAmount.rate.toLocaleString()) ||
                                  0}
                              </td>
                              <td>
                                ₹{' '}
                                {(ifAmount &&
                                  (
                                    ifAmount.rate *
                                    (requisition.status !== 'WFA'
                                      ? item.quantity
                                      : item.requestedQuantity)
                                  )
                                    .toFixed(2)
                                    .toLocaleString()) ||
                                  0}
                              </td>

                              <td>{item.po ? item.po.poNumber : 'NA'}</td>

                              <td className="text-xs">
                                {/*
                                    REFACTOR THIS AFTER STATUS IS WORKING
                                */}
                                {item.poGenerated === 'F'
                                  ? requisition.status === 'WFA'
                                    ? 'Waiting Approval'
                                    : requisition.status === 'APPROVED'
                                    ? 'Requisition Approved'
                                    : 'Requisition Rejected'
                                  : item.po
                                  ? item.po.status === 'RECEIVED'
                                    ? 'Received at Security'
                                    : item.po.status === null
                                    ? 'Requisition Approved'
                                    : item.po.status.split('_').join(' ')
                                  : 'Mutilple Quotations'}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {user.username === requisition.createdBy &&
                      ['production', 'inventory'].includes(user.role) &&
                      requisition.status === 'WFA' && (
                        <>
                          <Link
                            to={`/${user.role}/requisition/edit/${requisition.serialNumber}`}
                            className="btn btn-warning btn-sm shadow-sm float-left font-weight-bold"
                          >
                            Edit Requisition
                          </Link>

                          <button
                            type="button"
                            onClick={() => deleteRequisition(selected)}
                            className="btn btn-danger btn-sm shadow-sm float-left font-weight-bold ml-2"
                          >
                            Delete This Requisition
                          </button>
                        </>
                      )}

                    <div className="float-left">
                      {user.role === 'admin' && requisition.status === 'WFA' && (
                        <SendSMSNotification
                          sendRemark={sendRemark}
                          from={`${user.firstName} ${user.lastName}`}
                          response={() => {
                            getNotifications(requisition.serialNumber);
                            requisition.hasNotifications !== 'T' &&
                              getGroupedRequisitions(1, 'WFA');
                          }}
                          sendDetails={{
                            referenceNumber: requisition.serialNumber,
                            messageTo: requisition.createdBy,
                            type: 'Requisition'
                          }}
                          setSendRemark={(response) =>
                            setSendRemark({ ...response })
                          }
                        />
                      )}

                      <ul className="list-group list-group-flush">
                        {notifications.map((notification, index) => (
                          <li
                            className="list-group-item text-black d-flex justify-content-between align-items-center pt-1 pb-1"
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                          >
                            {notification.messageBody}
                            <span className="badge badge-light badge-pill ml-3">
                              {notification.createdBy}
                              <br />
                              {moment(notification.createdAt).format(
                                'DD/MM/YY hh:mm A'
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <h6 className="font-weight-bold text-black float-right mr-4 mt-2">
                      Total Amount: ₹ {totalAmount.toFixed(2).toLocaleString()}
                      <i
                        onClick={() =>
                          printElement(document.getElementById('printThis'))
                        }
                        className="pl-2 fas fa-print text-dark cursor-on-hover iccoon"
                      />
                    </h6>
                  </div>
                </div>
              </div>

              <div className="row mt-2 mb-2">
                <div className="col-sm-6">
                  <div className="card bg-light small">
                    <div className="card-body shadow">
                      <h6>
                        Required By:
                        <span className="font-weight-bold float-md-right">
                          {moment(requisition.requiredBy).format(
                            'DD MMMM YYYY'
                          )}
                        </span>
                      </h6>
                      <hr />

                      <h6>
                        Status:
                        <span className="font-weight-bold float-md-right">
                          {requisition.status === 'WFA'
                            ? 'Waiting For Approval'
                            : requisition.status}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="card bg-light small">
                    <div className="card-body shadow">
                      <h6>
                        Created By:
                        <span className="font-weight-bold float-md-right">
                          {requisition.createdBy.charAt(0).toUpperCase() +
                            requisition.createdBy.slice(1)}
                        </span>
                        <br />
                        <span className="font-weight-bold float-md-right text-xs text-info">
                          {moment(requisition.createdAt).format(
                            'DD MMMM YYYY - hh:mm A'
                          )}
                        </span>
                      </h6>

                      {approvedBy && (
                        <>
                          <hr />
                          <h6>
                            {requisition.status === 'REJECTED'
                              ? 'Rejected '
                              : 'Approved '}
                            By
                            <span className="font-weight-bold float-md-right">
                              {`${approvedBy.firstName} ${approvedBy.lastName} (${approvedBy.role})`}
                            </span>
                            <br />
                            <span className="font-weight-bold float-md-right text-xs text-info">
                              {moment(requisition.approvedAt).format(
                                'DD MMMM YYYY - hh:mm A'
                              )}
                            </span>
                          </h6>
                        </>
                      )}

                      {requisition.plant && (
                        <>
                          <hr />

                          <h6>
                            Plant:
                            <span className="font-weight-bold float-md-right">
                              {requisition.plant} [{requisition.machine} ]
                            </span>
                            <br />
                            <span className="text-xs float-md-right font-weight-bold text-info">
                              Remark: {requisition.remark}
                            </span>
                          </h6>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-body">
                  <ol className="requisition-progress mb-2 mt-2">
                    <li
                      className={min >= 1 ? 'is-active' : 'is-rejected'}
                      data-step="1"
                    >
                      Waiting for Approval
                    </li>
                    <li
                      className={
                        min >= 2
                          ? 'is-active'
                          : min === 1.5
                          ? 'is-rejected'
                          : null
                      }
                      data-step="2"
                    >
                      {requisition.status === 'REJECTED'
                        ? 'Rejected'
                        : 'Approved'}
                    </li>
                    <li
                      className={
                        min >= 3 ? 'is-active' : max >= 3 ? 'is-pending' : null
                      }
                      data-step="3"
                    >
                      PO Generated
                    </li>

                    <li
                      className={
                        min >= 4 ? 'is-active' : max >= 4 ? 'is-pending' : null
                      }
                      data-step="4"
                    >
                      PO Approved
                    </li>

                    <li
                      className={
                        min >= 6 ? 'is-active' : max >= 5 ? 'is-pending' : null
                      }
                      data-step="5"
                    >
                      Material at Security
                    </li>
                    <li
                      className={
                        min === 7
                          ? 'is-active'
                          : max === 7
                          ? 'is-pending'
                          : null
                      }
                      data-step="6"
                    >
                      GR Generated
                    </li>
                  </ol>
                </div>
              </div>

              {user.role === 'admin' && requisition.status === 'WFA' && (
                <ApproveRequisition requisitionId={requisition.serialNumber} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  wfa: state.requisitions.requisitions.wfa.data,
  approved: state.requisitions.requisitions.approved.data,
  register: state.requisitions.requisitionItems
});

export default connect(mapStateToProps, { getGroupedRequisitions })(
  RequisitionItemModal
);
