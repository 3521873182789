import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  forgotPassword,
  checkProvidedToken,
  resetPassword
} from '../../actions/authActions';

function ResetPassword({ forgotPassword, checkProvidedToken, resetPassword }) {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [password, setPassword] = useState({
    password: '',
    confimPassword: ''
  });

  const history = useHistory();

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const urlEmail = params.get('email');

  const validateToken = async () => {
    const res = await checkProvidedToken(token);

    res === true ? setValidToken(true) : history.push('/forgotpassword');
  };

  useEffect(() => {
    if (token) {
      validateToken();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-password-image" />
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">
                        Forgot Your Password?
                      </h1>
                      <p className="mb-4">
                        We get it, stuff happens. Just enter your email address
                        below and we&apos;ll send you a link to reset your
                        password!
                      </p>
                    </div>

                    {!token && (
                      <>
                        <form
                          className="user"
                          onSubmit={async (e) => {
                            e.preventDefault();
                            setLoading(true);

                            const res = await forgotPassword(email);

                            setLoading(false);
                            setResponse(res);
                          }}
                        >
                          <div className="form-group">
                            <input
                              type="email"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control form-control-user"
                              placeholder="Enter Email Address..."
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-user btn-block"
                          >
                            Reset Password
                          </button>
                        </form>

                        {response && (
                          <div className="text-center mt-3">
                            <p className="small">{response}</p>
                          </div>
                        )}

                        <hr />
                        <div className="text-center">
                          <Link className="small" to="/">
                            Already have an account? Login!
                          </Link>
                        </div>
                      </>
                    )}

                    {validToken && (
                      <>
                        <form
                          className="user"
                          onSubmit={async (e) => {
                            e.preventDefault();
                            setLoading(true);

                            const res = await resetPassword(
                              urlEmail,
                              password.password,
                              token
                            );

                            setLoading(false);
                            setResponse(res);
                            setPassword({});
                          }}
                        >
                          <div className="form-group">
                            <label className="small ml-2">New Password</label>
                            <input
                              type="password"
                              required
                              value={password.password || ''}
                              onChange={(e) =>
                                setPassword({
                                  ...password,
                                  password: e.target.value
                                })
                              }
                              className="form-control form-control-user"
                              placeholder="Enter a six digit passowrd..."
                            />
                          </div>

                          <div className="form-group">
                            <label className="small ml-2">
                              Confim New Password
                            </label>
                            <input
                              type="password"
                              required
                              value={password.confimPassword || ''}
                              onChange={(e) =>
                                setPassword({
                                  ...password,
                                  confimPassword: e.target.value
                                })
                              }
                              className={`form-control form-control-user ${
                                password.password !== password.confimPassword ||
                                !password.password ||
                                (!password.confimPassword && 'is-invalid')
                              }`}
                              placeholder="Re-enter six digit password..."
                            />
                            <label className="invalid-feedback">
                              Both passwords should match
                            </label>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary btn-user btn-block"
                            disabled={
                              !!(
                                password.password !== password.confimPassword ||
                                !password.password ||
                                !password.confimPassword ||
                                password.password.length < 5
                              )
                            }
                          >
                            Update Password
                          </button>
                        </form>

                        {response && (
                          <div className="text-center mt-3">
                            <p className="small">{response}</p>
                          </div>
                        )}
                      </>
                    )}

                    {loading && (
                      <div className="text-center mt-3">
                        <p className="small">Loading...</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  forgotPassword,
  checkProvidedToken,
  resetPassword
})(ResetPassword);
