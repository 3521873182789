import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import Footer from '../../common/Footer';
import LogoutModal from '../../common/LogoutModal';
import {
  getRequisitionByID,
  updateRequisition
} from '../../../actions/requisitionActions';
import Spinner from '../../common/Spinner';

function EditRequisition({
  match,
  history,
  getRequisitionByID,
  updateRequisition,
  loading
}) {
  const [amount, setAmount] = useState([]);
  const [remove, setRemove] = useState([]);
  const [requisition, setRequisition] = useState([]);

  let items = [];
  let ifAmount;
  let totalAmount = 0;
  let totalUpdatedAmount = 0;

  useEffect(() => {
    const p1 = Promise.resolve(getRequisitionByID(match.params.id));

    p1.then((res) => {
      let temp = [];

      res.map((item) => {
        // eslint-disable-next-line
        items = [...items, item.item];
        temp = [...temp, { ...item, quantity: item.requestedQuantity }];

        return true;
      });

      setRequisition([...temp]);

      axios
        .post(
          '/api/inventory/search/batch',
          { items },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
            }
          }
        )
        .then((res) => setAmount(res.data.data.items))
        .catch((err) => console.log(err));
    }).catch(() => history.push('/admin/requisition'));
  }, []);

  // REMOVE item from requisition and SET its QUANTITY to 0
  const removeItem = (index) => {
    const temp = [...requisition];

    temp[index].quantity = 0;

    setRequisition([...temp]);

    setRemove([...remove, index]);
  };

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}

          <div id="content">
            <Topbar title="Edit Requisition" />

            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header">
                  Update Requistion # {match.params.id} {loading && <Spinner />}
                </div>
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      updateRequisition(match.params.id, requisition);

                      history.push('/admin/requisition');
                    }}
                  >
                    <div className="table-responsive">
                      <table
                        className="table table-borderless text-center table-striped"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th className="text-left" width="30%">
                              Item
                            </th>
                            <th>In Stock</th>
                            <th>Requested Quantity</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Approved Quantity</th>
                            <th>Updated Amount</th>
                            <th>
                              <i className="fas fa-trash text-danger" />
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {requisition.map((tool, index) => {
                            ifAmount =
                              amount[
                                amount.findIndex(
                                  (obj) => obj.item === tool.item
                                )
                              ];

                            if (!ifAmount) {
                              return true;
                            }

                            if (amount[0]) {
                              totalAmount +=
                                ifAmount.rate * tool.requestedQuantity;

                              totalUpdatedAmount +=
                                ifAmount.rate * tool.quantity;
                            }

                            return (
                              <tr
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                className={`${
                                  remove.includes(index)
                                    ? 'bg-danger text-white'
                                    : null
                                }`}
                                style={{
                                  opacity: remove.includes(index) ? '0.4' : '1'
                                }}
                              >
                                {/* Item Name */}
                                <td className="text-left">{tool.item}</td>

                                {/* Cuurent Stock of Item in Inventory */}
                                <td className="text-center font-weight-bold">
                                  {(amount[0] && ifAmount.inStock) || 0}{' '}
                                  {tool.unit}
                                </td>

                                {/* Requested Quantity */}
                                <td className="text-center font-weight-bold">
                                  {tool.requestedQuantity} {tool.unit}
                                </td>

                                {/* Rate of Item */}
                                <td>
                                  {(amount[0] &&
                                    `₹ ${ifAmount.rate.toLocaleString()}`) ||
                                    'Loading...'}
                                </td>

                                {/* Amount of Item */}
                                <td className="font-weight-bold">
                                  {(amount[0] &&
                                    `₹ ${(
                                      ifAmount.rate * tool.requestedQuantity
                                    ).toLocaleString()}`) ||
                                    'Loading...'}
                                </td>

                                {/* Approve Quantity */}
                                <td width="13%">
                                  <input
                                    type="number"
                                    className={`form-control form-control-tool text-center shadow-sm ${
                                      tool.quantity > tool.requestedQuantity
                                        ? 'is-invalid'
                                        : null
                                    }`}
                                    required
                                    step="0.01"
                                    min="0"
                                    placeholder="Quantity"
                                    disabled={!!remove.includes(index)}
                                    value={
                                      tool.quantity >= 0 ? tool.quantity : ''
                                    }
                                    onChange={(e) => {
                                      const temp = [...requisition];

                                      if (
                                        +e.target.value % 1 !== 0 &&
                                        [
                                          'Nos',
                                          'Units',
                                          'Pairs',
                                          'Sets'
                                        ].includes(tool.unit)
                                      ) {
                                        return;
                                      }

                                      temp[index].quantity = +Number(
                                        e.target.value
                                      ).toFixed(2);

                                      setRequisition([...temp]);
                                    }}
                                  />
                                  <p className="invalid-feedback mb-0">
                                    Please check requested quantity
                                  </p>
                                </td>

                                {/* Updated Amount of item wrt Updated Quantity */}
                                <td className="text-center font-weight-bold">
                                  {amount[0] &&
                                    `₹ ${(
                                      ifAmount.rate * tool.quantity
                                    ).toLocaleString()}`}
                                </td>

                                {/* Remove Item from PR */}
                                <td>
                                  <i
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      if (remove.includes(index)) {
                                        setRemove(
                                          remove.splice(
                                            remove.findIndex(
                                              (obj) => obj === index
                                            ),
                                            0
                                          )
                                        );
                                      } else {
                                        removeItem(index);
                                      }
                                    }}
                                    className={`fas fa-${
                                      remove.includes(index)
                                        ? 'plus-circle text-info'
                                        : 'minus-circle text-danger'
                                    }`}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>

                        <tfoot>
                          <tr>
                            <th />
                            <th />
                            <th />
                            <th>Total: </th>
                            <th>₹{totalAmount.toLocaleString()}</th>
                            <th />
                            <th className="text-center">
                              ₹ {totalUpdatedAmount.toLocaleString()}
                            </th>
                            <th />
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <button
                      className="btn btn-success btn-lg btn-block"
                      type="submit"
                    >
                      Update & Approve Requisition
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.requisitions.loading
});

export default connect(mapStateToProps, {
  getRequisitionByID,
  updateRequisition
})(EditRequisition);
