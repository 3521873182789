import axios from 'axios';
import {
  SET_PO_WAITING_TOBE_RECEIVED,
  SET_PO_WAITING_APPROVAL,
  SET_ALL_RECEIVED_GOODS,
  SET_GOODS_REGISTER_ITEMS,
  SET_GR_LOADING,
  SET_LOADING
} from './types';
import setError from '../components/utils/asyncHandler';

export const setGRLoading = (loading) => async (dispatch) => {
  dispatch({ type: SET_GR_LOADING, payload: loading || false });
};

export const getAllReceivedGoods = (pageNumber) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(
      `/api/gr/goodsreceived?page=${pageNumber || 1}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_ALL_RECEIVED_GOODS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getGoodsRegisterItems = (page, filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    let res;

    if (filter) {
      res = await axios.post(
        `/api/gr/goodsregister/${filter.phrase || 'null'}?page=${
          page || 1
        }&sort=approvedAt_DESC`,
        { filter },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );
    } else {
      res = await axios.get(
        `/api/gr/goodsregister?page=${page || 1}&sort=approvedAt_DESC`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );
    }

    dispatch({ type: SET_GOODS_REGISTER_ITEMS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getPoToBeReceived = (destination, date) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(
      `/api/gr/security/receive/${destination || 'null'}?date=${date || ''}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_PO_WAITING_TOBE_RECEIVED, payload: res.data.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const forceClosePOItem = (poNumber, items) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`/api/gr/pending/${poNumber}`, items, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const receivePoItems =
  (poNumber, items, billDetails) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      const body = { items, billDetails };

      await axios.post(`/api/gr/security/${poNumber}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      });

      return true;
    } catch (err) {
      return setError(err, (res) =>
        dispatch({ type: res.type, payload: res.data })
      );
    }
  };

export const getGrToBeApproved = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get('/api/gr/received', {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });

    dispatch({ type: SET_PO_WAITING_APPROVAL, payload: res.data.data });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const approveGoodsReceived = (body) => async (dispatch) => {
  try {
    await axios.post('/api/gr/approve', body, {
      headers: { Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}` }
    });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};
