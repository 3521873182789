import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addNewVendor } from '../../../actions/poActions';

function AddNewVendor({ selected, setVendor, addNewVendor }) {
  const [vendorDetails, setVendorDetails] = useState({});

  return (
    <div
      className="modal fade"
      id="addNewVendor"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <h4 className="text text-center text-black-50">
              Add New Vendor
              <span
                className="float-right"
                style={{
                  cursor: 'pointer',
                  marginTop: '-4px'
                }}
                onClick={() => window.$('#addNewVendor').modal('hide')}
              >
                x
              </span>
            </h4>

            <form
              onSubmit={async (e) => {
                e.preventDefault();

                const vendor = await addNewVendor(vendorDetails);

                if (setVendor) {
                  setVendor(
                    selected,
                    vendor[0].serialNumber,
                    vendor[0].particulars
                  );

                  setVendorDetails({});
                }

                window.$('#addNewVendor').modal('hide');
              }}
            >
              <div className="row">
                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">
                    Vendor&apos;s Name
                  </label>

                  <input
                    type="text"
                    placeholder="Enter vendors name"
                    required
                    className="form-control shadow-sm"
                    value={vendorDetails.particulars || ''}
                    onChange={(e) =>
                      setVendorDetails({
                        ...vendorDetails,
                        particulars: e.target.value.toUpperCase()
                      })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">
                    Vendor&apos;s GSTIN
                  </label>

                  <input
                    className="form-control shadow-sm"
                    type="text"
                    placeholder="Enter vendors GSTIN"
                    required
                    value={vendorDetails.gstin || ''}
                    onChange={(e) =>
                      setVendorDetails({
                        ...vendorDetails,
                        gstin: e.target.value.toUpperCase()
                      })
                    }
                  />
                </div>

                <div className="col-sm-12">
                  <label className="text-dark mt-3 mb-0">
                    Vendor&apos;s Address
                  </label>

                  <input
                    className="form-control shadow-sm"
                    type="text"
                    placeholder="Enter vendors address"
                    required
                    value={vendorDetails.address || ''}
                    onChange={(e) =>
                      setVendorDetails({
                        ...vendorDetails,
                        address: e.target.value.toUpperCase()
                      })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">Country</label>

                  <input
                    type="text"
                    placeholder="Enter country"
                    required
                    className="form-control shadow-sm"
                    value={vendorDetails.country || ''}
                    onChange={(e) =>
                      setVendorDetails({
                        ...vendorDetails,
                        country: e.target.value.toUpperCase()
                      })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">State</label>

                  <input
                    className="form-control shadow-sm"
                    type="text"
                    placeholder="Enter state"
                    required
                    value={vendorDetails.state || ''}
                    onChange={(e) =>
                      setVendorDetails({
                        ...vendorDetails,
                        state: e.target.value.toUpperCase()
                      })
                    }
                  />
                </div>
              </div>

              <div className="text-center mt-4">
                <div className="btn-group">
                  <button
                    className="btn btn-danger shadow-sm"
                    onClick={() => {
                      setVendorDetails({});
                      window.$('#addNewVendor').modal('hide');
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success shadow-sm"
                    disabled={!vendorDetails.particulars}
                    type="submit"
                  >
                    Add Vendor
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { addNewVendor })(AddNewVendor);
