import React from 'react';
import PropTypes from 'prop-types';

function AreYouSure({ clicked, selected, cancel }) {
  return (
    <div>
      <button
        data-testid="cancel-button"
        onClick={() => cancel()}
        className="btn btn-circle btn-warning shadow-sm mr-1"
      >
        <i className="fas fa-times" />
      </button>
      <button
        data-testid="click-button"
        onClick={() => clicked(selected)}
        className="btn btn-circle btn-danger shadow-sm"
      >
        <i className="fas fa-check" />
      </button>
    </div>
  );
}

AreYouSure.propTypes = {
  clicked: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default AreYouSure;
