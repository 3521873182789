import React from 'react';
import jwtDecode from 'jwt-decode';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store';
import Login from './components/Login';
import Admin from './components/Admin';
import Reports from './components/Reports';
import Users from './components/Admin/Users';
import Production from './components/Production';
import Profile from './components/common/Profile';
import Register from './components/Login/Register';
import InventoryDashboard from './components/Inventory';
import NotFoundPage from './components/utils/NotFoundPage';
import PrivateRoute from './components/utils/PrivateRoute';
import PORegister from './components/Registers/PORegister';
import MainInventory from './components/Inventory/Inventory';
import ResetPassword from './components/Login/ResetPassword';
import ManageVendors from './components/PurchaseOrder/Vendors';
import ViewPurchaseOrdersSecurity from './components/Security';
import PurchaseOrderDashboard from './components/PurchaseOrder';
import GoodsRegister from './components/Registers/GoodsRegister';
import { setCurrentUser, logoutUser } from './actions/authActions';
import ProductionInventory from './components/Production/Inventory';
import GoodsReversal from './components/PurchaseOrder/GoodsReversal';
import ViewGoodsReceived from './components/Production/GoodsReceived';
import ManageInventory from './components/Admin/Settings/ManageInventoy';
import ViewPurchaseOrders from './components/PurchaseOrder/PurchaseOrder';
import RequisitionRegister from './components/Registers/RequisitionRegister';
import EditRequisition from './components/Admin/Requisition/EditRequisition';
import AddPODetails from './components/PurchaseOrder/PurchaseOrder/AddPODetails';
import NewRequisition from './components/Production/Requisitions/NewRequisition';
import ViewGoodsReceivedForNonProduction from './components/Admin/GoodsReceived';
import ViewRequisitions from './components/Production/Requisitions/ViewRequisitions';
import PendingForPurchase from './components/Admin/PurchaseOrder/PendingForPurchase';
import WaitingToBeReceived from './components/Admin/GoodsReceived/WaitingToBeReceived';
import PurchaseOrderTracker from './components/Admin/PurchaseOrder/PurchaseOrderTracker';
import ItemsApprovedForPO from './components/Production/Requisitions/ItemsApprovedForPO';
import NewPurchaseOrder from './components/PurchaseOrder/PurchaseOrder/NewPurchaseOrder';

if (localStorage.JWT_TOKEN) {
  const decoded = jwtDecode(localStorage.JWT_TOKEN);

  if (decoded.exp < Date.now() / 1000) {
    store.dispatch(logoutUser());

    window.location.href = '/';
  }

  store.dispatch(setCurrentUser(decoded));
}

const userRole = store.getState().user.role;

if (
  window.location.pathname.replace('/', '').split('/')[0] !== userRole
  && userRole !== null
) {
  window.location.href = `/${userRole}`;
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            {/* PUBLIC ROUTES */}
            <Route exact path="/" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgotpassword" component={ResetPassword} />
            {/* PUBLIC ROUTES */}

            {/* PRODUCTION ROUTES */}
            <PrivateRoute exact path="/production" component={Production} />
            <PrivateRoute
              exact
              path="/production/inventory"
              component={ProductionInventory}
            />
            <PrivateRoute
              exact
              path="/production/requisition"
              component={ViewRequisitions}
              createRequisition
            />
            <PrivateRoute
              exact
              path="/production/requisition/new"
              component={NewRequisition}
            />
            <PrivateRoute
              path="/production/requisition/edit/:id"
              exact
              editRequisition
              component={NewRequisition}
            />
            <PrivateRoute
              exact
              path="/production/requisition/items"
              component={ItemsApprovedForPO}
            />
            <PrivateRoute
              exact
              path="/production/requisition/register"
              component={RequisitionRegister}
            />
            <PrivateRoute
              path="/production/purchaseorder"
              exact
              component={ViewPurchaseOrders}
            />
            <PrivateRoute
              path="/production/purchaseorder/track"
              exact
              component={PurchaseOrderTracker}
            />
            <PrivateRoute
              path="/production/purchaseorder/register"
              exact
              component={PORegister}
            />
            <PrivateRoute
              path="/production/goodsreceived"
              exact
              component={ViewGoodsReceived}
            />
            <PrivateRoute
              path="/production/goodsreceived/goodsregister"
              exact
              component={GoodsRegister}
            />
            <PrivateRoute
              path="/production/goodsreceived/grreversal"
              exact
              component={GoodsReversal}
            />
            <PrivateRoute
              path="/production/profile"
              exact
              component={Profile}
            />
            {/* PRODUCTION ROUTES */}

            {/* ADMIN ROUTES */}
            <PrivateRoute path="/admin" exact component={Admin} />
            <PrivateRoute
              path="/admin/inventory"
              exact
              component={ProductionInventory}
            />
            <PrivateRoute
              path="/admin/settings"
              exact
              component={ManageInventory}
            />
            <PrivateRoute
              path="/admin/requisition"
              exact
              component={ViewRequisitions}
            />
            <PrivateRoute
              exact
              path="/admin/requisition/register"
              component={RequisitionRegister}
            />
            <PrivateRoute
              path="/admin/requisition/edit/:id"
              exact
              component={EditRequisition}
            />
            <PrivateRoute
              path="/admin/purchaseorder"
              exact
              component={ViewPurchaseOrders}
            />
            <PrivateRoute
              path="/admin/purchaseorder/track"
              exact
              component={PurchaseOrderTracker}
            />
            <PrivateRoute
              path="/admin/purchaseorder/pending"
              exact
              component={PendingForPurchase}
            />
            <PrivateRoute
              path="/admin/purchaseorder/register"
              exact
              component={PORegister}
            />
            <PrivateRoute
              path="/admin/vendors"
              exact
              component={ManageVendors}
            />
            <PrivateRoute
              path="/admin/goodsreceived"
              exact
              component={ViewGoodsReceivedForNonProduction}
            />
            <PrivateRoute
              path="/admin/goodsreceived/waiting"
              exact
              component={WaitingToBeReceived}
            />
            <PrivateRoute
              path="/admin/goodsreceived/goodsregister"
              exact
              component={GoodsRegister}
            />
            <PrivateRoute
              path="/admin/goodsreceived/grreversal"
              exact
              component={GoodsReversal}
            />
            <PrivateRoute path="/admin/reports" exact component={Reports} />
            <PrivateRoute path="/admin/users" exact component={Users} />
            <PrivateRoute path="/admin/profile" exact component={Profile} />
            {/* ADMIN ROUTES */}

            {/* INVENTORY ROUTES */}
            <PrivateRoute
              path="/inventory"
              exact
              component={InventoryDashboard}
            />
            <PrivateRoute
              path="/inventory/inventory"
              exact
              component={MainInventory}
            />
            <PrivateRoute
              path="/inventory/requisition"
              exact
              component={ViewRequisitions}
              createRequisition
            />
            <PrivateRoute
              path="/inventory/requisition/new"
              exact
              component={NewRequisition}
            />
            <PrivateRoute
              path="/inventory/requisition/edit/:id"
              exact
              component={NewRequisition}
              editRequisition
            />
            <PrivateRoute
              exact
              path="/inventory/requisition/register"
              component={RequisitionRegister}
            />
            <PrivateRoute
              exact
              path="/inventory/requisition/items"
              component={ItemsApprovedForPO}
            />
            <PrivateRoute
              path="/inventory/purchaseorder"
              exact
              component={ViewPurchaseOrders}
            />
            <PrivateRoute
              path="/inventory/purchaseorder/track"
              exact
              component={PurchaseOrderTracker}
            />
            <PrivateRoute
              path="/inventory/purchaseorder/pending"
              exact
              component={PendingForPurchase}
            />
            <PrivateRoute
              path="/inventory/purchaseorder/register"
              exact
              component={PORegister}
            />
            <PrivateRoute
              path="/inventory/security"
              exact
              component={ViewPurchaseOrdersSecurity}
            />
            <PrivateRoute
              path="/inventory/goodsreceived"
              exact
              component={ViewGoodsReceived}
            />
            <PrivateRoute
              path="/inventory/goodsreceived/goodsregister"
              exact
              component={GoodsRegister}
            />
            <PrivateRoute
              path="/inventory/goodsreceived/grreversal"
              exact
              component={GoodsReversal}
            />
            <PrivateRoute path="/inventory/reports" exact component={Reports} />
            <PrivateRoute path="/inventory/profile" exact component={Profile} />
            {/* INVENTORY ROUTES */}

            {/* PURCHASE ORDER ROUTES */}
            <PrivateRoute
              path="/purchaseorder"
              exact
              component={PurchaseOrderDashboard}
            />
            <PrivateRoute
              exact
              path="/purchaseorder/inventory"
              component={ProductionInventory}
            />
            <PrivateRoute
              path="/purchaseorder/requisition"
              exact
              component={ViewRequisitions}
            />
            <PrivateRoute
              exact
              path="/purchaseorder/requisition/register"
              component={RequisitionRegister}
            />
            <PrivateRoute
              path="/purchaseorder/purchaseorder"
              exact
              component={ViewPurchaseOrders}
              createPo
            />
            <PrivateRoute
              path="/purchaseorder/purchaseorder/track"
              exact
              component={PurchaseOrderTracker}
            />
            <PrivateRoute
              path="/purchaseorder/purchaseorder/new"
              exact
              component={NewPurchaseOrder}
            />
            <PrivateRoute
              path="/purchaseorder/purchaseorder/details"
              exact
              component={AddPODetails}
            />
            <PrivateRoute
              path="/purchaseorder/purchaseorder/register"
              exact
              component={PORegister}
            />
            <PrivateRoute
              path="/purchaseorder/security"
              exact
              component={ViewPurchaseOrdersSecurity}
            />
            <PrivateRoute
              path="/purchaseorder/vendors"
              exact
              component={ManageVendors}
            />
            <PrivateRoute
              path="/purchaseorder/goodsreceived"
              exact
              component={ViewGoodsReceivedForNonProduction}
            />
            <PrivateRoute
              path="/purchaseorder/goodsreceived/goodsregister"
              exact
              component={GoodsRegister}
            />
            <PrivateRoute
              path="/purchaseorder/goodsreceived/grreversal"
              exact
              component={GoodsReversal}
            />
            <PrivateRoute
              path="/purchaseorder/profile"
              exact
              component={Profile}
            />
            {/* PURCHASE ORDER ROUTES */}

            {/* SECURITY ROUTE */}
            <PrivateRoute
              path="/security"
              exact
              component={ViewPurchaseOrdersSecurity}
            />
            <PrivateRoute path="/security/profile" exact component={Profile} />
            {/* SECURITY ROUTE */}

            <PrivateRoute component={NotFoundPage} role={userRole} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
