import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Topbar from '../../common/Topbar';
import Footer from '../../common/Footer';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import ConfirmationModal from '../../utils/ConfirmationModal';
import { getAllReversals, setReversalStatus } from '../../../actions/poActions';

function GoodsReversal({
  getAllReversals,
  setReversalStatus,
  reversals,
  reversalCount,
  loading,
  role
}) {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    getAllReversals(1);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'Item',
      selector: 'inventory.item',
      sortable: true,
      cell: (row) => (row.inventory ? row.inventory.item : 'Item Deleted'),
      width: '30%'
    },
    {
      name: 'Returned Quantity',
      selector: 'quantity',
      sortable: true,
      center: true
    },
    {
      name: 'Remarks',
      selector: 'remark',
      sortable: true,
      center: true,
      width: '20%'
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (row) => (
        <span className="font-weight-bold">
          {row.status
            ? row.status.split('_').join(' ')
            : 'Returned by Inventory'}
        </span>
      )
    },
    {
      name: 'Returned On',
      selector: 'createdAt',
      sortable: true,
      center: true,
      format: (row) => moment(row.createdAt).format('DD-MM-YYYY : hh:mm A')
    },
    {
      width: '5%',
      center: true,
      cell: (row) => {
        if (row.status !== 'RETURN_APPROVED' && role === 'purchaseorder') {
          return (
            <i
              className="fas fa-undo-alt iccoon text-success"
              onClick={() => {
                setSelected(row.serialNumber);
                window.$('#approveGoodsReturn').modal('show');
              }}
            />
          );
        }
        return <i className="fas fa-check text-success" />;
      }
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Goods Reversal" loading={loading} />

            <div className="container-fluid shadow-sm">
              <DataTable
                noHeader
                columns={columns}
                customStyles={{
                  headCells: {
                    style: {
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }
                  }
                }}
                highlightOnHover
                pointerOnHover
                data={reversals}
                pagination
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={reversalCount}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                onChangePage={async (pageNumber) =>
                  await getAllReversals(pageNumber)
                }
                paginationPerPage={15}
              />
            </div>
          </div>

          <ConfirmationModal
            modalId="approveGoodsReturn"
            title="Return Item"
            bodyText="Are you sure you want to return this item."
            buttonTitle="Approve Return"
            buttonStyle="success"
            selected={selected}
            dismissOnClick
            response={(id) => setReversalStatus(id)}
          />

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  reversals: state.purchaseorders.grReversal.rows,
  reversalCount: state.purchaseorders.grReversal.count,
  loading: state.purchaseorders.loading,
  role: state.user.role,
  error: state.purchaseorders.error
});

export default connect(mapStateToProps, { getAllReversals, setReversalStatus })(
  GoodsReversal
);
