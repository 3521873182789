import React, { useState, useEffect } from 'react';

function UpdateVendor({ selected, time, vendors, updateVendor }) {
  const [vendor, setVendor] = useState({
    particulars: '',
    address: '',
    state: '',
    country: '',
    gstin: ''
  });

  useEffect(() => {
    const index = vendors.findIndex((obj) => obj.serialNumber === selected);

    index !== -1 && setVendor(vendors[index]);
  }, [time]);

  return (
    <div
      className="modal fade"
      id="updateVendor"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <h4 className="text text-center text-black">
              Add New Vendor
              <span
                className="float-right"
                style={{
                  cursor: 'pointer',
                  marginTop: '-4px'
                }}
                data-dismiss="modal"
              >
                x
              </span>
            </h4>

            <form
              onSubmit={(e) => {
                e.preventDefault();

                updateVendor(vendor);

                setVendor({
                  particulars: '',
                  address: '',
                  state: '',
                  country: '',
                  gstin: ''
                });
              }}
            >
              <div className="row">
                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">
                    Vendor&apos;s Name
                  </label>

                  <input
                    type="text"
                    placeholder="Enter vendors name"
                    required
                    className="form-control shadow-sm"
                    value={vendor.particulars || ''}
                    onChange={(e) =>
                      setVendor({ ...vendor, particulars: e.target.value })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">
                    Vendor&apos;s GSTIN
                  </label>

                  <input
                    className="form-control shadow-sm"
                    type="text"
                    placeholder="Enter vendors GSTIN"
                    required
                    value={vendor.gstin || ''}
                    onChange={(e) =>
                      setVendor({ ...vendor, gstin: e.target.value })
                    }
                  />
                </div>

                <div className="col-sm-12">
                  <label className="text-dark mt-3 mb-0">
                    Vendor&apos;s Address
                  </label>

                  <input
                    className="form-control shadow-sm"
                    type="text"
                    placeholder="Enter vendors address"
                    required
                    value={vendor.address || ''}
                    onChange={(e) =>
                      setVendor({ ...vendor, address: e.target.value })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">Country</label>

                  <input
                    type="text"
                    placeholder="Enter country"
                    required
                    className="form-control shadow-sm"
                    value={vendor.country || ''}
                    onChange={(e) =>
                      setVendor({ ...vendor, country: e.target.value })
                    }
                  />
                </div>

                <div className="col-sm-6">
                  <label className="text-dark mt-3 mb-0">State</label>

                  <input
                    className="form-control shadow-sm"
                    type="text"
                    placeholder="Enter state"
                    required
                    value={vendor.state || ''}
                    onChange={(e) =>
                      setVendor({ ...vendor, state: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="text-center mt-4">
                <div className="btn-group">
                  <button
                    className="btn btn-danger shadow-sm"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-success shadow-sm" type="submit">
                    Update Vendor
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateVendor;
