import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Footer from '../../common/Footer';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import SearchSort from '../../common/SearchSort';
import LogoutModal from '../../common/LogoutModal';
import {
  getAllUsers,
  updateUserDetails,
  deleteUser
} from '../../../actions/userActions';
import AddNewUser from './AddNewUser';

function Users({
  users,
  error,
  loading,
  getAllUsers,
  updateUserDetails,
  deleteUser
}) {
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    getAllUsers(1);
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      name: 'Username',
      selector: 'username'
    },
    {
      name: 'Name',
      sortable: true,
      cell: row => `${row.firstName} ${row.lastName}`
    },
    {
      name: 'Mobile',
      selector: 'phone',
      center: true
    },
    {
      name: 'Email',
      selector: 'email',
      width: '20%'
    },
    {
      name: 'Role',
      selector: 'role',
      sortable: true,
      center: true,
      cell: row => row.role.charAt(0).toUpperCase() + row.role.slice(1)
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      center: true,
      format: row => (
        <small>{moment(row.createdAt).format('DD/MM/YY : hh:mm A')}</small>
      )
    },
    {
      name: 'SMS Alerts',
      width: '10%',
      selector: 'smsAlerts',
      center: true,
      cell: row => {
        const allowSMS = row.smsAlerts === 'T';
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={allowSMS}
              id={row.serialNumber}
              onChange={() => updateUserDetails(row.serialNumber, {
                smsAlerts: allowSMS ? 'F' : 'T'
              })}
            />
            <label
              className="custom-control-label hov-pointer"
              htmlFor={row.serialNumber}
            />
          </div>
        );
      }
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        color: '#202124',
        fontSize: '16px',
        fontWeight: 'bold'
      }
    }
  };

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Users" loading={loading} />

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-9">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <SearchSort addNewButton="addNewUser" />
                      <AddNewUser />

                      <div className="table-responsive">
                        <DataTable
                          noHeader
                          striped
                          customStyles={customStyles}
                          columns={columns}
                          highlightOnHover
                          pointerOnHover
                          onRowClicked={row => setSelectedUser(row)}
                          data={users}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {Object.keys(selectedUser).length > 0 && (
                  <div className="col-sm-3">
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <img
                          className="img-fluid"
                          style={{
                            objectFit: 'cover',

                            width: '100vw',
                            height: '150px'
                          }}
                          draggable="false"
                          src="../../../img/bg-login-img.jpg"
                          alt="Cover"
                        />

                        <div className="text-center">
                          <img
                            className="img-thumbnail shadow"
                            style={{
                              borderRadius: 100,
                              marginTop: '-55px',
                              width: '45%'
                            }}
                            draggable="false"
                            src={
                              selectedUser.profilePicURL
                              || '../../../img/default_profile.png'
                            }
                            alt="Profile"
                          />

                          <div className="text-dark mt-4 mb-2">
                            <label
                              htmlFor="username"
                              className="float-left mb-0 mt-2"
                            >
                              Username
                            </label>
                            <input
                              id="username"
                              className="form-control shadow-sm border-0 mb-2"
                              value={selectedUser.username}
                              onChange={e => setSelectedUser({
                                ...selectedUser,
                                username: e.target.value
                              })}
                            />

                            <label className="float-left mb-0 mt-2">
                              First Name
                            </label>
                            <input
                              className="form-control shadow-sm border-0 mb-2"
                              value={selectedUser.firstName}
                              onChange={e => setSelectedUser({
                                ...selectedUser,
                                firstName: e.target.value
                              })}
                            />

                            <label className="float-left mb-0 mt-2">
                              Last Name
                            </label>
                            <input
                              className="form-control shadow-sm border-0 mb-2"
                              value={selectedUser.lastName}
                              onChange={e => setSelectedUser({
                                ...selectedUser,
                                lastName: e.target.value
                              })}
                            />

                            <label className="float-left mb-0 mt-2">
                              Email
                            </label>
                            <input
                              className="form-control shadow-sm border-0 mb-2"
                              value={selectedUser.email}
                              readOnly
                              // onChange={e =>
                              //   setSelectedUser({
                              //     ...selectedUser,
                              //     email: e.target.value
                              //   })
                              // }
                            />

                            <label className="float-left mb-0 mt-2">
                              Phone
                            </label>
                            <input
                              className="form-control shadow-sm border-0 mb-2"
                              type="number"
                              value={selectedUser.phone || ''}
                              onChange={e => setSelectedUser({
                                ...selectedUser,
                                phone: e.target.value
                              })}
                            />

                            <label className="float-left mb-0 mt-2">Role</label>
                            <input
                              className="form-control shadow-sm border-0 mb-2"
                              value={selectedUser.role}
                              readOnly
                            />

                            {error && (
                              <p className="text-danger bold mt-4 mb-2">
                                {error}
                              </p>
                            )}

                            <div>
                              <button
                                className="btn btn-info shadow-sm mt-4 mr-1"
                                onClick={() => updateUserDetails(
                                  selectedUser.serialNumber,
                                  selectedUser
                                )}
                              >
                                Update
                              </button>

                              <button
                                className="btn btn-danger shadow-sm mt-4 ml-1"
                                onClick={async () => {
                                  const res = await deleteUser(
                                    selectedUser.serialNumber
                                  );
                                  res === true && setSelectedUser({});
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = state => ({
  users: state.admin.users,
  error: state.user.error,
  loading: state.admin.loading
});

export default connect(mapStateToProps, {
  getAllUsers,
  updateUserDetails,
  deleteUser
})(Users);
