import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Topbar from '../../common/Topbar';
import Footer from '../../common/Footer';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import { getPendingRequisitionItems } from '../../../actions/poActions';
import ItemsWaitingForPurchase from '../../utils/ItemsWaitingForPurchase';

function PendingForPurchase({ loading, getPendingRequisitionItems }) {
  const [pending, setPending] = useState([]);
  const [filter, setFilter] = useState([]);

  async function getItems() {
    const res = await getPendingRequisitionItems();
    res && setPending(res);
  }

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Pending Items" loading={loading} />

            <div className="container-fluid bg-white shadow-sm pt-1">
              <ItemsWaitingForPurchase
                fullScreen
                filter={filter}
                loading={loading}
                pending={pending}
                setFilter={setFilter}
                setPending={setPending}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  pendingItems: state.purchaseorders.pendingItems,
  loading: state.purchaseorders.loading
});

export default connect(mapStateToProps, { getPendingRequisitionItems })(
  PendingForPurchase
);
