import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllInventoryCategories } from '../../actions/configActions';

function CategoryOptions({
  current,
  getTools,
  categories,
  setCategory,
  showMinStock,
  getAllInventoryCategories
}) {
  useEffect(() => {
    getAllInventoryCategories();
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className="btn-group mx-auto"
      style={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      {/* eslint-disable-next-line */}
      {categories &&
        categories.map(category => {
          if (category.count > 0) {
            return (
              <button
                key={category.category}
                className={`d-none d-sm-inline-block btn btn-sm ${current === category.category
                  ? 'btn-primary'
                  : 'btn-secondary'
                } shadow-sm`}
                onClick={() => {
                  setCategory(category.category);
                  getTools(category.category, 1, showMinStock);
                }}
              >
                {category.category.toUpperCase()}
              </button>
            );
          }

          return true;
        })}
    </div>
  );
}

const mapStateToProps = state => ({
  categories: state.inventory.categories
});

export default connect(mapStateToProps, { getAllInventoryCategories })(
  CategoryOptions
);
