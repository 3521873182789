import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Footer from '../../common/Footer';
import Topbar from '../../common/Topbar';
import Sidebar from '../../common/Sidebar';
import LogoutModal from '../../common/LogoutModal';
import customStyles from '../../utils/dataTableCustomStyle';
import { getAllReceivedGoods } from '../../../actions/grActions';

function ViewGoodsReceivedForNonProduction({
  role,
  count,
  client,
  loading,
  received,
  getAllReceivedGoods
}) {
  useEffect(() => {
    getAllReceivedGoods();
    // eslint-disable-next-line
  }, []);

  function ExpandedComponent({ data }) {
    let amount = 0;
    let total = 0;

    return (
      <div className="card" key={data.poNumber}>
        <div className="card-body">
          <table
            className="table table-borderless table-light table-striped text-black text-center"
            style={{ marginTop: '-10px' }}
          >
            <thead>
              <tr>
                <th width="5%">SR</th>
                <th className="text-left" width="35%">
                  Particulars
                </th>
                <th>Quantity</th>
                <th>Status of Inspection</th>
                <th>Rate</th>
                <th>GST %</th>
                <th>Discount</th>
                <th>Amount W/Tax</th>
              </tr>
            </thead>
            <tbody>
              {data.items.map((item, index) => {
                total =
                  item.poDetails.rate -
                  (item.poDetails.rate / 100) * item.poDetails.discount;

                amount +=
                  (total + (total * data.po.gstPercent) / 100) *
                  item.approvedQuantity;

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-left">{item.item}</td>
                    <td>{`${item.approvedQuantity} ${item.unit}`}</td>
                    <td>{item.status}</td>
                    <td>₹{item.poDetails.rate}</td>
                    <td>{data.po.gstPercent} %</td>
                    <td>{item.poDetails.discount || 0} %</td>
                    <td>
                      ₹{' '}
                      {(
                        (total + (total * data.po.gstPercent) / 100) *
                        item.approvedQuantity
                      ).toLocaleString()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <h6 className="text-black text-right mr-2 mb-0">
            Total Amount W/Tax:{' '}
            <span className="font-weight-bold">
              ₹ {amount.toLocaleString()}
            </span>
          </h6>
        </div>
      </div>
    );
  }

  const columns = [
    {
      sortable: true,
      cell: (row) => <b>{row.items[0].grNumber}</b>
    },
    {
      right: true,
      cell: (row) => (
        <button
          className="btn btn-sm text-xs btn-light shadow-sm float-right"
          onClick={() =>
            import('../../Production/GoodsReceived/GoodsReceivedPDF').then(
              ({ default: GoodsReceivedPDF }) =>
                GoodsReceivedPDF({ gr: row, vendorId: row.po.vendor, client })
            )
          }
        >
          Download PDF
        </button>
      )
    },
    {
      left: true,
      cell: (row) => (
        <button
          className="btn btn-sm text-xs btn-light shadow-sm float-right"
          onClick={() =>
            import('../../Production/GoodsReceived/GoodsReceivedPDF').then(
              ({ default: GoodsReceivedPDF }) =>
                GoodsReceivedPDF({
                  gr: row,
                  vendorId: row.po.vendor,
                  view: true,
                  client
                })
            )
          }
        >
          View PDF
        </button>
      )
    },
    {
      sortable: true,
      right: true,
      cell: (row) => moment(row.approvedAt).format('DD-MM-YYYY : hh:mm A')
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Goods Received" loading={loading} />

            <div className="container-fluid">
              <div className="text-right">
                <Link
                  to={`/${role}/goodsreceived/grreversal`}
                  className="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm mb-2 mr-1"
                >
                  <i className="fas fa-undo fa-sm text-white-50" /> GR Reversal
                </Link>

                <Link
                  to={`/${role}/goodsreceived/goodsregister`}
                  className="d-none d-sm-inline-block btn btn-sm btn-info shadow-sm mb-2"
                >
                  <i className="fas fa-file fa-sm text-white-50" /> GR Register
                </Link>
              </div>

              <DataTable
                noHeader
                noTableHead
                columns={columns}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                data={received}
                pagination
                paginationTotalRows={count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                paginationComponentOptions={{ noRowsPerPage: true }}
                onChangePage={async (pageNumber) =>
                  await getAllReceivedGoods(pageNumber)
                }
                paginationPerPage={15}
                expandableRows
                expandOnRowClicked
                expandableRowsComponent={<ExpandedComponent />}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  client: state.user.client,
  username: state.user.username,
  count: state.goodsreceived.count,
  error: state.goodsreceived.error,
  loading: state.goodsreceived.loading,
  received: state.goodsreceived.received
});

export default connect(mapStateToProps, {
  getAllReceivedGoods
})(ViewGoodsReceivedForNonProduction);
