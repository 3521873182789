import {
  SET_USER,
  SET_ERROR,
  CLEAR_ERROR,
  LOGOUT_USER,
  SET_LOADING,
  REFRESH_STATS,
  SET_DASHBOARD
} from '../actions/types';

const initialState = {
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  role: null,
  createdAt: null,
  profilePicture: '',
  client: { name: '', address: '' },
  isAuthenticated: false,
  dashboard: {},
  stats: [],
  loading: false,
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        username: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        role: action.payload.role,
        createdAt: action.payload.createdAt,
        profilePicture: action.payload.profilePicURL,
        client: action.payload.domain || {},
        isAuthenticated: true,
        loading: false
      };
    case LOGOUT_USER:
      return {
        username: null,
        email: null,
        phone: null,
        role: null,
        isAuthenticated: false,
        loading: false
      };

    case SET_DASHBOARD:
      return {
        ...state,
        loading: false,
        dashboard: action.payload.data,
        stats: action.payload.stats
      };

    case REFRESH_STATS:
      return {
        ...state,
        loading: false,
        stats: action.payload.stats
      };

    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export default authReducer;
