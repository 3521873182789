import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  getAllItemsByUser,
  setItemNotRequired
} from '../../../actions/requisitionActions';
import Footer from '../../common/Footer';
import LogoutModal from '../../common/LogoutModal';
import SearchSort from '../../common/SearchSort';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import ConfirmationModal from '../../utils/ConfirmationModal';
import customStyles from '../../utils/dataTableCustomStyle';

function ItemsApprovedForPO({
  loading,
  data,
  count,
  getAllItemsByUser,
  setItemNotRequired
}) {
  useEffect(() => {
    getAllItemsByUser(1);
    // eslint-disable-next-line
  }, [])

  const [filter, setFilter] = useState({ phrase: '' });
  const [itemsForRej, setItemsForRej] = useState([]);
  const [selected, setSelected] = useState({
    item: '',
    requisitionNumber: '',
    itemId: ''
  });

  const columns = [
    {
      name: 'Item Name',
      selector: 'item',
      sortable: true,
      width: '30%',
      wrap: true
    },
    {
      name: 'Requisition',
      center: true,
      width: '15%',
      selector: 'requisitionNumber',
      sortable: true
    },
    {
      name: 'Quantity',
      center: true,
      cell: row => `${row.quantity || row.requestedQuantity} ${row.unit}`,
      width: '15%'
    },
    {
      name: 'Approved By',
      center: true,
      width: '10%',
      cell: row => (row.requisition.approver
        ? `${row.requisition.approver.firstName} ${row.requisition.approver.lastName}`
        : 'Waiting Approval')
    },
    {
      name: 'Details',
      center: true,
      width: '15%',
      cell: row => (row.requisition.plant ? (
        <div>
          <p className="text-xs font-weight-bold mb-0 mt-2">
            Plant:
            {' '}
            <span className="text-info font-weight-light">
              {row.requisition.plant || 'NA'}
            </span>
          </p>
          <p className="text-xs font-weight-bold mb-0">
            Machine:
            {' '}
            <span className="text-info font-weight-light">
              {row.requisition.machine || 'NA'}
            </span>
          </p>
          <p className="text-xs font-weight-bold mb-2">
            Remarks:
            {' '}
            <span className="text-info font-weight-light">
              {row.requisition.remark || 'NA'}
            </span>
          </p>
        </div>
      ) : (
        'NA'
      ))
    },
    {
      name: 'Set as Not Required',
      width: '12%',
      center: true,
      cell: row => (
        <button
          disabled={itemsForRej.length > 0}
          onClick={() => {
            setSelected({
              item: row.item,
              requisitionNumber: row.requisitionNumber,
              itemId: row.serialNumber
            });
            window.$('#deleteConfirmation').modal('show');
          }}
          className="btn btn-danger btn-sm shadow-sm text-xs font-weight-bold"
        >
          Not Required
        </button>
      )
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content" className="mb-4">
            <Topbar title="My Requisition Items" loading={loading} />

            <div className="container-fluid bg-white pt-4">
              <SearchSort
                search={phrase => {
                  setFilter({ ...filter, phrase });
                  getAllItemsByUser(1, { ...filter, phrase });
                }}
                filter={filter}
                setFilter={res => {
                  setFilter({ ...filter, ...res });
                  getAllItemsByUser(1, { ...filter, ...res });
                }}
                clearFilter={() => {
                  setFilter({});
                  getAllItemsByUser(1, {});
                }}
              />

              {itemsForRej.length > 0 && (
                <div className="text-center">
                  <button
                    className="btn btn-sm btn-danger shadow-sm font-weight-bold"
                    onClick={() => window.$('#multipleDeleteConfirmation').modal('show')}
                  >
                    Mark Selected Items
                  </button>
                </div>
              )}

              <DataTable
                noHeader
                columns={columns}
                highlightOnHover
                pointerOnHover
                data={data}
                pagination
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={row => {
                  const temp = [];

                  row.selectedRows.map(item => temp.push({
                    requisitionNumber: item.requisitionNumber,
                    itemId: item.serialNumber
                  }));

                  setItemsForRej([...temp]);
                }}
                paginationTotalRows={count}
                paginationServer
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                paginationComponentOptions={{ noRowsPerPage: true }}
                onChangePage={async pageNumber => await getAllItemsByUser(pageNumber, filter)}
                paginationPerPage={15}
              />

              <ConfirmationModal
                modalId="multipleDeleteConfirmation"
                title="Mark Selected Items as Not Required"
                bodyText="Are you sure you want to remove all the selected items. This action can not be reversed. This Item will not appear in purchasing list."
                buttonTitle="Set Not Required"
                selected=""
                response={() => {
                  setItemNotRequired(itemsForRej);
                  setItemsForRej([]);
                  window.$('#multipleDeleteConfirmation').modal('hide');
                }}
              />

              <ConfirmationModal
                modalId="deleteConfirmation"
                title="Mark Item as Not Required"
                bodyText={`Are you sure you want to remove this item from ${selected.requisitionNumber}. This action can not be reversed. This Item will not appear in purchasing list.`}
                buttonTitle="Set Not Required"
                selected={selected}
                response={() => {
                  setItemNotRequired([{ ...selected }]);
                  window.$('#deleteConfirmation').modal('hide');
                }}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <LogoutModal />
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.requisitions.loading,
  data: state.requisitions.myItems.data,
  count: state.requisitions.myItems.count
});

export default connect(mapStateToProps, {
  getAllItemsByUser,
  setItemNotRequired
})(ItemsApprovedForPO);
