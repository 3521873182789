import axios from 'axios';
import {
  SET_TOOLS,
  SET_TOOL_CATEGORY,
  SET_PLANTS,
  SET_MACHINES,
  SET_ALLOCATION_TREE,
  SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR
} from './types';
import setError from '../components/utils/asyncHandler';

export const getTools = (category, page, filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(
      `/api/inventory?category=${category || ''}&page=${page || 1}${
        filter ? '&minimum_stock=true' : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_TOOLS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const setCategory = (category) => async (dispatch) => {
  try {
    dispatch({ type: SET_TOOL_CATEGORY, payload: category });
  } catch (err) {
    dispatch({ type: SET_ERROR, payload: 'Invalid Category Provided' });
  }
};

export const searchTools =
  (search, category, page, filter) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      let res;

      if (search === '') {
        res = await axios.get(
          `/api/inventory?category=${category}&page=${page || 1}${
            filter ? '&minimum_stock=true' : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
            }
          }
        );
      } else {
        res = await axios.post(
          `/api/inventory/search?category=${category}&page=${page}${
            filter ? '&minimum_stock=true' : ''
          }`,
          { name: search },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
            }
          }
        );
      }

      dispatch({ type: SET_TOOLS, payload: res.data });
    } catch (err) {
      setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    }
  };

export const addNewTool = (body) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });

    const res = await axios.post('/api/inventory', body, {
      headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
    });

    return res.data.data;
  } catch (err) {
    return setError(err, (res) =>
      dispatch({ type: res.type, payload: res.data })
    );
  }
};

export const updateInventoryItem =
  (id, body, category, page, search) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      await axios.put(
        `/api/inventory/item/${id}`,
        { ...body },
        {
          headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
        }
      );

      const res = await axios.post(
        `/api/inventory/search?category=${category}&page=${page || 1}`,
        { name: search },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      dispatch({ type: SET_TOOLS, payload: res.data });
    } catch (err) {
      setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    }
  };

export const deleteInventoryItem = (id, category, page) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.delete(`/api/inventory/item/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.JWT_TOKEN}` }
    });

    const res = await axios.get(
      `/api/inventory?category=${category}&page=${page || 1}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_TOOLS, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getPlants = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/inventory/plants', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: SET_PLANTS, payload: res.data.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getMachines = (plant) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`/api/inventory/machines/${plant}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
      }
    });

    dispatch({ type: CLEAR_ERROR });

    res.data.data
      ? dispatch({ type: SET_MACHINES, payload: res.data.data })
      : dispatch({ type: SET_MACHINES, payload: [] });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const getAllocationTree = (id, page) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(
      `/api/inventory/allocate/tree/${id}?page=${
        page || 1
      }&sort=createdAt_DESC`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      }
    );

    dispatch({ type: SET_ALLOCATION_TREE, payload: res.data });
  } catch (err) {
    setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
  }
};

export const createAllocation =
  (id, body, category, page, search) => async (dispatch) => {
    try {
      await axios.post(`/api/inventory/allocate/${id}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
        }
      });

      const tree = await axios.get(
        `/api/inventory/allocate/tree/${id}?page=1&sort=createdAt_DESC`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      dispatch({ type: SET_ALLOCATION_TREE, payload: tree.data });

      const res = await axios.post(
        `/api/inventory/search?category=${category}&page=${page || 1}`,
        { name: search },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT_TOKEN')}`
          }
        }
      );

      dispatch({ type: SET_TOOLS, payload: res.data });
    } catch (err) {
      setError(err, (res) => dispatch({ type: res.type, payload: res.data }));
    }
  };
