import { SET_STATUS, CLEAR_STATUS } from '../actions/types';

const initialState = {
  error: false,
  message: '',
  errorSource: '',
  redirect: false,
  redirectTo: '',
  isOnline: true
};

const logsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message
      };
    case CLEAR_STATUS:
      return {
        error: false,
        message: '',
        errorSource: '',
        redirect: false,
        redirectTo: '',
        isOnline: true
      };

    default:
      return state;
  }
};

export default logsReducer;
