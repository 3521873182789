import React, { useEffect, useState } from 'react';
import momemt from 'moment';
import { connect } from 'react-redux';
import Topbar from './Topbar';
import Footer from './Footer';
import Sidebar from './Sidebar';
import LogoutModal from './LogoutModal';
import Spinner from './Spinner';
import {
  getUserProfile,
  updateUserProfile,
  updateUserProfilePicture
} from '../../actions/authActions';

function Profile({
  user,
  getUserProfile,
  updateUserProfile,
  updateUserProfilePicture,
  error,
  loading
}) {
  const [profile, setProfile] = useState({
    firstName: 'User',
    lastName: '',
    phone: ''
  });
  const [uploading, setUploading] = useState(false);

  async function getProfile() {
    const userData = await getUserProfile();
    userData && setProfile(userData);
  }

  useEffect(() => {
    getProfile();

    // eslint-disable-next-line
  }, []);

  const allowUpdate = Object.keys(profile || {}).length < 1;

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Profile" />

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-8">
                  <div className="card card-body shadow-sm mb-4">
                    <h1 className="h3 mb-2 text-gray-800">
                      Update Profile {loading && <Spinner />}
                    </h1>

                    <form className="mt-4 mb-4">
                      <div className="form-row">
                        <div className="form-group col-sm-4">
                          <label className="mt-2 mb-1">Company</label>
                          <input
                            className="form-control border-0 shadow-sm"
                            type="text"
                            value={user.client.name || ''}
                            readOnly
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label className="mt-2 mb-1">Username</label>
                          <input
                            className="form-control border-0 shadow-sm"
                            type="text"
                            value={user.username}
                            readOnly
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label className="mt-2 mb-1">Mobile</label>
                          <input
                            className="form-control border-0 shadow-sm"
                            type="number"
                            placeholder="Enter your mobile number"
                            value={profile.phone || ''}
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                phone: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-sm-6">
                          <label className="mt-2 mb-1">First Name</label>
                          <input
                            className="form-control border-0 shadow-sm"
                            type="text"
                            value={profile.firstName || ''}
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                firstName: e.target.value
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label className="mt-2 mb-1">Last Name</label>
                          <input
                            className="form-control border-0 shadow-sm"
                            type="text"
                            value={profile.lastName || ''}
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                lastName: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-sm-6">
                          <label className="mt-2 mb-1">Email</label>
                          <input
                            type="email"
                            className="form-control border-0 shadow-sm"
                            value={user.email || ''}
                            readOnly
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label className="mt-2 mb-1">City</label>
                          <input
                            type="text"
                            className="form-control border-0 shadow-sm"
                            value={user.client.address || ''}
                            readOnly
                          />
                        </div>
                      </div>
                    </form>

                    {error && (
                      <p className="text-danger text-center">{error}</p>
                    )}
                    <button
                      className="btn btn-info float-right shadow-sm"
                      onClick={() => updateUserProfile(profile)}
                      disabled={allowUpdate}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>

                {/* <========== PROFILE ===========> */}
                <div className="col-sm-4">
                  <div className="card shadow-sm m-b-30">
                    <div className="card-header bg-white">
                      <h5 className="card-title text-black mb-0">
                        {profile.firstName}
                        &apos;s Profile
                        {uploading && <Spinner />}
                      </h5>
                    </div>
                    <div className="card-body text-center">
                      <div className="onHover">
                        <img
                          src={
                            profile.profilePicURL ||
                            '../../img/default_profile.png'
                          }
                          alt="user-profile"
                          draggable="false"
                          className="rounded-circle img-fluid shadow-sm"
                          style={{ height: '35%', width: '35%' }}
                        />

                        <div className="img-overlay fileupload">
                          <i className="fas fa-camera overlayIcon" />
                          <input
                            onChange={async (e) => {
                              const imageFile = new FormData();

                              imageFile.append(
                                'file',
                                e.target.files[0],
                                e.target.files[0].name
                              );

                              setUploading(true);
                              await updateUserProfilePicture(imageFile);

                              await getProfile();
                              setUploading(false);
                            }}
                            type="file"
                            accept="image/x-png,image/gif,image/jpeg"
                          />
                        </div>
                      </div>

                      {uploading && (
                        <p className="text-center">
                          Uploading Profile Image...
                        </p>
                      )}

                      <h5 className="my-1 mt-3 text-black">
                        {`${profile.firstName} ${profile.lastName}`}
                      </h5>

                      <p className="mb-3 text-muted">@{user.username}</p>

                      <p className="text-muted">Works at {user.client.name}</p>

                      <h5>
                        <span className="badge badge-primary text-uppercase">
                          {user.role}
                        </span>
                      </h5>

                      <div
                        className="row bg-light mt-3 pb-3"
                        style={{ borderRadius: '8px' }}
                      >
                        <div className="col-4 pt-3">
                          <a
                            className="fas fa-envelope mb-0 text-decoration-none text-lg"
                            href={`mailto:${profile.email}`}
                          >
                            <i aria-hidden="true" />
                          </a>
                          <h6 className="my-1 text-muted">{profile.email}</h6>
                        </div>
                        <div className="col-4 pt-3">
                          <a
                            href={`tel:${profile.phone}`}
                            className="fas fa-phone mb-0 text-decoration-none text-lg"
                          >
                            <i aria-hidden="true" />
                          </a>
                          <h6 className="my-1 text-muted">{profile.phone}</h6>
                        </div>
                        <div className="col-4 pt-3">
                          <p className="fas fa-clock mb-0 text-decoration-none text-primary text-lg" />
                          <h6 className="my-1 text-muted">
                            {momemt(profile.createdAt).format('DD MMMM YYYY')}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <========== PROFILE ===========> */}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.user.loading,
  error: state.user.error
});

export default connect(mapStateToProps, {
  getUserProfile,
  updateUserProfile,
  updateUserProfilePicture
})(Profile);
