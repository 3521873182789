import {
  SET_ERROR,
  SET_TOOLS,
  SET_PLANTS,
  SET_LOADING,
  CLEAR_ERROR,
  SET_MACHINES,
  SET_TOOL_CATEGORY,
  SET_ALLOCATION_TREE,
  SET_INVENTORY_CATEGORIES
} from '../actions/types';

const initialState = {
  tools: [],
  count: 0,
  pagination: {},
  category: null,
  categories: [],
  plants: [],
  machines: [],
  tree: {
    rows: [],
    count: 0
  },
  loading: false,
  error: null
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOOLS:
      return {
        ...state,
        tools: action.payload.data,
        count: action.payload.count,
        pagination: action.payload.pagination,
        loading: false
      };

    case SET_TOOL_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading: false
      };

    case SET_INVENTORY_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
        loading: false
      };

    case SET_PLANTS:
      return {
        ...state,
        plants: action.payload,
        loading: false
      };

    case SET_MACHINES:
      return {
        ...state,
        machines: action.payload,
        loading: false
      };

    case SET_ALLOCATION_TREE:
      return {
        ...state,
        tree: {
          rows: action.payload.data,
          count: action.payload.count
        },
        loading: false
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };

    default:
      return state;
  }
};

export default inventoryReducer;
